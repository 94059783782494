import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik } from "formik";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import FormSubmit from "../../../../../Components/Common/FormSubmit";
import InputComponent from "../../../../../Components/Hooks/InputComponent";
import SelectComponent from "../../../../../Components/Hooks/SelectComponent";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import LabelComponent from "../../../../../Components/Hooks/LabelComponent";
import TNT_logo from "../../../../../assets/images/logo/tnt.png";
import { getDataFromApi } from "../../../../../Components/Hooks/Functions";
import ModalViewFile from "../../../../../Components/Hooks/ModalViewFile";
import ModalLoading from "../../../../../Components/Hooks/ModalLoading";
import { phoneRegExp, regexEmail } from "../../../../../Components/Common/Constant";
import { toast } from "react-toastify";

const ModalTNT = ({
	isOpen = false,
	toggle = () => {},
	type,
	valuesCheckPrice,
	dataRow,
	token = "",
	currentTimestamp,
	typeShipment,
	convertTimeShip,
}) => {
	const dispatch = useDispatch();
	const formikRef = useRef();
	const formikRefViewFile = useRef();

	//Ship
	const [addressShip, setAddressShip] = useState("");
	const [personNameShip, setPersonNameShip] = useState("");
	const [companyNameShip, setCompanyNameShip] = useState("");
	const [phoneNumberShip, setPhoneNumberShip] = useState("");
	const [emailShip, setEmailShip] = useState("");

	//Recipient
	const [addressRecip, setAddressRecip] = useState("");
	const [personNameRecip, setPersonNameRecip] = useState("");
	const [companyNameRecip, setCompanyNameRecip] = useState("");
	const [phoneNumberRecip, setPhoneNumberRecip] = useState("");
	const [emailRecip, setEmailRecip] = useState("");
	const [isOpenModel, setIsOpenModel] = useState(false);
	const [isOpenLoading, setIsOpenLoading] = useState(false);

	const [tntAccountNumber, setTNTAccountNumber] = useState("");

	const [productName, setProductName] = useState("");
	const [price, setPrice] = useState("");
	const [time, setTime] = useState("");

	const [dataDocument, setDataDocument] = useState(null);

	const hanldeGetAddressData = (field = "from") => {
		let addressData = "";
		if (valuesCheckPrice?.addressInfo[field]?.address) {
			addressData = valuesCheckPrice?.addressInfo[field]?.address;
		}
		if (valuesCheckPrice?.addressInfo[field]?.city) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.city;
			} else {
				addressData = addressData + ", " + valuesCheckPrice?.addressInfo[field]?.city;
			}
		}
		if (valuesCheckPrice?.addressInfo[field]?.state) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.state;
			} else {
				addressData = addressData + ", " + valuesCheckPrice?.addressInfo[field]?.state;
			}
		}
		if (valuesCheckPrice?.addressInfo[field]?.postCode) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.postCode;
			} else {
				addressData = addressData + " " + valuesCheckPrice?.addressInfo[field]?.postCode;
			}
		}
		if (valuesCheckPrice?.addressInfo[field]?.country) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.country;
			} else {
				addressData = addressData + ", " + valuesCheckPrice?.addressInfo[field]?.country;
			}
		}
		return addressData;
	};

	useEffect(() => {
		if (isOpen) {
			// setPersonNameShip("harry potter 2");
			// setCompanyNameShip("apac tech 2");
			// setPhoneNumberShip("+6128066886");

			// setPersonNameRecip("harry potter");
			// setCompanyNameRecip("apac tech");
			// setPhoneNumberRecip("+9138584708");

			const addressDataShip = hanldeGetAddressData("from");
			setAddressShip(addressDataShip);
			setPhoneNumberShip(valuesCheckPrice?.phoneFrom);
			setPersonNameShip(valuesCheckPrice?.personNameFrom);
			setCompanyNameShip(valuesCheckPrice?.companyNameFrom);
			setEmailShip(valuesCheckPrice?.emailAddressFrom);

			const addressDataRecip = hanldeGetAddressData("to");
			setAddressRecip(addressDataRecip);
			setPhoneNumberRecip(valuesCheckPrice?.addressInfo?.to?.phone);
			setPersonNameRecip(valuesCheckPrice?.personNameTo);
			setCompanyNameRecip(valuesCheckPrice?.companyNameTo);
			setEmailRecip(valuesCheckPrice?.emailAddressTo);
		}
	}, [valuesCheckPrice?.addressInfo, isOpen]);

	const initialValues = {
		addressShip: "",
		personNameShip: valuesCheckPrice?.personNameFrom ?? "",
		companyNameShip: valuesCheckPrice?.companyNameFrom ?? "",
		phoneNumberShip: valuesCheckPrice?.phoneFrom ?? "",
		emailShip: valuesCheckPrice?.emailAddressFrom ?? "",

		addressRecip: "",
		personNameRecip: "",
		companyNameRecip: "",
		phoneNumberRecip: "",
		emailRecip: "",
		tntAccountNumber: "",
	};

	const submitForm = async (values) => {
		const requestData = {
			courier: typeShipment,
			historyId: dataRow?.historyId,
			commodities: [],
			requestedPackageLineItems: valuesCheckPrice.requestedPackageLineItems ?? [],
			shipper: {
				address: valuesCheckPrice.from,
				contact: {
					personName: personNameShip,
					phoneNumber: phoneNumberShip,
					companyName: companyNameShip,
					emailAddress: emailShip,
				},
			},
			recipient: {
				address: valuesCheckPrice.to,
				contact: {
					personName: personNameRecip,
					phoneNumber: phoneNumberRecip,
					companyName: companyNameRecip,
					emailAddress: emailRecip,
				},
			},
			inforCreateTNT: {
				description: valuesCheckPrice?.domesticInformation?.description,
				totalValue: valuesCheckPrice?.domesticInformation?.totalValue,
				currency: "AUD",
				tntAccountNumber: tntAccountNumber ? tntAccountNumber : undefined, // optional, cho biết mã số tài khoản sẽ trả phí, nếu không nhập thì undefined
			},
			// domesticInformation: valuesCheckPrice?.domesticInformation ?? "",
			// addressInfo: valuesCheckPrice?.addressInfo,
		};
		setIsOpenLoading(true);
		const response = await handleCreateShipment(requestData);
		if (response?.error) {
			toast.error(response?.error && response?.error?.message);
		} else {
			setDataDocument(response);
		}
		setIsOpenLoading(false);
	};

	const handleReset = () => {
		toggle();

		setAddressShip("");
		setPersonNameShip(valuesCheckPrice?.personNameFrom ?? "");
		setCompanyNameShip(valuesCheckPrice?.companyNameFrom ?? "");
		setPhoneNumberShip(valuesCheckPrice?.phoneFrom ?? "");
		setEmailShip(valuesCheckPrice?.emailAddressFrom ?? "");

		setAddressRecip("");
		setPersonNameRecip("");
		setCompanyNameRecip("");
		setPhoneNumberRecip("");
		setEmailRecip("");

		setTNTAccountNumber("");
	};

	useEffect(() => {
		if (isOpen && dataRow?.historyId) {
			setProductName(dataRow?.serviceName);
			setPrice(dataRow?.price);
			setTime(convertTimeShip(dataRow?.deliverDateRange));
		}
	}, [isOpen]);

	const handleCreateShipment = async (requestBody) => {
		if (token !== "") {
			const url = process.env.REACT_APP_API_URL + "/api/shipment/create-shipment";
			return await getDataFromApi("POST", url, requestBody, token, currentTimestamp)
				.then((data) => {
					return data;
				})
				.catch((error) => {
					return [];
					// console.error("Error fetching data:", error);
				});
		}
	};
	const validate = (values) => {
		let errors = {};
		if (values?.personNameShip === "") errors.personNameShip = "Please enter person name";
		if (values?.companyNameShip === "") errors.companyNameShip = "Please enter company name";
		if (values?.phoneNumberShip === "") errors.phoneNumberShip = "Please enter phone number";
		if (values?.emailShip === "") errors.emailShip = "Please enter a email";
		if (!regexEmail.test(values?.emailShip) && values?.emailShip !== "") errors.emailShip = "Invalid Email";
		if (!phoneRegExp.test(values?.phoneNumberShip) && values?.phoneNumberShip !== "") errors.phoneNumberShip = "Invalid Phone Number";

		if (values?.personNameRecip === "") errors.personNameRecip = "Please enter person name";
		if (values?.companyNameRecip === "") errors.companyNameRecip = "Please enter company name";
		if (values?.phoneNumberRecip === "") errors.phoneNumberRecip = "Please enter phone number";
		if (values?.emailRecip === "" && values?.tntAccountNumber !== "") errors.emailRecip = "Please enter a email";
		if (!regexEmail.test(values?.emailRecip) && values?.emailRecip !== "") errors.emailRecip = "Invalid Email";
		if (!phoneRegExp.test(values?.phoneNumberRecip) && values?.phoneNumberRecip !== "") errors.phoneNumberRecip = "Invalid Phone Number";

		return errors;
	};

	return (
		<>
			<ModalLoading isOpen={isOpenLoading} />
			<ModalViewFile
				dataDocument={dataDocument}
				setIsOpen={setIsOpenModel}
				isOpen={isOpenModel}
				formikRef={formikRefViewFile}
				toggleCreateShipment={() => {
					handleReset();
				}}
			/>
			<Modal
				isOpen={isOpen}
				toggle={() => {
					handleReset();
				}}
				centered
				className="modal-lg-2">
				<Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={submitForm} innerRef={formikRef}>
					{(formik) => {
						const {
							values,
							handleChange,
							setFieldValue,
							handleSubmit,
							errors,
							touched,
							handleBlur,
							isValid,
							dirty,
							validateForm,
							setFieldError,
							setFieldTouched,
						} = formik;
						return (
							<FormSubmit onSubmit={handleSubmit}>
								<ModalHeader
									className="bg-soft-info p-3"
									toggle={() => {
										handleReset();
									}}>
									TNT Label
								</ModalHeader>
								<ModalBody className="p-0">
									<Row className="ps-3 pb-1 d-flex align-items-center">
										<img
											src={TNT_logo}
											alt=""
											className="acitivity-avatar"
											style={{
												marginLeft: "13px",
												marginTop: "10px",
												width: "250px",
												height: "120px",
												borderRadius: "10%",
											}}
										/>
										<div className="p-2 w-auto px-1 text-left  ">
											<div className="d-flex align-items-center">
												<legend className="fw-bold  float-none" style={{ fontSize: "15px" }}>
													Product Name: {productName}
												</legend>
											</div>
											<div className="d-flex align-items-center">
												<legend className="fw-bold  float-none" style={{ fontSize: "15px" }}>
													Price: {price} USD
												</legend>
											</div>
											<div className="d-flex align-items-center">
												<legend className="fw-bold  float-none" style={{ fontSize: "15px" }}>
													Time: {time}
												</legend>
											</div>
										</div>
									</Row>
									<Row>
										<div className="d-flex align-items-center">
											<div className="mb-0 w-100 pb-1">
												<fieldset className="border rounded-3">
													<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
														Shipper Information
													</legend>
													<Row className="gy-3 pe-3 ps-3">
														<Col lg={12}>
															<LabelComponent title="Address:" />
															<InputComponent
																type="search"
																name={`addressShip`}
																value={addressShip}
																onChange={(value, name) => {
																	setAddressShip(value);
																	setFieldValue("addressShip", value);
																}}
																placeholder={"Address"}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Person name:" requiredField />
															<InputComponent
																type="search"
																name={`personNameShip`}
																value={personNameShip}
																onChange={(value, name) => {
																	setPersonNameShip(value);
																	setFieldValue("personNameShip", value);
																}}
																placeholder={"Person name"}
																onBlur={handleBlur}
																invalid={touched.personNameShip && errors.personNameShip ? true : false}
																error={touched?.personNameShip && errors?.personNameShip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Company name:" requiredField />
															<InputComponent
																type="search"
																name={`companyNameShip`}
																value={companyNameShip}
																onChange={(value, name) => {
																	setCompanyNameShip(value);
																	setFieldValue("companyNameShip", value);
																}}
																placeholder={"Company name"}
																onBlur={handleBlur}
																invalid={touched.companyNameShip && errors.companyNameShip ? true : false}
																error={touched?.companyNameShip && errors?.companyNameShip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Phone number:" requiredField />
															<InputComponent
																type="search"
																name={`phoneNumberShip`}
																value={phoneNumberShip}
																onChange={(value, name) => {
																	setPhoneNumberShip(value);
																	setFieldValue("phoneNumberShip", value);
																}}
																placeholder={"Phone number"}
																onBlur={handleBlur}
																invalid={touched.phoneNumberShip && errors.phoneNumberShip ? true : false}
																error={touched?.phoneNumberShip && errors?.phoneNumberShip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Email:" requiredField />
															<InputComponent
																type="search"
																name={`emailShip`}
																value={emailShip}
																onChange={(value, name) => {
																	setEmailShip(value);
																	setFieldValue("emailShip", value);
																}}
																placeholder={"Email"}
																onBlur={handleBlur}
																invalid={touched.emailShip && errors.emailShip ? true : false}
																error={touched?.emailShip && errors?.emailShip}
															/>
														</Col>
													</Row>
												</fieldset>
											</div>
										</div>
									</Row>
									<Row>
										<div className="d-flex align-items-center">
											<div className="mb-0 w-100 pb-1">
												<fieldset className="border rounded-3">
													<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
														Recipient Information
													</legend>
													<Row className="gy-3 pe-3 ps-3">
														<Col lg={12}>
															<LabelComponent title="Address:" />
															<InputComponent
																type="search"
																name={`addressRecip`}
																value={addressRecip}
																onChange={(value, name) => {
																	setAddressRecip(value);
																	setFieldValue("addressRecip", value);
																}}
																placeholder={"Address"}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Person name:" requiredField />
															<InputComponent
																type="search"
																name={`personNameRecip`}
																value={personNameRecip}
																onChange={(value, name) => {
																	setPersonNameRecip(value);
																	setFieldValue("personNameRecip", value);
																}}
																placeholder={"Person name"}
																onBlur={handleBlur}
																invalid={touched.personNameRecip && errors.personNameRecip ? true : false}
																error={touched?.personNameRecip && errors?.personNameRecip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Company name:" requiredField />
															<InputComponent
																type="search"
																name={`companyNameRecip`}
																value={companyNameRecip}
																onChange={(value, name) => {
																	setCompanyNameRecip(value);
																	setFieldValue("companyNameRecip", value);
																}}
																placeholder={"Company name"}
																onBlur={handleBlur}
																invalid={touched.companyNameRecip && errors.companyNameRecip ? true : false}
																error={touched?.companyNameRecip && errors?.companyNameRecip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Phone number:" requiredField />
															<InputComponent
																type="search"
																name={`phoneNumberRecip`}
																value={phoneNumberRecip}
																onChange={(value, name) => {
																	setPhoneNumberRecip(value);
																	setFieldValue("phoneNumberRecip", value);
																}}
																placeholder={"Phone number"}
																onBlur={handleBlur}
																invalid={touched.phoneNumberRecip && errors.phoneNumberRecip ? true : false}
																error={touched?.phoneNumberRecip && errors?.phoneNumberRecip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Email:" />
															<InputComponent
																type="search"
																name={`emailRecip`}
																value={emailRecip}
																onChange={(value, name) => {
																	setEmailRecip(value);
																	setFieldValue("emailRecip", value);
																}}
																placeholder={"Email"}
																onBlur={handleBlur}
																invalid={touched.emailRecip && errors.emailRecip ? true : false}
																error={touched?.emailRecip && errors?.emailRecip}
															/>
														</Col>
													</Row>
												</fieldset>
											</div>
										</div>
									</Row>

									<Row>
										<div className="d-flex align-items-center">
											<div className="mb-0 w-100 pb-1">
												<fieldset className="border rounded-3">
													<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
														Account Number of Customer
													</legend>
													<Row className="gy-3 pe-3 ps-3">
														<Col lg={12}>
															<InputComponent
																type="text"
																name={`tntAccountNumber`}
																value={tntAccountNumber}
																onChange={(value, name) => {
																	setTNTAccountNumber(value);
																	setFieldValue("tntAccountNumber", value);
																}}
																placeholder={"Account Number of Customer"}
															/>
														</Col>
													</Row>
												</fieldset>
											</div>
										</div>
									</Row>

									<div className="mb-3 w-100 mx-auto d-flex align-items-center justify-content-end">
										<div style={{ marginRight: "29px" }}>
											<Button
												// disabled={!dirty}
												type="button"
												color="success"
												onClick={() => {
													handleSubmit(values);
												}}>
												Create Shipment
											</Button>
										</div>
									</div>
								</ModalBody>
							</FormSubmit>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
};

export default ModalTNT;
