// Actions
export const LOGISTICS_API_RESPONSE_SUCCESS = "LOGISTICS_API_RESPONSE_SUCCESS";
export const LOGISTICS_API_RESPONSE_ERROR = "LOGISTICS_API_RESPONSE_ERROR";

export const LOGISTICS_GET_LIST_HISTORY = "LOGISTICS_GET_LIST_HISTORY";

export const LOGISTICS_GET_LIST_ADDRESS = "LOGISTICS_GET_LIST_ADDRESS";
export const LOGISTICS_GET_LIST_ADDRESS_DEFAULT = "LOGISTICS_GET_LIST_ADDRESS_DEFAULT";
export const LOGISTICS_ADD_ADDRESS = "LOGISTICS_ADD_ADDRESS";
export const LOGISTICS_UPDATE_ADDRESS = "LOGISTICS_UPDATE_ADDRESS";

export const LOGISTICS_SET_VALUE_FROM_TO_DOMESTIC = "LOGISTICS_SET_VALUE_FROM_TO_DOMESTIC";
export const LOGISTICS_SET_VALUE_FROM_TO_INTERNATIONAL = "LOGISTICS_SET_VALUE_FROM_TO_INTERNATIONAL";
export const LOGISTICS_SET_VALUE_DOMESTIC_TO_INTERNATIONAL = "LOGISTICS_SET_VALUE_DOMESTIC_TO_INTERNATIONAL";

export const LOGISTICS_GET_LIST_PACKAGE_SIZE = "LOGISTICS_GET_LIST_PACKAGE_SIZE";
export const LOGISTICS_ADD_PACKAGE_SIZE = "LOGISTICS_ADD_PACKAGE_SIZE";
export const LOGISTICS_ADD_ROWS_PACKAGE_SIZE_SUCCESS = "LOGISTICS_ADD_ROWS_PACKAGE_SIZE_SUCCESS";
export const LOGISTICS_UPDATE_ROWS_PACKAGE_SIZE_SUCCESS = "LOGISTICS_UPDATE_ROWS_PACKAGE_SIZE_SUCCESS";
export const LOGISTICS_DELETE_ROWS_PACKAGE_SIZE_SUCCESS = "LOGISTICS_DELETE_ROWS_PACKAGE_SIZE_SUCCESS";

export const LOGISTICS_UPDATE_PACKAGE_SIZE = "LOGISTICS_UPDATE_PACKAGE_SIZE";
export const LOGISTICS_DELETE_PACKAGE_SIZE = "LOGISTICS_DELETE_PACKAGE_SIZE";
export const LOGISTICS_DELETE_PACKAGE_SIZE_SUCCESS = "LOGISTICS_DELETE_PACKAGE_SIZE_SUCCESS";
export const LOGISTICS_DELETE_PACKAGE_SIZE_FAIL = "LOGISTICS_DELETE_PACKAGE_SIZE_FAIL";

export const LOGISTICS_SET_VALUE_PACKAGE_SIZE_DOMESTIC = "LOGISTICS_SET_VALUE_PACKAGE_SIZE_DOMESTIC";
export const LOGISTICS_SET_VALUE_PACKAGE_SIZE_INTERNATIONAL = "LOGISTICS_SET_VALUE_PACKAGE_SIZE_INTERNATIONAL";

export const LOGISTICS_GET_RATE_SHIPMENT = "LOGISTICS_GET_RATE_SHIPMENT";
export const LOGISTICS_GET_DEFINITIONS = "LOGISTICS_GET_DEFINITIONS";
export const LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_DOMESTIC = "LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_DOMESTIC";
export const LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_INTERNATIONAL = "LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_INTERNATIONAL";

export const LOGISTICS_DELETE_ADDRESS = "LOGISTICS_DELETE_ADDRESS";
