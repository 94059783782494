import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik } from "formik";
import { Button, Col, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import FormSubmit from "../../../../../Components/Common/FormSubmit";
import InputComponent from "../../../../../Components/Hooks/InputComponent";
import SelectComponent from "../../../../../Components/Hooks/SelectComponent";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import LabelComponent from "../../../../../Components/Hooks/LabelComponent";
import TNT_logo from "../../../../../assets/images/logo/tnt.png";
import ListCommoditiesTable from "./CommoditiesInformation/ListCommoditiesTable";
import Flatpickr from "react-flatpickr";
import { handleCheckFile } from "../../../../../utils/utilFiles";
import ModalLoading from "../../../../../Components/Hooks/ModalLoading";
import ModalViewFile from "../../../../../Components/Hooks/ModalViewFile";
import { getDataFromApi } from "../../../../../Components/Hooks/Functions";
import useFormatDate from "../../../../../Components/Hooks/FormatDateGMT";
import { dateFormatString15, dateFormatString18 } from "../../../../../Components/Common/FormatTime";
import { phoneRegExp, regexEmail } from "../../../../../Components/Common/Constant";
import { toast } from "react-toastify";

const ModalTNT = ({
	isOpen = false,
	toggle = () => {},
	type,
	valuesCheckPrice,
	dataRow,
	token = "",
	currentTimestamp,
	typeShipment,
	convertTimeShip,
}) => {
	const dispatch = useDispatch();
	const formikRefViewFile = useRef();
	const fileInputRef = useRef(null);

	const { FormatDateGMT, FormatDateGMTNotTimePicker, FormatDateHasZoneTime } = useFormatDate();

	const [invoiceNumber, setInvoiceNumber] = useState("");
	const [paperlessTrade, setPaperlessTrade] = useState(false);
	const [paperless, setPaperless] = useState([]);
	const [returnLabel, setReturnLabel] = useState(false);
	const [invoiceDate, setInvoiceDate] = useState("");

	//Ship
	const [addressShip, setAddressShip] = useState("");
	const [personNameShip, setPersonNameShip] = useState("");
	const [companyNameShip, setCompanyNameShip] = useState("");
	const [phoneNumberShip, setPhoneNumberShip] = useState("");
	const [emailShip, setEmailShip] = useState("");

	//Recipient
	const [addressRecip, setAddressRecip] = useState("");
	const [personNameRecip, setPersonNameRecip] = useState("");
	const [companyNameRecip, setCompanyNameRecip] = useState("");
	const [phoneNumberRecip, setPhoneNumberRecip] = useState("");
	const [emailRecip, setEmailRecip] = useState("");

	const [listConterm, setListCoterm] = useState([]);
	const [incoterm, setIncoterm] = useState([]);

	const [productName, setProductName] = useState("");
	const [price, setPrice] = useState("");
	const [time, setTime] = useState("");

	const [isOpenModel, setIsOpenModel] = useState(false);
	const [isOpenLoading, setIsOpenLoading] = useState(false);
	const [dataDocument, setDataDocument] = useState(null);

	const [useInvoiceFromTNT, setUseInvoiceFromTNT] = useState(false);
	const [listShipmentPurpose, setListShipmentPurpose] = useState([]);
	const [shipmentPurpose, setShipmentPurpose] = useState([]);
	const [tntAccountNumber, setTNTAccountNumber] = useState("");
	const [description, setDescription] = useState("Computer Hardware");

	const hanldeGetAddressData = (field = "from") => {
		let addressData = "";
		if (valuesCheckPrice?.addressInfo[field]?.address) {
			addressData = valuesCheckPrice?.addressInfo[field]?.address;
		}
		if (valuesCheckPrice?.addressInfo[field]?.city) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.city;
			} else {
				addressData = addressData + ", " + valuesCheckPrice?.addressInfo[field]?.city;
			}
		}
		if (valuesCheckPrice?.addressInfo[field]?.state) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.state;
			} else {
				addressData = addressData + ", " + valuesCheckPrice?.addressInfo[field]?.state;
			}
		}
		if (valuesCheckPrice?.addressInfo[field]?.postCode) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.postCode;
			} else {
				addressData = addressData + " " + valuesCheckPrice?.addressInfo[field]?.postCode;
			}
		}
		if (valuesCheckPrice?.addressInfo[field]?.country) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.country;
			} else {
				addressData = addressData + ", " + valuesCheckPrice?.addressInfo[field]?.country;
			}
		}
		return addressData;
	};

	useEffect(() => {
		if (isOpen) {
			// setPersonNameShip("harry potter 2");
			// setCompanyNameShip("apac tech 2");
			// setPhoneNumberShip("+6128066886");

			// setPersonNameRecip("harry potter");
			// setCompanyNameRecip("apac tech");
			// setPhoneNumberRecip("+9138584708");

			const addressDataShip = hanldeGetAddressData("from");
			setAddressShip(addressDataShip);
			setPhoneNumberShip(valuesCheckPrice?.phoneFrom);
			setPersonNameShip(valuesCheckPrice?.personNameFrom);
			setCompanyNameShip(valuesCheckPrice?.companyNameFrom);
			setEmailShip(valuesCheckPrice?.emailAddressFrom);

			const addressDataRecip = hanldeGetAddressData("to");
			setAddressRecip(addressDataRecip);
			setPhoneNumberRecip(valuesCheckPrice?.addressInfo?.to?.phone);
			setPersonNameRecip(valuesCheckPrice?.personNameTo);
			setCompanyNameRecip(valuesCheckPrice?.companyNameTo);
			setEmailRecip(valuesCheckPrice?.emailAddressTo);
		}
	}, [valuesCheckPrice?.addressInfo, isOpen]);

	useEffect(() => {
		if (isOpen && dataRow?.historyId) {
			setProductName(dataRow?.serviceName);
			setPrice(dataRow?.price);
			setTime(convertTimeShip(dataRow?.deliverDateRange));
		}
	}, [isOpen]);

	useEffect(() => {
		const methods = ["EXW", "DAP", "DDP", "CIP", "DAT", "DPU", "FCA", "CPT", "FAS", "FOB", "CFR", "CIF"];
		const methodObjects = methods.map((method, index) => ({
			id: index + 1,
			name: method,
		}));
		setListCoterm(methodObjects);
		const defaultIncoterm = methodObjects.find((item) => item.name == "FCA");
		setIncoterm(defaultIncoterm);

		const methodsShipmentPurpose = ["sale", "sample", "gift", "return", "repair", "temporary-export", "exhibition-goods", "testing"];
		const methodObjectsShipmentPurpose = methodsShipmentPurpose.map((method, index) => ({
			id: index + 1,
			name: method,
		}));
		setListShipmentPurpose(methodObjectsShipmentPurpose);
	}, []);
	const formikRef = useRef();

	const validate = (values) => {
		let errors = {};

		if (values?.personNameShip === "") errors.personNameShip = "Please enter person name";
		if (values?.companyNameShip === "") errors.companyNameShip = "Please enter company name";
		if (values?.phoneNumberShip === "") errors.phoneNumberShip = "Please enter phone number";
		if (values?.emailShip === "") errors.emailShip = "Please enter a email";
		if (!regexEmail.test(values?.emailShip) && values?.emailShip !== "") errors.emailShip = "Invalid Email";
		if (!phoneRegExp.test(values?.phoneNumberShip) && values?.phoneNumberShip !== "") errors.phoneNumberShip = "Invalid Phone Number";

		if (values?.personNameRecip === "") errors.personNameRecip = "Please enter person name";
		if (values?.companyNameRecip === "") errors.companyNameRecip = "Please enter company name";
		if (values?.phoneNumberRecip === "") errors.phoneNumberRecip = "Please enter phone number";
		if (values?.emailRecip === "" && values?.tntAccountNumber !== "") errors.emailRecip = "Please enter a email";
		if (!regexEmail.test(values?.emailRecip) && values?.emailRecip !== "") errors.emailRecip = "Invalid Email";
		if (!phoneRegExp.test(values?.phoneNumberRecip) && values?.phoneNumberRecip !== "") errors.phoneNumberRecip = "Invalid Phone Number";

		if (!values?.shipmentPurpose) errors.shipmentPurpose = "Please select a shipment purpose";
		if (values?.description === "") errors.description = "Please enter description";

		// Validate commodity if check useInvoiceFromTNT
		if (useInvoiceFromTNT && values?.commodities?.length > 0) {
			const arrErrors = values?.commodities?.map((value) => ({}));
			values?.commodities?.forEach((commodity, index) => {
				if (!commodity?.description) arrErrors[index].description = "Please enter description";
				if (!commodity?.quantity) arrErrors[index].noOfUnits = "Please enter no of units";
				if (!commodity?.weight?.value || commodity?.weight?.value === 0) arrErrors[index].weight = "Please enter weight";
				if (!commodity?.customsValue?.amount || commodity?.customsValue?.amount === 0) arrErrors[index].customValue = "Please enter custom value";
				if (!commodity?.unitPrice?.amount || commodity?.unitPrice?.amount === 0) arrErrors[index].unitValue = "Please enter unit value";
				if (!commodity?.hsCodes) arrErrors[index].hsCodes = "Please enter hsCodes";
				if (!commodity?.countryOfManufacture) arrErrors[index].countryOfManufacture = "Please enter country of manufacture";
			});
			const errCommodity = arrErrors?.filter((commodity) => Object.keys(commodity).length > 0);
			if (errCommodity?.length > 0) errors.commodities = arrErrors?.filter((commodity) => Object.keys(commodity).length > 0);
		}

		return errors;
	};

	const submitForm = async (values) => {
		const hsCodes = values?.commodities?.map((commodity) => commodity?.hsCodes);
		values?.commodities?.forEach((commodity) => {
			delete commodity?.hsCodes;
		});
		const requestData = {
			courier: typeShipment,
			historyId: dataRow?.historyId,
			commodities: values.commodities,
			requestedPackageLineItems: valuesCheckPrice.requestedPackageLineItems ?? [],
			shipper: {
				address: valuesCheckPrice.from,
				contact: {
					personName: personNameShip,
					phoneNumber: phoneNumberShip,
					companyName: companyNameShip,
					emailAddress: emailShip,
				},
			},
			recipient: {
				address: valuesCheckPrice.to,
				contact: {
					personName: personNameRecip,
					phoneNumber: phoneNumberRecip,
					companyName: companyNameRecip,
					emailAddress: emailRecip,
				},
			},
			inforCreateTNT: {
				description: description,
				tntAccountNumber: tntAccountNumber ? tntAccountNumber : undefined, // optional, cho biết mã số tài khoản sẽ trả phí, nếu không nhập thì undefined
				useInvoiceFromTNT: useInvoiceFromTNT, // cho biết có sử dụng invoice TNT hay không, nếu có trong label sẽ có đính kèm danh sách hàng
				incoterm: incoterm?.name || "", // cho biết incoterm: ["EXW", "DAP", "DDP", "CIP", "DAT", "DPU", "FCA", "CPT", "FAS", "FOB", "CFR", "CIF"]
				shipmentPurpose: shipmentPurpose?.name || "", // lý do xuất khẩu: ["sale", "sample", "gift", "return", "repair", "temporary-export", "exhibition-goods", "testing"]
				hsCodes: hsCodes, // mã hscode của món hàng, xếp theo thứ tự trong commodities. nếu không nhập thì để chuỗi rỗng ['123','','5678']
			},
		};
		// console.log("requestData", requestData);
		setIsOpenLoading(true);
		const response = await handleCreateShipment(requestData);
		if (response?.error) {
			toast.error(response?.error && response?.error?.message);
		} else {
			setDataDocument(response);
		}
		setIsOpenLoading(false);
	};

	const initialValues = {
		addressShip: "",
		personNameShip: valuesCheckPrice?.personNameFrom ?? "",
		companyNameShip: valuesCheckPrice?.companyNameFrom ?? "",
		phoneNumberShip: valuesCheckPrice?.phoneFrom ?? "",
		emailShip: valuesCheckPrice?.emailAddressFrom ?? "",

		addressRecip: "",
		personNameRecip: "",
		companyNameRecip: "",
		phoneNumberRecip: "",
		emailRecip: "",
		commercialInvoice: "",

		invoiceNumber: "",
		invoiceDate: "",
		commodities: [],

		paperlessTrade: false,
		returnLabel: false,

		incoterm: listConterm.find((item) => item.name == "FCA"),
		shipmentPurpose: "",
		description: "Computer Hardware",
		tntAccountNumber: "",
	};

	const handleReset = () => {
		toggle();

		setAddressShip("");
		setPersonNameShip(valuesCheckPrice?.personNameFrom ?? "");
		setCompanyNameShip(valuesCheckPrice?.companyNameFrom ?? "");
		setPhoneNumberShip(valuesCheckPrice?.phoneFrom ?? "");
		setEmailShip(valuesCheckPrice?.emailAddressFrom ?? "");
		setAddressRecip("");
		setPersonNameRecip("");
		setCompanyNameRecip("");
		setPhoneNumberRecip("");
		setEmailRecip("");

		const defaultIncoterm = listConterm.find((item) => item.name == "FCA");
		setIncoterm(defaultIncoterm);

		setShipmentPurpose([]);
		setTNTAccountNumber("");
		setDescription("Computer Hardware");
		setUseInvoiceFromTNT(false);
	};

	const handleCreateShipment = async (requestBody) => {
		if (token != "") {
			const url = process.env.REACT_APP_API_URL + "/api/shipment/create-shipment";

			return await getDataFromApi("POST", url, requestBody, token, currentTimestamp)
				.then((data) => {
					return data;
				})
				.catch((error) => {
					return [];
					// console.error("Error fetching data:", error);
				});
		}
	};

	return (
		<>
			<ModalLoading isOpen={isOpenLoading} />
			<ModalViewFile
				dataDocument={dataDocument}
				setIsOpen={setIsOpenModel}
				isOpen={isOpenModel}
				formikRef={formikRefViewFile}
				toggleCreateShipment={() => {
					handleReset();
				}}
			/>
			<Modal
				isOpen={isOpen}
				toggle={() => {
					handleReset();
				}}
				centered
				className="modal-xxl">
				<Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={submitForm} innerRef={formikRef}>
					{(formik) => {
						const {
							values,
							handleChange,
							setFieldValue,
							handleSubmit,
							errors,
							touched,
							handleBlur,
							isValid,
							dirty,
							validateForm,
							setFieldError,
							setFieldTouched,
						} = formik;
						return (
							<FormSubmit onSubmit={handleSubmit}>
								<ModalHeader
									className="bg-soft-info p-3"
									toggle={() => {
										handleReset();
									}}>
									TNT Label
								</ModalHeader>
								<ModalBody className="p-0">
									<Row className="ps-3 pb-1 d-flex align-items-center">
										<img
											src={TNT_logo}
											alt=""
											className="acitivity-avatar"
											style={{
												marginLeft: "13px",
												marginTop: "10px",
												width: "250px",
												height: "120px",
												borderRadius: "10%",
											}}
										/>
										<div className="p-2 w-auto px-1 text-left  ">
											<div className="d-flex align-items-center">
												<legend className="fw-bold  float-none" style={{ fontSize: "15px" }}>
													Product Name: {productName}
												</legend>
											</div>
											<div className="d-flex align-items-center">
												<legend className="fw-bold  float-none" style={{ fontSize: "15px" }}>
													Price: {price} USD
												</legend>
											</div>
											<div className="d-flex align-items-center">
												<legend className="fw-bold  float-none" style={{ fontSize: "15px" }}>
													Time: {time}
												</legend>
											</div>
										</div>
									</Row>

									<Row>
										<div className="d-flex align-items-center">
											<div className="mb-0 w-100 pb-1">
												<fieldset className="border rounded-3">
													<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
														Shipper Information
													</legend>
													<Row className="gy-3 pe-3 ps-3">
														<Col lg={12}>
															<LabelComponent title="Address:" />
															<InputComponent
																type="search"
																name={`addressShip`}
																value={addressShip}
																onChange={(value, name) => {
																	setAddressShip(value);
																	setFieldValue("addressShip", value);
																}}
																placeholder={"Address"}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Person name:" requiredField />
															<InputComponent
																type="search"
																name={`personNameShip`}
																value={personNameShip}
																onChange={(value, name) => {
																	setPersonNameShip(value);
																	setFieldValue("personNameShip", value);
																}}
																placeholder={"Person name"}
																onBlur={handleBlur}
																invalid={touched.personNameShip && errors.personNameShip ? true : false}
																error={touched?.personNameShip && errors?.personNameShip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Company name:" requiredField />
															<InputComponent
																type="search"
																name={`companyNameShip`}
																value={companyNameShip}
																onChange={(value, name) => {
																	setCompanyNameShip(value);
																	setFieldValue("companyNameShip", value);
																}}
																placeholder={"Company name"}
																onBlur={handleBlur}
																invalid={touched.companyNameShip && errors.companyNameShip ? true : false}
																error={touched?.companyNameShip && errors?.companyNameShip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Phone number:" requiredField />
															<InputComponent
																type="search"
																name={`phoneNumberShip`}
																value={phoneNumberShip}
																onChange={(value, name) => {
																	setPhoneNumberShip(value);
																	setFieldValue("phoneNumberShip", value);
																}}
																placeholder={"Phone number"}
																onBlur={handleBlur}
																invalid={touched.phoneNumberShip && errors.phoneNumberShip ? true : false}
																error={touched?.phoneNumberShip && errors?.phoneNumberShip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Email:" requiredField />
															<InputComponent
																type="search"
																name={`emailShip`}
																value={emailShip}
																onChange={(value, name) => {
																	setEmailShip(value);
																	setFieldValue("emailShip", value);
																}}
																placeholder={"Email"}
																onBlur={handleBlur}
																invalid={touched.emailShip && errors.emailShip ? true : false}
																error={touched?.emailShip && errors?.emailShip}
															/>
														</Col>
													</Row>
												</fieldset>
											</div>
										</div>
									</Row>
									<Row>
										<div className="d-flex align-items-center">
											<div className="mb-0 w-100 pb-1">
												<fieldset className="border rounded-3">
													<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
														Recipient Information
													</legend>
													<Row className="gy-3 pe-3 ps-3">
														<Col lg={12}>
															<LabelComponent title="Address:" />
															<InputComponent
																type="search"
																name={`addressRecip`}
																value={addressRecip}
																onChange={(value, name) => {
																	setAddressRecip(value);
																	setFieldValue("addressRecip", value);
																}}
																placeholder={"Address"}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Person name:" requiredField />
															<InputComponent
																type="search"
																name={`personNameRecip`}
																value={personNameRecip}
																onChange={(value, name) => {
																	setPersonNameRecip(value);
																	setFieldValue("personNameRecip", value);
																}}
																placeholder={"Person name"}
																onBlur={handleBlur}
																invalid={touched.personNameRecip && errors.personNameRecip ? true : false}
																error={touched?.personNameRecip && errors?.personNameRecip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Company name:" requiredField />
															<InputComponent
																type="search"
																name={`companyNameRecip`}
																value={companyNameRecip}
																onChange={(value, name) => {
																	setCompanyNameRecip(value);
																	setFieldValue("companyNameRecip", value);
																}}
																placeholder={"Company name"}
																onBlur={handleBlur}
																invalid={touched.companyNameRecip && errors.companyNameRecip ? true : false}
																error={touched?.companyNameRecip && errors?.companyNameRecip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Phone number:" requiredField />
															<InputComponent
																type="search"
																name={`phoneNumberRecip`}
																value={phoneNumberRecip}
																onChange={(value, name) => {
																	setPhoneNumberRecip(value);
																	setFieldValue("phoneNumberRecip", value);
																}}
																placeholder={"Phone number"}
																onBlur={handleBlur}
																invalid={touched.phoneNumberRecip && errors.phoneNumberRecip ? true : false}
																error={touched?.phoneNumberRecip && errors?.phoneNumberRecip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Email:" />
															<InputComponent
																type="search"
																name={`emailRecip`}
																value={emailRecip}
																onChange={(value, name) => {
																	setEmailRecip(value);
																	setFieldValue("emailRecip", value);
																}}
																placeholder={"Email"}
																onBlur={handleBlur}
																invalid={touched.emailRecip && errors.emailRecip ? true : false}
																error={touched?.emailRecip && errors?.emailRecip}
															/>
														</Col>
													</Row>
												</fieldset>
											</div>
										</div>
									</Row>

									<Row>
										<Col lg={2}>
											<div className="d-flex align-items-center">
												<div className="mb-0 w-100 pb-1">
													<fieldset className="border rounded-3">
														<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
															Incoterm
														</legend>
														<Row className="gy-3 pe-3 ps-3">
															<SelectComponent
																isSearchServer={true}
																label=""
																options={listConterm}
																name="incoterm"
																onBlur={() => {
																	handleBlur({ target: { name: "incoterm" } });
																}}
																onChange={(option) => {
																	setFieldValue("incoterm", option);
																	setIncoterm(option);
																}}
																validate={{
																	required: { value: true },
																}}
																value={incoterm}
																placeholder={"Select incoterm"}
																fieldValue="id"
																display="name"
															/>
														</Row>
													</fieldset>
												</div>
											</div>
										</Col>
										<Col lg={2}>
											<div className="d-flex align-items-center justify-content-center">
												<div className="mb-0 w-100 pb-1">
													<fieldset className="border rounded-3">
														<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
															Use Invoice From TNT
														</legend>
														<Row className="py-2 pe-3 ps-3">
															<div className="d-flex align-items-center form-check">
																<Input
																	style={{ fontSize: "15px" }}
																	className="form-check-input me-2"
																	type="checkbox"
																	// role="switch"
																	id="useInvoiceFromTNT"
																	checked={useInvoiceFromTNT}
																	onChange={() => {
																		setUseInvoiceFromTNT(!useInvoiceFromTNT);
																	}}
																/>
																<span
																	className="form-check-label"
																	htmlFor="useInvoiceFromTNT"
																	onClick={() => {
																		setUseInvoiceFromTNT(!useInvoiceFromTNT);
																	}}>
																	Use Invoice From TNT
																</span>
															</div>
														</Row>
													</fieldset>
												</div>
											</div>
										</Col>
										<Col lg={2}>
											<div className="d-flex align-items-center">
												<div className="mb-0 w-100 pb-1">
													<fieldset className="border rounded-3">
														<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
															Shipment Purpose{" "}
															<span>
																(<span className={"text-danger"}>*</span>)
															</span>
														</legend>
														<Row className="gy-3 pe-3 ps-3">
															<SelectComponent
																isSearchServer={true}
																label=""
																options={listShipmentPurpose}
																name="shipmentPurpose"
																onBlur={() => {
																	handleBlur({ target: { name: "shipmentPurpose" } });
																}}
																onChange={(option) => {
																	setFieldValue("shipmentPurpose", option);
																	setShipmentPurpose(option);
																}}
																validate={{
																	required: { value: true },
																}}
																value={shipmentPurpose}
																placeholder={"Select shipment purpose"}
																fieldValue="id"
																display="name"
																requiredField
																touched={touched.shipmentPurpose && errors.shipmentPurpose ? true : false}
																error={touched.shipmentPurpose && errors.shipmentPurpose}
															/>
														</Row>
													</fieldset>
												</div>
											</div>
										</Col>
										<Col lg={3}>
											<div className="d-flex align-items-center">
												<div className="mb-0 w-100 pb-1">
													<fieldset className="border rounded-3">
														<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
															Account Number of Customer
														</legend>
														<Row className="gy-3">
															<InputComponent
																type="text"
																name={`tntAccountNumber`}
																value={tntAccountNumber}
																onChange={(value, name) => {
																	setTNTAccountNumber(value);
																	setFieldValue("tntAccountNumber", value);
																}}
																placeholder={"Account Number of Customer"}
															/>
														</Row>
													</fieldset>
												</div>
											</div>
										</Col>
										<Col lg={3}>
											<div className="d-flex align-items-center">
												<div className="mb-0 w-100 pb-1">
													<fieldset className="border rounded-3">
														<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
															Description{" "}
															<span>
																(<span className={"text-danger"}>*</span>)
															</span>
														</legend>
														<Row className="gy-3">
															<InputComponent
																type="text"
																name={`description`}
																value={description}
																onChange={(value, name) => {
																	setDescription(value);
																	setFieldValue("description", value);
																}}
																placeholder={"Description"}
																onBlur={handleBlur}
																invalid={touched.description && errors.description ? true : false}
																error={touched?.description && errors?.description}
															/>
														</Row>
													</fieldset>
												</div>
											</div>
										</Col>
									</Row>

									{type == "international" ? (
										<>
											<Row>
												<div>
													<legend className="float-none w-auto px-3 text-left fw-bold" style={{ fontSize: "15px" }}>
														Commodities Information
													</legend>
													<ListCommoditiesTable valuesCheckPrice={valuesCheckPrice} setFieldValue={setFieldValue} touched={touched} errors={errors} />
												</div>
											</Row>
										</>
									) : (
										<></>
									)}

									<div className="mb-3 w-100 mx-auto d-flex align-items-center justify-content-end">
										<div style={{ marginRight: "29px" }}>
											<Button
												// disabled={!dirty}
												type="button"
												color="success"
												onClick={() => {
													handleSubmit(values);
												}}>
												Create Shipment
											</Button>
										</div>
									</div>
								</ModalBody>
							</FormSubmit>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
};

export default ModalTNT;
