import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import InputComponent from "../../../../../Components/Hooks/InputComponent";
import { listLogisticsAddPackageSize, listLogisticsDeletePackageSize, listLogisticsUpdatePackageSize } from "../../../../../store/actions";
import PopoverDelete from "../../../../../Components/Hooks/PopoverDelete";

function TableQuickSelectRows({
	data,
	index,
	handleChange,
	disabledPO = {
		pointerEvents: "unset",
		opacity: 1,
	},
	deleteTableRows,
	handleChangeWeight,
}) {
	const { id, name, sizeW, sizeL, sizeH, weight, statusChecked } = data;

	const dispatch = useDispatch();

	const [isOpen, setIsOpen] = useState(false);

	const [flagDisable, setFlagDisable] = useState(false);
	const [checked, setCheck] = useState(false);

	const [isErrorName, setIsErrorName] = useState(false);
	const [isErrorSizeW, setIsErrorSizeW] = useState(false);
	const [isErrorSizeL, setIsErrorSizeL] = useState(false);
	const [isErrorSizeH, setIsErrorSizeH] = useState(false);
	const [isErrorWeight, setIsErrorWeight] = useState(false);

	const formikRef = useRef(null);

	useEffect(() => {
		setCheck(statusChecked);
	}, [statusChecked]);

	useEffect(() => {
		if (id == "" && name == "" && sizeW == "" && sizeL == "" && sizeH == "" && weight == "") {
			setIsErrorName(false);
			setIsErrorSizeW(false);
			setIsErrorSizeL(false);
			setIsErrorSizeH(false);
			setIsErrorWeight(false);
		} else {
			if (name != "") {
				setIsErrorName(false);
			} else setIsErrorName(true);
			if (sizeW != "" && sizeW >= 1 && sizeW <= 999) {
				setIsErrorSizeW(false);
			} else setIsErrorSizeW(true);
			if (sizeL != "" && sizeL >= 1 && sizeL <= 999) {
				setIsErrorSizeL(false);
			} else setIsErrorSizeL(true);
			if (sizeH != "" && sizeH >= 1 && sizeH <= 999) {
				setIsErrorSizeH(false);
			} else setIsErrorSizeH(true);
			if (weight != "" && weight > 0 && weight <= 999) {
				setIsErrorWeight(false);
			} else setIsErrorWeight(true);
		}
	}, [name, sizeW, sizeL, sizeH, weight]);

	const handleSave = () => {
		if (name != "" && sizeW != "" && sizeL != "" && sizeH != "" && weight != "") {
			let request = {
				name: name,
				sizeW: sizeW,
				sizeL: sizeL,
				sizeH: sizeH,
				weight: weight,
			};
			if (id != "") {
				request.id = id;
				dispatch(listLogisticsUpdatePackageSize(request));
			} else {
				dispatch(listLogisticsAddPackageSize(request, index));
			}
		}
	};

	return (
		<React.Fragment>
			<td>
				<div style={disabledPO}>
					<InputComponent
						type="text"
						value={name || ""}
						onChange={(value) => handleChange(index, value, "name")}
						name="name"
						className="form-control  fst-italic"
						style={{ fontSize: 11 }}
						placeholder="Name"
						disabled={flagDisable}
						requiredField
						error={isErrorName}
						invalid={isErrorName}
					/>
				</div>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={weight || ""}
					onChange={(value) => {
						const estimate = Math.sqrt((value * 5000) / 18);
						const dataUpdate = {
							weight: value,
							sizeH: 18,
							sizeL: Math.round(estimate),
							sizeW: Math.round(estimate),
						};
						handleChangeWeight(index, dataUpdate);
					}}
					name="weight"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Weight"
					disabled={flagDisable}
					requiredField
					error={isErrorWeight}
					invalid={isErrorWeight}
				/>
			</td>
			<td className={`text-center `}>
				<InputComponent
					type="text"
					value={sizeL || ""}
					onChange={(value) => handleChange(index, value, "sizeL")}
					name="sizeL"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Length"
					disabled={flagDisable}
					requiredField
					error={isErrorSizeL}
					invalid={isErrorSizeL}
				/>
			</td>
			<td className={`text-center `}>
				<InputComponent
					type="text"
					value={sizeW || ""}
					onChange={(value) => handleChange(index, value, "sizeW")}
					name="sizeW"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Width"
					disabled={flagDisable}
					requiredField
					error={isErrorSizeW}
					invalid={isErrorSizeW}
				/>
			</td>
			<td>
				<InputComponent
					type="text"
					value={sizeH || ""}
					onChange={(value) => handleChange(index, value, "sizeH")}
					name="sizeH"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Height"
					disabled={flagDisable}
					requiredField
					error={isErrorSizeH}
					invalid={isErrorSizeH}
				/>
			</td>

			<td className="text-center">
				<ul className="list-inline hstack gap-2 mb-0">
					{/* <li className="list-inline-item" title="Edit">
						<Link
							className={"text-primary"}
							onClick={() => {
								// handleClickEdit(row);
							}}
							to="#">
							<i className="ri-edit-2-line align-bottom fs-16"></i>
						</Link>
					</li> */}
					<li className="list-inline-item" title="Edit">
						<Link
							className={name == "" || sizeW == "" || sizeL == "" || sizeH == "" || weight == "" ? "text-black-50 disabled-link" : "text-primary"}
							onClick={() => {
								if (!isErrorName && !isErrorSizeW && !isErrorSizeL && !isErrorSizeH && !isErrorWeight) {
									handleSave();
								}
							}}
							to="#">
							<i className=" ri-save-3-line align-bottom fs-16"></i>
						</Link>
					</li>
					<li className="list-inline-item" title="Delete">
						<React.Fragment>
							<Link
								id={`popoverPackage${index}`}
								// className="remove-item-btn text-danger"
								className={
									id == "" && name == "" && sizeW == "" && sizeL == "" && sizeH == "" && weight == "" ? "text-black-50 disabled-link" : "text-danger"
								}
								onClick={() => setIsOpen(!isOpen)}
								to="#">
								<i className="ri-delete-bin-line align-bottom fs-16"></i>
							</Link>
							<PopoverDelete
								isOpen={isOpen}
								target={`popoverPackage${index}`}
								toggle={() => setIsOpen(!isOpen)}
								handleCancel={() => setIsOpen(false)}
								handleConfirm={() => {
									if (id == "" && name == "" && sizeW == "" && sizeL == "" && sizeH == "" && weight == "") {
										//
									} else {
										if (id != "") {
											dispatch(listLogisticsDeletePackageSize(id));
										} else {
											deleteTableRows(index);
										}
									}
									setIsOpen(false);
								}}
							/>
						</React.Fragment>
						{/* <Link
							className={
								id == "" && name == "" && sizeW == "" && sizeL == "" && sizeH == "" && weight == "" ? "text-black-50 disabled-link" : "text-danger"
							}
							onClick={() => {
								if (id == "" && name == "" && sizeW == "" && sizeL == "" && sizeH == "" && weight == "") {
									//
								} else {
									if (id != "") {
										dispatch(listLogisticsDeletePackageSize(id));
									} else {
										deleteTableRows(index);
									}
								}
							}}
							to="#">
							<i className="ri-delete-bin-line align-bottom fs-16"></i>
						</Link> */}
					</li>
				</ul>
			</td>
			<td className="text-center">
				{name == "" || sizeW == "" || sizeL == "" || sizeH == "" || weight == "" || id == "" ? (
					<></>
				) : (
					<InputComponent
						type="checkbox"
						checked={checked || false}
						onChange={() => {
							setCheck(!checked);
							handleChange(index, !statusChecked, "statusChecked");
						}}
					/>
				)}
			</td>
		</React.Fragment>
	);
}

export default TableQuickSelectRows;
