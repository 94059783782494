import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import ListPackageTable from "./International/PackageInformation/ListPackageTable";
import ListCommoditiesTable from "./International/CommoditiesInformation/ListCommoditiesTable";
import DataTableCheckPrice from "./International/DataTableCheckPrice/DataTableCheckPrice";
import FromToInformation from "./International/FromToInformation/FromToInformation";
import LabelComponent from "../../Components/Hooks/LabelComponent";
import InputComponent from "../../Components/Hooks/InputComponent";
import { getDataFromApi } from "../../Components/Hooks/Functions";
import * as yup from "yup";
import ModalLoading from "../../Components/Hooks/ModalLoading";
import { Formik } from "formik";
import FormSubmit from "../../Components/Common/FormSubmit";
import { toast } from "react-toastify";
import { listLogisticsGetListAddressDefaultSearchAddressInternational } from "../../store/actions";

const courier = ["FEDEX", "DHL", "AUSPOST", "COURIERSPLEASE", "SENDLE", "TRANSDIRECT", "TNT"];

const International = ({ type = "domestic" }) => {
	const dispatch = useDispatch();
	const formikRef = useRef();
	const [token, setToken] = useState("");
	const [currentTimestamp, setCurrentTimestamp] = useState("");
	const [isOpenLoading, setIsOpenLoading] = useState(false);
	const [data, setData] = useState([
		// {
		// 	courierName: "AUSPOST",
		// 	serviceName: "AUS PARCEL REGULAR PACKAGE SMALL",
		// 	price: "10.60",
		// 	currency: "AUD",
		// 	priceUSD: 6.917705410167721,
		// 	serviceType: "AUS_PARCEL_REGULAR_PACKAGE_SMALL",
		// 	deliverDateRange: [],
		// 	isDeliverBussinessDayOnly: true,
		// 	historyId: "65e57aff83e0b6607398c8e5",
		// },
		// {
		// 	courierName: "AUSPOST",
		// 	serviceName: "AUS PARCEL REGULAR SATCHEL 500G",
		// 	price: "10.90",
		// 	currency: "AUD",
		// 	priceUSD: 7.113489525549827,
		// 	serviceType: "AUS_PARCEL_REGULAR_SATCHEL_500G",
		// 	deliverDateRange: [],
		// 	isDeliverBussinessDayOnly: true,
		// 	historyId: "65e57aff83e0b6607398c8e6",
		// },
		// {
		// 	courierName: "SENDLE",
		// 	serviceName: "STANDARD PICKUP",
		// 	price: 12.97,
		// 	currency: "AUD",
		// 	priceUSD: 8.464399921686354,
		// 	serviceType: "STANDARD-PICKUP",
		// 	deliverDateRange: [2, 6],
		// 	isDeliverBussinessDayOnly: true,
		// 	historyId: "65e57b0083e0b6607398c8e7",
		// },
		// {
		// 	courierName: "SENDLE",
		// 	serviceName: "STANDARD DROP OFF",
		// 	price: 12.97,
		// 	currency: "AUD",
		// 	priceUSD: 8.464399921686354,
		// 	serviceType: "STANDARD-DROPOFF",
		// 	deliverDateRange: [3, 7],
		// 	isDeliverBussinessDayOnly: true,
		// 	historyId: "65e57b0083e0b6607398c8e8",
		// },
		// {
		// 	courierName: "DHL",
		// 	serviceName: "EXPRESS DOMESTIC 12:00",
		// 	price: 39.21,
		// 	currency: "AUD",
		// 	priceUSD: 25.588983880441166,
		// 	serviceType: "1",
		// 	deliverDateRange: [0, 0],
		// 	isDeliverBussinessDayOnly: false,
		// 	historyId: "65e57b0083e0b6607398c8e9",
		// },
		// {
		// 	courierName: "DHL",
		// 	serviceName: "EXPRESS DOMESTIC",
		// 	price: 24.91,
		// 	currency: "AUD",
		// 	priceUSD: 16.256607713894144,
		// 	serviceType: "N",
		// 	deliverDateRange: [1, 1],
		// 	isDeliverBussinessDayOnly: false,
		// 	historyId: "65e57b0083e0b6607398c8ea",
		// },
	]);
	const [dataError, setDataError] = useState("");

	const [countResponseApi, setCountResponseApi] = useState(0);

	useEffect(() => {
		setCurrentTimestamp(Date.now());
	}, []);

	useEffect(() => {
		const url = process.env.REACT_APP_API_URL + "/api/map/get-user-session";
		getDataFromApi("GET", url, {}, "", currentTimestamp)
			.then((data) => {
				// Use the data as needed
				setToken(data?.token);
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	}, []);

	const validateSchema = yup.object({
		streetLineFrom: yup.string().required("Street line is required"),
		cityFrom: yup.string().required("City is required"),
		postalCodeFrom: yup.string().required("Postal Code is required"),
		stateFrom: yup.string().required("State is required"),
		countryFrom: yup.string().required("Country is required"),
		// phoneFrom: yup.string().required("Phone is required"),
		streetLineTo: yup.string().required("Street line is required"),
		cityTo: yup.string().required("City is required"),
		postalCodeTo: yup.string().required("Postal Code is required"),
		stateTo: yup.string().required("State is required"),
		countryTo: yup.string().required("Country is required"),
		// phoneTo: yup.string().required("Phone is required"),
		requestedPackageLineItems: yup.array().min(1, "Package information is required").required("Package information is required").nullable(),
		commodities: yup.array().min(1, "Commodities information is required").required("Commodities information is required").nullable(),
		requestedPackageLineItemsError: yup.boolean().oneOf([false], "The field is required"),
	});

	const submitForm = (values) => {
		for (const item of courier) {
			let data = {
				courier: item,
				from: values.from,
				to: values.to,
				requestedPackageLineItems: values.requestedPackageLineItems,
				commodities: values.commodities,
				// shipDateTime: values.shipDateTime,
				// domesticInformation: values.domesticInformation,
			};
			const dataCourier = handleCheckPrice(data);
		}
		// setIsOpenLoading(false);
	};

	const handleCheckPrice = async (requestBody) => {
		if (token != "") {
			const url = process.env.REACT_APP_API_URL + "/api/shipment/get-rate";
			return await getDataFromApi("POST", url, requestBody, token, currentTimestamp)
				.then((data) => {
					setDataError(data);
					if (data.length > 0) {
						setData((prev) => prev.concat(data));
					}
					setCountResponseApi((prev) => prev + 1);
				})
				.catch((error) => {
					setCountResponseApi((prev) => prev + 1);
					// console.error("Error fetching data:", error);
				});
		}
	};

	useEffect(() => {
		if (countResponseApi == courier.length) {
			setIsOpenLoading(false);
			dispatch(listLogisticsGetListAddressDefaultSearchAddressInternational(1, 10, "updatedAt", "desc", ""));
			if (data?.length == 0 && dataError?.error?.message) {
				toast.error(dataError?.error?.message);
			}
		}
	}, [countResponseApi]);

	const initialValues = {
		streetLineFrom: "",
		cityFrom: "",
		postalCodeFrom: "",
		stateFrom: "",
		countryFrom: "",
		phoneFrom: "",
		personNameFrom: "",
		companyNameFrom: "",
		emailAddressFrom: "",

		streetLineTo: "",
		cityTo: "",
		postalCodeTo: "",
		stateTo: "",
		countryTo: "",
		phoneTo: "",
		personNameTo: "",
		companyNameTo: "",
		emailAddressTo: "",

		courier: "",
		from: "",
		to: "",
		requestedPackageLineItems: [],
		requestedPackageLineItemsError: false,
		commodities: [],
		commoditiesData: [],
		shipDateTime: 0,
		domesticInformation: "",
		addressInfo: "",
	};

	return (
		<>
			<ModalLoading isOpen={isOpenLoading} />
			<Formik enableReinitialize initialValues={initialValues} validationSchema={validateSchema} onSubmit={submitForm} innerRef={formikRef}>
				{(formik) => {
					const { values, handleChange, setFieldValue, handleSubmit, errors, touched, handleBlur, isValid, dirty, validateForm } = formik;
					return (
						<FormSubmit onSubmit={handleSubmit}>
							<FromToInformation
								type={type}
								setFieldValue={setFieldValue}
								handleBlur={handleBlur}
								touched={touched}
								errors={errors}
								token={token}
								currentTimestamp={currentTimestamp}
							/>
							<Row>
								<div className="d-flex align-items-center">
									<div className="mb-0 w-100 pb-1">
										{/* <fieldset className="border rounded-3">
											<legend className="float-none w-auto px-3 text-left fw-bold">Package Information</legend> */}
										<ListPackageTable setFieldValue={setFieldValue} touched={touched} errors={errors} values={values} />
										{/* </fieldset> */}
									</div>
								</div>
							</Row>
							{/* <Row>
								<div className="d-flex align-items-center">
									<div className="mb-0 w-100 pb-1">
										<fieldset className="border rounded-3">
											<legend className="float-none w-auto px-3 text-left fw-bold">Commodities Information</legend>
											<ListCommoditiesTable setFieldValue={setFieldValue} touched={touched} errors={errors} values={values} />
										</fieldset>
									</div>
								</div>
							</Row> */}

							<Row>
								<div className="d-flex align-items-center justify-content-center">
									<Button
										style={{ marginTop: "28px" }}
										onClick={() => {
											setData([]);
											handleSubmit(values);
											if (Object.keys(errors).length === 0) {
												setIsOpenLoading(true);
											}
											setCountResponseApi(0);
										}}
										type="button"
										color="success">
										Check Price
									</Button>
								</div>
							</Row>
							<Row>
								<DataTableCheckPrice type={type} data={data} courier={courier} values={values} currentTimestamp={currentTimestamp} token={token} />
							</Row>
						</FormSubmit>
					);
				}}
			</Formik>
		</>
	);
};

export default International;
