import React, { useEffect, useRef } from "react";
import { Button, FormFeedback, Input, InputGroup } from "reactstrap";
import { regexSerialNumber } from "./ValidateComponent";
import unidecode from "unidecode";

const InputComponent = ({
	//Input Props
	id = "",
	name = "",
	type = "text",
	className = "",
	placeholder = "Search...",
	value = "",
	style = {},
	validate = {},
	valueUpperCase = false,
	maxLength = 100000,
	disabled = false,
	readOnly = false,
	checked = false,
	autoComplete = "on", //on or off
	autoFocus = false,
	size = "md",

	onChange = () => {},
	onKeyDown = () => {},
	onBlur = () => {},
	requiredField = false,
	invalid = false,
	error = "",
	onFocus = () => {},
	onClick = () => {},

	//Button Search Props
	onClickSearch = () => {},
	titleButton = "Search",
	styleButton = {},
	buttonType = "button",
	buttonColor = "primary",
	showButtonSearch = false,

	//Options Component Orther
	otherComponent = <></>,
	styleInputGroup = {},
	englishValue = true,

	//textarea
	rows = 1,

	onDoubleClick = () => {},
}) => {
	const isCheckBoxRadio = ["radio", "checkbox"].includes(type);
	const isFile = ["file"].includes(type);
	const isTypeNotSetSelectionRange = [
		"radio",
		"checkbox",
		"email",
		"button",
		"number",
		"color",
		"date",
		"datetime-local",
		"file",
		"hidden",
		"image",
		"month",
		"range",
		"reset",
		"submit",
		"tel",
		"time",
		"week",
	].includes(type);
	const inputRef = useRef();

	const selectionRef = useRef({ start: 0, end: 0 });

	const handleChange = (event) => {
		const input = event.target;
		const start = input.selectionStart;
		const end = input.selectionEnd;

		// Update the state with the uppercase value
		const convertedValue = englishValue ? unidecode(input.value) : input.value;
		const upperCaseText = valueUpperCase ? convertedValue.toUpperCase() : convertedValue;
		onChange(upperCaseText, name);

		// Store the updated selection position
		//selectionRef.current = { start, end };

		// fix autoFocus when edit
		// Store the updated selection position
		// selectionRef.current = { start, end };
		if (!isTypeNotSetSelectionRange) inputRef.current?.setSelectionRange(start, end);
	};

	// After the component updates (re-renders), set the selection range back to its original values
	// useEffect(() => {
	// 	if (!isTypeNotSetSelectionRange) {
	// 		const { start, end } = selectionRef.current;
	// 		inputRef.current.setSelectionRange(start, end);
	// 	}
	// }, [value]);

	// fix autoFocus when edit
	// useEffect(() => {
	// 	if (!isTypeNotSetSelectionRange) {
	// 		const start = value?.length ?? 0;
	// 		const end = value?.length + 1 ?? 0;
	// 		inputRef.current?.setSelectionRange(start, end);
	// 	}
	// }, [autoFocus]);

	const handleInputChange = (event, name) => {
		if (isCheckBoxRadio) {
			const value = event?.target?.checked ?? "";
			onChange(value, name);
			return;
		}
		if (isFile) {
			onChange(event, name);
			return;
		}
		handleChange(event);
	};

	const RenderComponent = () => {
		switch (type) {
			case "radio":
				return (
					<input
						style={style}
						checked={checked}
						onChange={(e) => {
							handleInputChange(e, name);
						}}
						onClick={onClick}
						name={name}
						type="radio"
						disabled={disabled}></input>
				);
			case "checkbox":
				return (
					<>
						<Input
							id={id}
							name={name}
							type="checkbox"
							className={`${className}`}
							placeholder={placeholder}
							value={value}
							checked={checked}
							onChange={(e) => {
								handleInputChange(e, name);
							}}
							onClick={onClick}
							onKeyDown={onKeyDown}
							style={style}
							validate={validate}
							onBlur={onBlur}
							onFocus={onFocus}
							invalid={invalid}
							rows={rows}
							disabled={disabled}
							readOnly={readOnly}
							innerRef={inputRef} // Use innerRef prop instead of ref for reactstrap components
							onDoubleClick={onDoubleClick}
						/>
						{otherComponent}

						{(requiredField && error && invalid) || (error && invalid) ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
					</>
				);
			default:
				return (
					<InputGroup style={styleInputGroup}>
						<Input
							id={id}
							name={name}
							type={type}
							className={`form-control ${className}`}
							placeholder={placeholder}
							value={value}
							checked={checked}
							onChange={(e) => {
								handleInputChange(e, name);
							}}
							onClick={onClick}
							onKeyDown={onKeyDown}
							style={style}
							validate={validate}
							onBlur={onBlur}
							onFocus={onFocus}
							invalid={invalid}
							rows={rows}
							disabled={disabled}
							readOnly={readOnly}
							innerRef={inputRef} // Use innerRef prop instead of ref for reactstrap components
							maxLength={maxLength}
							autoComplete={autoComplete}
							autoFocus={autoFocus}
							bsSize={size}
						/>
						{showButtonSearch && (
							<Button style={{ zIndex: 1, ...styleButton }} type={buttonType} color={buttonColor} onClick={onClickSearch}>
								{titleButton}
							</Button>
						)}
						{otherComponent}

						{(requiredField && error && invalid) || (error && invalid) ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
					</InputGroup>
				);
		}
	};

	return <>{RenderComponent()}</>;
};

export default InputComponent;
