import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Input, Modal, ModalBody, Row } from "reactstrap";
import LabelComponent from "../../Components/Hooks/LabelComponent";
import InputComponent from "../../Components/Hooks/InputComponent";
import DataTableComponent from "../../Components/Hooks/DataTableComponent";
import { Link } from "react-router-dom";
import { listLogisticsGetDefinitions, listLogisticsGetListMails } from "../../store/actions";
import { dateFormatString1, dateFormatString16 } from "../../Components/Common/FormatTime";
import moment from "moment";

const History = () => {
	const dispatch = useDispatch();
	const { dataListHistory, totalListHistory, dataListDefinitions } = useSelector((state) => state.DashboardListLogistics);

	const [data, setData] = useState([]);
	const [inputValueSearch, setInputValueSearch] = useState("");
	const [totalRows, setTotalRows] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [order, setOrder] = useState("updatedAt");
	const [sortDirection, setSortDirection] = useState("desc");
	const [perPage, setPerPage] = useState(50);
	const [shippingType, setShippingType] = useState([]);
	const [domestic, setDomestic] = useState(false);
	const [international, setInternational] = useState(false);
	const [modalPreviewFile, setModalPreviewFile] = useState(false);
	const [dataPreviewFile, setDataPreviewFile] = useState("");
	const [typeFilePreview, setTypeFilePreview] = useState("pdf");
	const [dataViewFile, setDataViewFile] = useState("");
	const [typeFile, setTypeFile] = useState("");
	const [listStatus, setListStatus] = useState([]);
	const [isBooked, setIsBooked] = useState(false);

	useEffect(() => {
		handleSearch();
	}, [sortDirection, order, currentPage, perPage, shippingType, isBooked]);

	useEffect(() => {
		dispatch(listLogisticsGetDefinitions());
	}, []);

	useEffect(() => {
		const statusArr = Object.values(dataListDefinitions?.SHIPPING_TYPE_IN_HISTORY ?? {})?.reduce((prevResult, currItem) => {
			prevResult.push({
				id: currItem,
				code: currItem,
			});
			return prevResult;
		}, []);
		setListStatus(statusArr);
	}, [dataListDefinitions]);

	const handleSearch = () => {
		let shippingTypeData = "";
		if (shippingType.length == 1) {
			shippingTypeData = shippingType[0];
		}
		dispatch(listLogisticsGetListMails(currentPage, perPage, order, sortDirection, inputValueSearch, shippingTypeData, isBooked));
	};

	useEffect(() => {
		setData(dataListHistory);
		setTotalRows(totalListHistory);
	}, [dataListHistory]);

	const togglePreviewFile = useCallback(() => {
		if (modalPreviewFile) {
			setModalPreviewFile(false);
			setTimeout(() => {
				setDataPreviewFile("");
				setTypeFile("");
				setTypeFilePreview("");
			}, 500);
		} else {
			setModalPreviewFile(true);
		}
	}, [modalPreviewFile]);

	const handleDownloadFile = (file) => {
		fetch(file)
			.then((response) => response.blob())
			.then((blob) => {
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = "down_file";
				link.click();
			})
			.catch(console.error);
	};

	const columns = useMemo(
		() => [
			{
				name: <span>Time</span>,
				sortable: true,
				selector: (row) => {
					const timeFormat = moment(row?.time, "YYYY-MM-DD HH:mm").isValid()
						? moment(new Date(row?.time), "YYYY-MM-DD HH:mm").format(dateFormatString16)
						: "";
					return timeFormat;
					// return row?.time;
				},
				wrap: true,
				width: "150px",
				sortField: "time",
			},
			{
				name: <span>From</span>,
				selector: (row) => {
					return row?.from;
				},
				sortable: false,
				wrap: true,
				grow: 2,
				sortField: "from",
				// width: "160px",
			},
			{
				name: <span>To</span>,
				sortable: false,
				selector: (row) => {
					return row?.to;
				},
				wrap: true,
				grow: 2,
			},
			{
				name: <span>Services Name</span>,
				sortable: false,
				selector: (row) => {
					return (
						<div className="py-1">
							{row?.serviceName}
							{row?.courierName === "COURIERSPLEASE" && row?.couponResult && (
								<div>
									{row?.couponResult?.ezyLinks && Number(row?.couponResult?.ezyLinks) > 0 && (
										<div className="mt-1">{row?.couponResult?.ezyLinks} * EzyLinks Coupon</div>
									)}
									{row?.couponResult?.metro && Number(row?.couponResult?.metro) > 0 && (
										<div className="mt-1">{row?.couponResult?.metro} * Metro Coupon</div>
									)}
								</div>
							)}
						</div>
					);
				},
				wrap: true,
				grow: 1,
				width: "250px",
			},
			{
				name: <span>Price Return</span>,
				sortable: false,
				selector: (row) => {
					const priceReturn = row?.price ? parseFloat(row?.price).toFixed(2) + " " + (row?.currency ? row?.currency : "") : "";
					return priceReturn;
				},
				wrap: true,
				width: "100px",
			},
			{
				name: <span>Price (USD)</span>,
				sortable: false,
				selector: (row) => {
					return row?.priceUSD ? parseFloat(row?.priceUSD).toFixed(2) : "";
				},
				wrap: true,
				width: "100px",
			},
			{
				name: <span>Tracking Number</span>,
				sortable: false,
				selector: (row) => {
					return row?.trackingNumber;
				},

				wrap: true,
				width: "150px",
			},
			{
				name: <span>Attachedment</span>,
				sortable: false,
				selector: (row) => {
					const resultObjectLabel = (row?.documents ?? []).find((obj) => obj.type === "LABEL");
					let dataLabel = "";
					let typeFileData = "base64";
					if (resultObjectLabel) {
						if (resultObjectLabel?.base64) {
							dataLabel = resultObjectLabel?.base64;
						} else {
							dataLabel = resultObjectLabel?.url;
							typeFileData = "url";
						}
					}
					let dataComercialInvoice = "";
					const resultObjectInvoice = (row?.documents ?? []).find((obj) => obj.type === "INVOICE");
					if (resultObjectInvoice) {
						if (resultObjectLabel?.base64) {
							dataComercialInvoice = resultObjectLabel?.base64;
						} else {
							dataComercialInvoice = resultObjectLabel?.url;
							typeFileData = "url";
						}
					}
					return (
						<React.Fragment>
							{dataLabel != "" ? (
								<div className="pb-1">
									Label:{" "}
									<>
										<Link
											className="edit-item-btn text-primary"
											to="#"
											onClick={() => {
												setTypeFile(typeFileData);
												if (typeFileData == "url") {
													openNewTab(dataLabel);
												} else {
													setTypeFilePreview("pdf");
													togglePreviewFile();
													setDataViewFile(dataLabel);
													setDataPreviewFile(dataLabel);
												}
											}}>
											View
										</Link>
										/
										<Link
											className="edit-item-btn  text-primary"
											to="#"
											onClick={() => {
												setTypeFile(typeFileData);
												if (typeFileData == "url") {
													handleDownloadFile(dataLabel);
												} else {
													downloadFile(dataLabel);
												}
											}}>
											Download
										</Link>
									</>
								</div>
							) : (
								<></>
							)}
							{dataComercialInvoice != "" ? (
								<div className="pt-1">
									Comercial Invoice:{" "}
									<>
										<Link
											className="edit-item-btn text-primary"
											to="#"
											onClick={() => {
												setTypeFile(typeFileData);
												if (typeFileData == "url") {
													openNewTab(dataLabel);
												} else {
													setTypeFilePreview("pdf");
													togglePreviewFile();
													setDataViewFile(dataComercialInvoice);
													setDataPreviewFile(dataComercialInvoice);
												}
											}}>
											View
										</Link>
										/
										<Link
											className="edit-item-btn  text-primary"
											to="#"
											onClick={() => {
												setTypeFile(typeFileData);
												if (typeFileData == "url") {
													handleDownloadFile(dataComercialInvoice);
												} else {
													downloadFile(dataComercialInvoice);
												}
											}}>
											Download
										</Link>
									</>
								</div>
							) : (
								<></>
							)}
						</React.Fragment>
					);
				},
				wrap: true,
				width: "220px",
			},
		],
		[]
	);

	const openNewTab = (url) => {
		window.open(url, "_blank");
	};

	const downloadFile = (base64Data) => {
		// Convert base64 to binary data
		const binaryData = atob(base64Data);

		// Create a Blob object from the binary data
		const blob = new Blob([new Uint8Array(Array.from(binaryData).map((char) => char.charCodeAt(0)))], {
			type: "application/octet-stream",
		});

		// Get the current date and time components
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
		const day = currentDate.getDate().toString().padStart(2, "0");
		const hours = currentDate.getHours().toString().padStart(2, "0");
		const minutes = currentDate.getMinutes().toString().padStart(2, "0");
		const seconds = currentDate.getSeconds().toString().padStart(2, "0");

		// Create a download link and trigger the download
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = `file_${year}${month}${day}${hours}${minutes}${seconds}.pdf`; // Set the filename with the current date and time
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const onRowClicked = (row, event) => {
		// setInfo(row);
	};

	const conditionalRowStyles = [
		// {
		// 	when: (row) => typeof row.id !== "undefined",
		// 	classNames: ["selectRowData"],
		// 	style: {
		// 		"&:hover": {
		// 			cursor: "pointer",
		// 		},
		// 	},
		// },
	];

	const onRowDoubleClicked = (row, event) => {
		// if (arrayPageRoles.includes("CONTACTS_EDIT")) handleEditClick(row);
	};

	return (
		<Row className="g-3">
			{/* Modal Preview PDF File */}
			<Modal
				id="showModalPreviewFile"
				isOpen={modalPreviewFile}
				toggle={togglePreviewFile}
				centered
				className={typeFilePreview !== "image" ? "modal-full-width" : "modal-md"}>
				<ModalBody style={{ width: "100%", height: typeFilePreview !== "image" ? "870px" : "auto" }}>
					<div className="position-absolute me-1 mt-1" style={{ top: 0, right: 0 }}>
						<Link to="#" onClick={() => togglePreviewFile()}>
							<i className="ri-close-line fs-2"></i>
						</Link>
					</div>
					<div className="h-100 d-flex align-items-center justify-content-center ps-3 pe-3">
						{dataPreviewFile !== "" ? (
							typeFile === "base64" ? (
								<embed src={`data:application/pdf;base64,${dataPreviewFile}`} frameBorder="0" width="100%" height="840px" />
							) : (
								<>
									<embed src={dataPreviewFile} type="application/pdf" width="100%" height="840px"></embed>
								</>
							)
						) : (
							<div className="text-center">
								<lord-icon
									src="https://cdn.lordicon.com/xjovhxra.json"
									trigger="loop"
									colors="primary:#0ab39c,secondary:#f06548"
									style={{ width: "100px", height: "100px" }}></lord-icon>
								<div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
									<h4>Loading...</h4>
									<p className="text-muted mx-4 mb-0">Please wait a few seconds...</p>
								</div>
							</div>
						)}
					</div>
				</ModalBody>
			</Modal>
			<Col lg={12}>
				<div className="pb-2">
					<Row className="d-flex align-items-end g-3">
						<Col md={6} lg={4} xl={4} xxl={4}>
							<InputComponent
								showButtonSearch
								type="search"
								className="form-control"
								placeholder={"Search..."}
								value={inputValueSearch}
								onChange={(value) => setInputValueSearch(value)}
								onKeyDown={(keyEvent) => {
									if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
										setCurrentPage(1);
										handleSearch();
									}
								}}
								onClickSearch={() => {
									setCurrentPage(1);
									handleSearch();
								}}
							/>
						</Col>
						<Col md={6} lg={8} xl={8} xxl={5}>
							<div className="ps-5">
								<Row className="g-2">
									{listStatus.map((item, key) => {
										if (item?.id == "Domestic") {
											return (
												<Col xs={4} key={key}>
													<div className="form-check">
														<InputComponent
															name="checkbox_secure_domestic"
															className="form-check-input"
															type="checkbox"
															id={"checkbox_secure_domestic"}
															value={item?.id}
															checked={domestic}
															onChange={(value) => {
																setDomestic(!domestic);
																if (domestic) {
																	setShippingType((prev) => prev.filter((option) => option !== item?.id));
																} else {
																	setShippingType((prev) => [...prev, item?.id]);
																}
															}}
															onBlur={(e) => {
																// handleBlur(e);
															}}
														/>
														<LabelComponent title="Domestic" htmlFor="checkbox_secure_domestic" />
													</div>
												</Col>
											);
										} else {
											return (
												<Col xs={4} key={key}>
													<div className="form-check">
														<InputComponent
															name="checkbox_international"
															className="form-check-input"
															type="checkbox"
															id={"checkbox_international"}
															value={item?.id}
															checked={international}
															onChange={(value) => {
																setInternational(!international);
																if (international) {
																	setShippingType((prev) => prev.filter((option) => option !== item?.id));
																} else {
																	setShippingType((prev) => [...prev, item?.id]);
																}
															}}
															onBlur={(e) => {
																// handleBlur(e);
															}}
														/>
														<LabelComponent title="International" htmlFor="checkbox_international" />
													</div>
												</Col>
											);
										}
									})}
									<Col xs={4}>
										<div className="form-check">
											<InputComponent
												name="checkbox_isBooked"
												className="form-check-input"
												type="checkbox"
												id={"checkbox_isBooked"}
												value={"Booked"}
												checked={isBooked}
												onChange={(value) => {
													setIsBooked(!isBooked);
												}}
											/>
											<LabelComponent title="Booked" htmlFor="checkbox_isBooked" />
										</div>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</div>
				<div>
					<DataTableComponent
						keyField={"id"}
						fixedHeaderScrollHeight={"calc(75vh)"}
						columns={columns}
						data={data}
						// loading={loading}
						// setLoading={setLoading}
						dense={false} // Thu gọn chiều cao của hàng. có thể được ghi đè thông qua các hàng chủ đề.denseHeight.
						totalRows={totalRows}
						currentPage={currentPage}
						setOrder={setOrder}
						setSortDirection={setSortDirection}
						setCurrentPage={setCurrentPage}
						setPerPage={setPerPage}
						onRowClicked={onRowClicked}
						// selectableRows={true}
						// setSelectValues={setSelectValues}
						sortServer={true}
						conditionalRowStyles={conditionalRowStyles}
						onRowDoubleClicked={onRowDoubleClicked}
					/>
				</div>
			</Col>
		</Row>
	);
};

export default History;
