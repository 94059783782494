/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { debounce } from "lodash";
import _ from "lodash";
import { Col, Row, Card, CardBody, FormFeedback, Nav, NavItem, NavLink, TabContent, TabPane, Container } from "reactstrap";

// Import Images
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
//redux
import { useSelector, useDispatch } from "react-redux";
import FormSubmit from "../../Components/Common/FormSubmit";
import { Formik } from "formik";
import History from "./History";
import Domestic from "./Domestic";
import International from "./International";
import {
	getListPackageSize,
	listLogisticsGetListAddress,
	listLogisticsGetListAddressDefault,
	listLogisticsGetListAddressDefaultSearchAddressDomestic,
	listLogisticsGetListAddressDefaultSearchAddressInternational,
} from "../../store/actions";

const Logistics = () => {
	document.title = "Logistics | IP Supply";
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const [tabSelected, setTabSelected] = useState(1);
	const version = localStorage.getItem("versionLogistics");
	const buildNumber = 13;

	const [currentPage, setCurrentPage] = useState(1);
	const [order, setOrder] = useState("_iid");
	const [sortDirection, setSortDirection] = useState("asc");
	const [perPage, setPerPage] = useState(50);

	const { dataValueDomesticToInternational, loadingPage } = useSelector((state) => state.DashboardListLogistics);

	useEffect(() => {}, []);

	useEffect(() => {
		dispatch(getListPackageSize(currentPage, perPage, order, sortDirection));
	}, []);

	useEffect(() => {
		dispatch(listLogisticsGetListAddressDefaultSearchAddressDomestic(1, 10, "updatedAt", "desc", ""));
		dispatch(listLogisticsGetListAddressDefaultSearchAddressInternational(1, 10, "updatedAt", "desc", ""));
		dispatch(listLogisticsGetListAddressDefault());
	}, [loadingPage]);

	// const { userEmail, arrayPageRoles, fullName, userId, typeUser, userName } = useAuthUser();
	// const currentUser = useAuthUser();
	const [apiVersion, setApiVersion] = useState("");
	useEffect(() => {
		setApiVersion(buildNumber);
	}, [buildNumber]);

	useEffect(() => {
		if (apiVersion != "" && typeof apiVersion != "undefined") caching();
	}, [apiVersion]);

	const caching = () => {
		if (version != apiVersion) {
			if ("caches" in window) {
				caches.keys().then((names) => {
					// Delete all the cache files
					names.forEach((name) => {
						caches.delete(name);
					});
				});
				// Makes sure the page reloads. Changes are only visible after you refresh.
				window.location.reload(true);
			}
			localStorage.setItem("versionLogistics", apiVersion);
		}
	};

	const { error } = useSelector((state) => state.DashboardListMails);

	const formikRef = useRef();

	const formikRefSendMail = useRef(null);

	useEffect(() => {
		if (dataValueDomesticToInternational?.from) {
			setTabSelected(2);
		}
	}, [dataValueDomesticToInternational]);

	return (
		<React.Fragment>
			<div className="auth-page-content font-lato mt-2">
				<Container fluid>
					<ToastContainer closeButton={false} />
					<Row className="justify-content-center">
						<Col lg={10}>
							<Card className="pe-2 ps-2">
								<CardBody>
									<Row className="justify-content-center">
										<Col lg={12} className="border p-0 ">
											<Nav tabs className="nav-tabs-custom">
												<NavItem>
													<NavLink role="button" className="ps-4 pe-4" active={tabSelected === 1} onClick={() => setTabSelected(1)}>
														Domestic
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink role="button" className="ps-4 pe-4" active={tabSelected === 2} onClick={() => setTabSelected(2)}>
														International
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink role="button" className="ps-4 pe-4" active={tabSelected === 3} onClick={() => setTabSelected(3)}>
														History
													</NavLink>
												</NavItem>
											</Nav>
											<TabContent activeTab={tabSelected} className="pt-2">
												<TabPane tabId={1}>
													<div style={{ minHeight: "100vh" }} className="pe-2 ps-2">
														<Domestic type={"domestic"} />
													</div>
												</TabPane>
												<TabPane tabId={2}>
													<div style={{ minHeight: "100vh" }} className="pe-2 ps-2">
														<International type={"international"} />
													</div>
												</TabPane>
												<TabPane tabId={3}>
													<div style={{ minHeight: "90vh" }} className="pe-2 ps-2">
														<History />
													</div>
												</TabPane>
											</TabContent>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Logistics;
