import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import logoCheck from "../../assets/images/erp/anticon-check-circle.png";
import { Link } from "react-router-dom";
import dataIcon from "../../common/dataIcon";
import Tooltip from "rc-tooltip";

const setWithExpiry = (key, value, ttl = 2592000000) => {
	const now = new Date();

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	};
	localStorage.setItem(key, JSON.stringify(item));
};

const getWithExpiry = (key) => {
	const itemStr = localStorage.getItem(key);

	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}

	const item = JSON.parse(itemStr);
	const now = new Date();

	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
};

const TooltipValue = ({ value = "", name, placement = "top" }) => {
	if (name !== "" && typeof name !== "undefined") {
		return (
			//.rc-tooltipCustom
			<Tooltip overlayClassName={"rc-tooltipCustom"} placement={placement} overlay={<span style={{ whiteSpace: "pre-line" }}>{name}</span>}>
				<span>{value}</span>
			</Tooltip>
		);
	} else {
		return (
			<Tooltip overlayClassName={"rc-tooltipCustom "} placement={placement} overlay={<span style={{ whiteSpace: "pre-line" }}>{value}</span>}>
				<span>{value}</span>
			</Tooltip>
		);
	}
};

const TooltipValueNumber = ({ value }) => {
	const values = value ? value : "";
	return (
		<>
			{value ? (
				<>
					<Tooltip overlayClassName={"rc-tooltipCustom "} placement="top" overlay={<span className="fs-10">{value}</span>}>
						<span className="badge bg-info">
							<NumericFormat value={values} displayType={"text"} thousandSeparator={true} prefix={""} />
						</span>
					</Tooltip>
				</>
			) : (
				<></>
			)}
		</>
	);
};

const VPNCopy = ({ vpnCode, isTrust, vpnName, isFromSearchVPNPage = false }) => {
	const [copy, setCopy] = useState(false);

	useEffect(() => {
		if (copy) {
			setInterval(() => {
				setCopy(false);
			}, 2000);
		}
	}, [copy]);

	return (
		<span>
			{isTrust ? (
				<img src={logoCheck} alt="Check" height={10} style={{ marginLeft: "3px", marginRight: "3px", marginBottom: "2px" }} />
			) : (
				<span className={`${isFromSearchVPNPage && "mx-2"}`}></span>
			)}
			<>
				<Tooltip
					overlayClassName={"rc-tooltipCustom"}
					placement="top"
					overlay={<span className="fs-10">{vpnName ? vpnName : vpnCode ? vpnCode : "Available name!"}</span>}>
					<span>
						<Link to="#!">{vpnCode}</Link>
					</span>
				</Tooltip>
			</>
			<>
				<Tooltip overlayClassName={"rc-tooltipCustom"} placement="top" overlay={<span className="fs-10">Copy</span>}>
					<span>
						{copy ? (
							<i
								className="ri-check-double-line"
								style={{ color: "#54DE7E", marginLeft: 5, fontSize: 12 }}
								onClick={() => {
									setCopy(false);
								}}></i>
						) : (
							<i
								className="ri-file-copy-2-fill"
								style={{ color: "#54DE7E", marginLeft: 5, fontSize: 12 }}
								onClick={() => {
									navigator.clipboard.writeText(vpnCode);
									setCopy(true);
								}}></i>
						)}
					</span>
				</Tooltip>
			</>
		</span>
	);
};

const FieldCopy = ({ value, color, title = "Copy" }) => {
	const [copy, setCopy] = useState(false);

	useEffect(() => {
		if (copy) {
			setInterval(() => {
				setCopy(false);
			}, 2000);
		}
	}, [copy]);
	return (
		<>
			{value ? (
				<>
					<Tooltip overlayClassName={"rc-tooltipCustom"} placement="top" overlay={<span className="fs-10">{title}</span>}>
						<span>
							{copy ? (
								<i
									className="ri-check-double-line"
									style={{ color: color ? color : "#54DE7E", marginLeft: 5, fontSize: 12 }}
									onClick={() => {
										setCopy(false);
									}}
								/>
							) : (
								<i
									className="ri-file-copy-2-fill"
									style={{ color: color ? color : "#54DE7E", marginLeft: 5, fontSize: 12 }}
									onClick={() => {
										navigator.clipboard.writeText(value);
										setCopy(true);
									}}
								/>
							)}
						</span>
					</Tooltip>
				</>
			) : null}
		</>
	);
};

const CopyValueSelectedRows = ({ rows, field, fieldChild = "" }) => {
	const [copy, setCopy] = useState(false);

	useEffect(() => {
		if (copy) {
			setInterval(() => {
				setCopy(false);
			}, 2000);
		}
	}, [copy]);

	const copyData = (rows) => {
		let element = "";
		let value = "";
		for (let index = 0; index < rows.length; index++) {
			if (fieldChild !== "") {
				value = rows[index][field][fieldChild];
			} else {
				value = rows[index][field];
			}
			if (typeof value !== "undefined") {
				if (element === "") {
					element = value.trim();
				} else {
					element = element + "\n" + value.trim();
				}
			}
		}
		navigator.clipboard.writeText(element);
	};

	return rows.length > 0 ? (
		<>
			<Tooltip overlayClassName={"rc-tooltipCustom"} placement="top" overlay={<span className="fs-12">Copy</span>}>
				<>
					{copy ? (
						<i
							className="ri-check-double-line"
							style={{ color: "#54DE7E", marginLeft: 5, fontSize: 12 }}
							onClick={() => {
								setCopy(false);
							}}
						/>
					) : (
						<i
							className="ri-file-copy-2-fill"
							style={{ color: "#54DE7E", marginLeft: 5, fontSize: 12 }}
							onClick={() => {
								copyData(rows);
								setCopy(true);
							}}
						/>
					)}
				</>
			</Tooltip>
		</>
	) : null;
};

const getDataExpand = (nodes) => {
	let ids = [];

	nodes?.forEach(({ value, children }) => {
		ids = [...ids, value, ...getDataExpand(children)];
	});

	return ids;
};

const replaceIconFavicon = (pathName) => {
	var link = document.querySelector("link[rel~='icon']");
	if (!link) {
		link = document.createElement("link");
		link.rel = "icon";
		document.head.appendChild(link);
	}
	link.href = typeof dataIcon[pathName] == "undefined" ? dataIcon["default"] : dataIcon[pathName];
};

const getRolePerPageByCode = (arrayPageRolesWH, code) => {
	const rolePerPage = arrayPageRolesWH?.filter((item) => item.roleCode == code);
	return rolePerPage?.length > 0 ? rolePerPage[0] : "";
};

const handlePrintOrder = (
	data,
	config = {
		printable: [],
		properties: [],
		type: "json",
	}
) => {
	const { content } = data;

	// eslint-disable-next-line no-undef
	const printJS = require("print-js");
	printJS({
		properties: [],
		header: content,
		...config,
	});
};

function removeDuplicatesByKeys(arr1, arr2, key1, key2) {
	// Create a set to store unique values
	const uniqueValues = new Set();

	// Iterate over the first array and add values to the set
	arr1.forEach((item) => uniqueValues.add(item[key1]));

	// Iterate over the second array and remove duplicate values
	const filteredArray = arr2.filter((item) => !uniqueValues.has(item[key2]));

	return filteredArray;
}

const removeDuplicatesById = (array) => {
	const uniqueArray = array.reduce((accumulator, currentObj) => {
		const isDuplicate = accumulator.some((obj) => obj.id === currentObj.id);

		if (!isDuplicate) {
			accumulator.push(currentObj);
		}

		return accumulator;
	}, []);

	return uniqueArray;
};

const setValueStateProductWhenAdd = (inputValueSearchProductCode, setInputValueSearchProductCode, setInputValueVersionProductCode = null) => {
	const dataCharacterRemove = ["1P", "30P"];

	let delayDebounceFn = setTimeout(() => {
		const arrValueInput = inputValueSearchProductCode?.split(" ");
		if (arrValueInput?.length > 1) {
			if (arrValueInput[1]?.toUpperCase()?.indexOf("V") === 0) {
				const valueTwoCharacter = arrValueInput[0]?.toUpperCase()?.substring(0, 2);
				const valueThreeCharacter = arrValueInput[0]?.toUpperCase()?.substring(0, 3);
				if (dataCharacterRemove.includes(valueTwoCharacter) || dataCharacterRemove.includes(valueThreeCharacter)) {
					let isReplace = false;
					dataCharacterRemove.forEach((element) => {
						if (!isReplace && valueTwoCharacter == element && element.length == 2) {
							setInputValueSearchProductCode(arrValueInput[0]?.toUpperCase()?.replace(element, ""));
							isReplace = true;
						}
						if (!isReplace && valueThreeCharacter == element && element.length == 3) {
							setInputValueSearchProductCode(arrValueInput[0]?.toUpperCase()?.replace(element, ""));
							isReplace = true;
						}
					});
				} else {
					setInputValueSearchProductCode(arrValueInput[0]);
				}
				if (setInputValueVersionProductCode) setInputValueVersionProductCode(arrValueInput[1]);
			}
		} else {
			const valueTwoCharacter = inputValueSearchProductCode?.toUpperCase()?.substring(0, 2);
			const valueThreeCharacter = inputValueSearchProductCode?.toUpperCase()?.substring(0, 3);
			if (dataCharacterRemove.includes(valueTwoCharacter) || dataCharacterRemove.includes(valueThreeCharacter)) {
				let isReplace = false;
				dataCharacterRemove.forEach((element) => {
					if (!isReplace && valueTwoCharacter == element && element.length == 2) {
						setInputValueSearchProductCode(inputValueSearchProductCode?.toUpperCase()?.replace(element, ""));
						isReplace = true;
					}
					if (!isReplace && valueThreeCharacter == element && element.length == 3) {
						setInputValueSearchProductCode(inputValueSearchProductCode?.toUpperCase()?.replace(element, ""));
						isReplace = true;
					}
				});
			}
		}
	}, 500);

	return () => clearTimeout(delayDebounceFn);
};

const responesiveHeightRowForIpad = (width, height) => {
	if (width <= 1200 && height <= 1200 && width >= 768 && height >= 768) {
		return height > width ? "43px" : "35px";
	}
	return "43px";
};

const combineArraysById = (arr1, arr2) => {
	return arr1.map((item1) => {
		const matchingItem2 = arr2.find((item2) => item2.id === item1.id);
		return matchingItem2 ? { ...item1, ...matchingItem2 } : item1;
	});
};

const removeStyleHtml = (html) => {
	return html?.replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, "");
};

const getDataFromApi = (method = "GET", url, requestBody = {}, tokenValue = "", currentTimestamp) => {
	let header = {
		"Content-Type": "application/json",
		"my-auth": process.env.REACT_APP_AUTH_GOOGLE_MAP,
	};
	if (tokenValue != "") {
		header["session-token"] = tokenValue + currentTimestamp;
	}

	let config = {
		method: method,
		headers: { ...header },
	};

	if (method == "POST") {
		config.body = JSON.stringify(requestBody); // Convert request body to JSON string
	}

	return fetch(url, config)
		.then((response) => {
			if (!response.ok) {
				if (response.status === 422) {
					return response.json().then((errorData) => {
						// Handle the 422 response with error details
						return errorData;
					});
				} else {
					// Handle other non-successful responses
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
			}
			return response.json(); // This extracts the JSON from the response
		})
		.catch((error) => {
			console.error("Fetch error:", error);
			throw error;
		});
};

export {
	setWithExpiry,
	getWithExpiry,
	TooltipValue,
	TooltipValueNumber,
	VPNCopy,
	FieldCopy,
	CopyValueSelectedRows,
	getDataExpand,
	replaceIconFavicon,
	getRolePerPageByCode,
	handlePrintOrder,
	removeDuplicatesByKeys,
	removeDuplicatesById,
	setValueStateProductWhenAdd,
	responesiveHeightRowForIpad,
	combineArraysById,
	removeStyleHtml,
	getDataFromApi,
};
