import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import InputComponent from "../../../../Components/Hooks/InputComponent";
import { listLogisticsAddPackageSize, listLogisticsDeletePackageSize, listLogisticsUpdatePackageSize } from "../../../../store/actions";
import PopoverDelete from "../../../../Components/Hooks/PopoverDelete";

function TableQuickSelectRows({ data, index, handleChange }) {
	const disabledPO = {
		pointerEvents: "none",
		opacity: 1,
	};
	const { id, name, sizeW, sizeL, sizeH, weight, statusChecked } = data;

	const dispatch = useDispatch();

	const [isOpen, setIsOpen] = useState(false);

	const [flagDisable, setFlagDisable] = useState(false);
	const [checked, setCheck] = useState(false);

	const [isErrorName, setIsErrorName] = useState(false);
	const [isErrorSizeW, setIsErrorSizeW] = useState(false);
	const [isErrorSizeL, setIsErrorSizeL] = useState(false);
	const [isErrorSizeH, setIsErrorSizeH] = useState(false);
	const [isErrorWeight, setIsErrorWeight] = useState(false);

	const formikRef = useRef(null);

	useEffect(() => {
		setCheck(statusChecked);
	}, [statusChecked]);
	
	return (
		<React.Fragment>
			<td>
				<div style={disabledPO}>
					<InputComponent
						type="text"
						value={name || ""}
						onChange={(value) => handleChange(index, value, "name")}
						name="name"
						className="form-control  fst-italic"
						style={{ fontSize: 11, border: "none" }}
						placeholder="Name"
						disabled={flagDisable}
						requiredField
						error={isErrorName}
						invalid={isErrorName}
					/>
				</div>
			</td>
			<td className="text-center">
				<div style={disabledPO}>
					<InputComponent
						type="text"
						value={weight || ""}
						onChange={(value) => {
							const estimate = Math.sqrt((value * 5000) / 18);
							const dataUpdate = {
								weight: value,
								sizeH: 18,
								sizeL: Math.round(estimate),
								sizeW: Math.round(estimate),
							};
							// handleChangeWeight(index, dataUpdate);
						}}
						name="weight"
						className="form-control  fst-italic"
						style={{ fontSize: 11, border: "none" }}
						placeholder="Weight"
						disabled={flagDisable}
						requiredField
						error={isErrorWeight}
						invalid={isErrorWeight}
					/>
				</div>
			</td>
			<td className={`text-center `}>
				<div style={disabledPO}>
					<InputComponent
						type="text"
						value={sizeL || ""}
						onChange={(value) => handleChange(index, value, "sizeL")}
						name="sizeL"
						className="form-control  fst-italic"
						style={{ fontSize: 11, border: "none" }}
						placeholder="Length"
						disabled={flagDisable}
						requiredField
						error={isErrorSizeL}
						invalid={isErrorSizeL}
					/>
				</div>
			</td>
			<td className={`text-center `}>
				<div style={disabledPO}>
					<InputComponent
						type="text"
						value={sizeW || ""}
						onChange={(value) => handleChange(index, value, "sizeW")}
						name="sizeW"
						className="form-control  fst-italic"
						style={{ fontSize: 11, border: "none" }}
						placeholder="Width"
						disabled={flagDisable}
						requiredField
						error={isErrorSizeW}
						invalid={isErrorSizeW}
					/>
				</div>
			</td>
			<td>
				<div style={disabledPO}>
					<InputComponent
						type="text"
						value={sizeH || ""}
						onChange={(value) => handleChange(index, value, "sizeH")}
						name="sizeH"
						className="form-control  fst-italic"
						style={{ fontSize: 11, border: "none" }}
						placeholder="Height"
						disabled={flagDisable}
						requiredField
						error={isErrorSizeH}
						invalid={isErrorSizeH}
					/>
				</div>
			</td>
		</React.Fragment>
	);
}

export default TableQuickSelectRows;
