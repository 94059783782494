import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputComponent from "../../../../../../Components/Hooks/InputComponent";
import SelectComponent from "../../../../../../Components/Hooks/SelectComponent";
import { numberOnly } from "../../../../../../Components/Hooks/ValidateComponent";

function TableCommoditiesRows({
	data,
	index,
	handleChange,
	disabledPO = {
		pointerEvents: "unset",
		opacity: 1,
	},
	touched,
	errors,
}) {
	const { description, noOfUnits, weight, measures, width, height, customValue, unitValue, hsCodes, countryOfManufacture } = data;

	const { dataListDefinitions } = useSelector((state) => state.DashboardListLogistics);

	const dispatch = useDispatch();

	const [flagDisable, setFlagDisable] = useState(false);

	const [optionCountry, setOptionCountry] = useState(null);
	const [inputValueSearchCountry, setInputValueSearchCountry] = useState("");
	const [listCountry, setListCountry] = useState([]);

	const formikRef = useRef(null);

	useEffect(() => {
		const dataDefinitionsCountry = Object.values(dataListDefinitions?.LIST_COUNTRY_CODE ?? {})?.reduce((prevResult, currItem) => {
			prevResult.push({
				id: currItem.country_code,
				code: currItem.country_name,
			});
			return prevResult;
		}, []);
		setListCountry(dataDefinitionsCountry);
	}, [dataListDefinitions]);

	const handleChangeCountry = (option) => {
		setOptionCountry(option);
		handleChange(index, option?.id ? option.id : "", "countryOfManufacture");
		setInputValueSearchCountry("");
	};

	return (
		<React.Fragment>
			<td>
				<div style={disabledPO}>
					<InputComponent
						type="text"
						value={description || ""}
						onChange={(value) => handleChange(index, value, "description")}
						name="commodities"
						className="form-control  fst-italic"
						style={{ fontSize: 11 }}
						placeholder="Description of Good"
						disabled={flagDisable}
						invalid={errors?.commodities && errors?.commodities[index]?.description ? true : false}
						error={""}
					/>
				</div>
			</td>
			<td className={`text-center `}>
				<InputComponent
					type="text"
					value={noOfUnits || ""}
					onChange={(value) => {
						let num = Number(numberOnly(value));
						if (num <= 99999 && num >= 0) {
							handleChange(index, num, "noOfUnits");
						}
					}}
					name="commodities"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="No. of Units"
					disabled={flagDisable}
					invalid={errors?.commodities && errors?.commodities[index]?.noOfUnits ? true : false}
					error={""}
				/>
			</td>
			<td className={`text-center `}>
				<InputComponent
					type="text"
					value={weight || ""}
					onChange={(value) => {
						let num = Number(numberOnly(value));
						if (num <= 99999 && num >= 0) {
							handleChange(index, num, "weight");
						}
					}}
					name="commodities"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Net Weight"
					disabled={flagDisable}
					invalid={errors?.commodities && errors?.commodities[index]?.weight ? true : false}
					error={""}
				/>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={width || ""}
					onChange={(value) => {
						let num = Number(numberOnly(value));
						if (num <= 99999 && num >= 0) {
							handleChange(index, num, "width");
						}
					}}
					name="width"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Width"
					disabled={flagDisable}
				/>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={height || ""}
					onChange={(value) => {
						let num = Number(numberOnly(value));
						if (num <= 99999 && num >= 0) {
							handleChange(index, num, "height");
						}
					}}
					name="height"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Height"
					disabled={flagDisable}
				/>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={customValue || ""}
					onChange={(value) => {
						let num = Number(numberOnly(value));
						if (num <= 99999 && num >= 0) {
							handleChange(index, num, "customValue");
						}
					}}
					name="commodities"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Customs Value"
					disabled={flagDisable}
					invalid={errors?.commodities && errors?.commodities[index]?.customValue ? true : false}
					error={""}
				/>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={unitValue || ""}
					onChange={(value) => {
						let num = Number(numberOnly(value));
						if (num <= 99999 && num >= 0) {
							handleChange(index, num, "unitValue");
						}
					}}
					name="commodities"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Unit Value"
					disabled={flagDisable}
					invalid={errors?.commodities && errors?.commodities[index]?.unitValue ? true : false}
					error={""}
				/>
			</td>
			<td className="">
				<InputComponent
					type="text"
					value={hsCodes || ""}
					onChange={(value) => handleChange(index, value, "hsCodes")}
					name="commodities"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Commodity Manufacturer ID"
					disabled={flagDisable}
					invalid={errors?.commodities && errors?.commodities[index]?.hsCodes ? true : false}
					error={""}
				/>
			</td>
			<td className="">
				{/* <InputComponent
					type="text"
					value={countryOfManufacture || ""}
					onChange={(value) => handleChange(index, value, "countryOfManufacture")}
					name="countryOfManufacture"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Manufacturer Country"
					disabled={flagDisable}
				/> */}
				<SelectComponent
					isSearchServer={false}
					options={listCountry}
					name="commodities"
					onChange={(option) => {
						handleChangeCountry(option);
					}}
					value={optionCountry?.id ? optionCountry : []}
					placeholder="Select Country"
					fieldValue="id"
					setInputValue={setInputValueSearchCountry}
					inputValue={inputValueSearchCountry}
					display="code"
					requiredField
					touched={errors?.commodities && errors?.commodities[index]?.countryOfManufacture ? true : false}
					error={true}
				/>
			</td>
		</React.Fragment>
	);
}

export default TableCommoditiesRows;
