import {
	LOGISTICS_API_RESPONSE_SUCCESS,
	LOGISTICS_API_RESPONSE_ERROR,
	LOGISTICS_GET_LIST_HISTORY,
	LOGISTICS_GET_LIST_ADDRESS,
	LOGISTICS_ADD_ADDRESS,
	LOGISTICS_UPDATE_ADDRESS,
	LOGISTICS_SET_VALUE_FROM_TO_DOMESTIC,
	LOGISTICS_SET_VALUE_FROM_TO_INTERNATIONAL,
	LOGISTICS_GET_LIST_PACKAGE_SIZE,
	LOGISTICS_ADD_PACKAGE_SIZE,
	LOGISTICS_UPDATE_PACKAGE_SIZE,
	LOGISTICS_DELETE_PACKAGE_SIZE_SUCCESS,
	LOGISTICS_DELETE_PACKAGE_SIZE_FAIL,
	LOGISTICS_SET_VALUE_PACKAGE_SIZE_DOMESTIC,
	LOGISTICS_SET_VALUE_PACKAGE_SIZE_INTERNATIONAL,
	LOGISTICS_GET_DEFINITIONS,
	LOGISTICS_ADD_ROWS_PACKAGE_SIZE_SUCCESS,
	LOGISTICS_UPDATE_ROWS_PACKAGE_SIZE_SUCCESS,
	LOGISTICS_DELETE_ROWS_PACKAGE_SIZE_SUCCESS,
	LOGISTICS_GET_LIST_ADDRESS_DEFAULT,
	LOGISTICS_SET_VALUE_DOMESTIC_TO_INTERNATIONAL,
	LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_DOMESTIC,
	LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_INTERNATIONAL,
	LOGISTICS_DELETE_ADDRESS,
} from "./actionType";

const INIT_STATE = {
	error: {},
	loadingPage: false,
	dataListHistory: [],
	totalListHistory: 0,
	dataListAddress: [],
	dataListAddressSearchDefaultDomestic: [],
	dataListAddressSearchDefaultInternational: [],
	dataListAddressDefault: null,
	totalListAddress: 0,
	dataListPackagaSize: [],
	totalListPackageSize: 0,
	dataValueFromToDomestic: false,
	isClear: false,
	dataValuPackagaSizeDomestic: [],
	dataValuePackagaSizeInternational: [],
	dataListDefinitions: [],
	dataValueDomesticToInternational: false,
};

const DashboardListLogistics = (state = INIT_STATE, action) => {
	switch (action.type) {
		case LOGISTICS_API_RESPONSE_SUCCESS:
			switch (action.payload.actionType) {
				case LOGISTICS_GET_LIST_HISTORY:
					return {
						...state,
						dataListHistory: action.payload.data.data,
						totalListHistory: action.payload.data.total,
					};
				case LOGISTICS_GET_LIST_ADDRESS:
					return {
						...state,
						dataListAddress: action.payload.data.data,
						totalListAddress: action.payload.data.total,
					};
				case LOGISTICS_GET_LIST_ADDRESS_DEFAULT:
					return {
						...state,
						dataListAddressDefault: action.payload?.data?.data.find((item) => {
							return item.default == 1;
						}),
					};
				case LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_DOMESTIC:
					return {
						...state,
						dataListAddressSearchDefaultDomestic: action.payload.data.data,
					};
				case LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_INTERNATIONAL:
					return {
						...state,
						dataListAddressSearchDefaultInternational: action.payload.data.data,
					};
				case LOGISTICS_ADD_ADDRESS:
					return {
						...state,
						dataListAddress: [action.payload.data?.data, ...state.dataListAddress],
					};
				case LOGISTICS_UPDATE_ADDRESS:
					return {
						...state,
						dataListAddress: state.dataListAddress.map((address) =>
							address.id === action.payload.data?.data?.id ? { ...address, ...action.payload.data?.data } : address
						),
					};
				case LOGISTICS_DELETE_ADDRESS:
					return {
						...state,
						loadingPage: !state.loadingPage,
					};
				case LOGISTICS_GET_LIST_PACKAGE_SIZE:
					return {
						...state,
						dataListPackagaSize: action.payload.data.data,
						totalListPackageSize: action.payload.data.total,
					};
				case LOGISTICS_ADD_PACKAGE_SIZE:
					return {
						...state,
						// dataListPackagaSize: [...state.dataListPackagaSize, action.payload.data?.data],
						dataListPackagaSize: state.dataListPackagaSize.map((item, key) =>
							key === action.payload.data.index ? { ...item, ...action.payload.data?.data } : item
						),
					};
				case LOGISTICS_GET_DEFINITIONS:
					return {
						...state,
						dataListDefinitions: action.payload.data?.data,
					};
				case LOGISTICS_UPDATE_PACKAGE_SIZE:
					return {
						...state,
						dataListPackagaSize: state.dataListPackagaSize.map((packageSize) =>
							packageSize.id === action.payload.data?.data?.id ? { ...packageSize, ...action.payload.data?.data } : packageSize
						),
					};

				default:
					return { ...state };
			}
		case LOGISTICS_API_RESPONSE_ERROR:
			switch (action.payload.actionType) {
				case LOGISTICS_GET_LIST_HISTORY:
					return {
						...state,
						error: action.payload.error,
					};
				case LOGISTICS_GET_LIST_ADDRESS:
					return {
						...state,
						error: action.payload.error,
					};
				case LOGISTICS_GET_LIST_PACKAGE_SIZE:
					return {
						...state,
						error: action.payload.error,
					};
				case LOGISTICS_GET_DEFINITIONS:
					return {
						...state,
						error: action.payload.error,
					};
				default:
					return { ...state };
			}
		case LOGISTICS_SET_VALUE_FROM_TO_DOMESTIC:
			return {
				...state,
				dataValueFromToDomestic: action.payload,
				isClear: false,
			};
		case LOGISTICS_SET_VALUE_FROM_TO_INTERNATIONAL:
			return {
				...state,
				dataValueFromToInternational: action.payload,
				isClear: false,
			};
		case LOGISTICS_SET_VALUE_DOMESTIC_TO_INTERNATIONAL:
			return {
				...state,
				dataValueDomesticToInternational: action.payload,
				isClear: false,
			};
		case LOGISTICS_DELETE_PACKAGE_SIZE_SUCCESS:
			return {
				...state,
				dataListPackagaSize: state.dataListPackagaSize.filter((data) => data.id.toString() !== action.payload.data.toString()),
			};
		case LOGISTICS_ADD_ROWS_PACKAGE_SIZE_SUCCESS:
			return {
				...state,
				dataListPackagaSize: [...state.dataListPackagaSize, action.payload],
			};
		case LOGISTICS_UPDATE_ROWS_PACKAGE_SIZE_SUCCESS:
			return {
				...state,
				dataListPackagaSize: action.payload,
				// dataListPackagaSize: state.dataListPackagaSize.map((item, key) =>
				// 	key === action.payload.index ? { ...item, [action.payload.name]: action.payload.value } : item
				// ),
			};
		case LOGISTICS_DELETE_ROWS_PACKAGE_SIZE_SUCCESS:
			return {
				...state,
				// dataListPackagaSize: state.dataListPackagaSize.filter((data, index) => data !== action.payload),
				dataListPackagaSize: [
					...state.dataListPackagaSize.slice(0, action.payload),
					...state.dataListPackagaSize.slice(action.payload + 1, state.dataListPackagaSize.length),
				],
			};

		case LOGISTICS_DELETE_PACKAGE_SIZE_FAIL:
			return {
				...state,
				error: action.payload,
			};
		case LOGISTICS_SET_VALUE_PACKAGE_SIZE_DOMESTIC:
			return {
				...state,
				dataValuPackagaSizeDomestic: action.payload,
				isClear: false,
			};
		case LOGISTICS_SET_VALUE_PACKAGE_SIZE_INTERNATIONAL:
			return {
				...state,
				dataValuePackagaSizeInternational: action.payload,
				isClear: false,
			};
		default:
			return { ...state };
	}
};

export default DashboardListLogistics;
