import React, { useRef, useState, useEffect } from "react";
import { Popover, PopoverBody, Button } from "reactstrap";

function PopoverDelete({ target, toggle, isOpen, handleCancel, handleConfirm, innerRef }) {
	const popoverRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (popoverRef.current && !popoverRef.current.contains(event.target)) {
				toggle();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [popoverRef, toggle]);

	return (
		<Popover fade={false} placement="right" isOpen={isOpen} target={target} toggle={toggle} innerRef={popoverRef}>
			<PopoverBody style={{ width: "172px", backgroundColor: "white", boxShadow: "0 5px 15px rgba(30, 32, 37, 0.12)" }}>
				<div>Do you want to delete?</div>
				<div className="mt-1 text-center">
					<Button className="me-3 btn btn-sm" type="button" color="primary" outline onClick={handleCancel}>
						No
					</Button>
					<Button className="btn btn-sm" type="button" color="primary" onClick={handleConfirm}>
						Yes
					</Button>
				</div>
			</PopoverBody>
		</Popover>
	);
}

export default PopoverDelete;
