import React, { useRef, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import InputComponent from "./InputComponent";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";

// import { Document, Page, pdfjs } from "react-pdf";
// import { saveAs } from "file-saver";
// Set worker path for pdfjs
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function ModalViewFile({ dataDocument, isOpen, setIsOpen, toggleCreateShipment }) {
	const dataTest = {
		trackingNumber: "794985080471",
		documentList: [
			{
				url: "https://wwwtest.fedex.com/document/v1/cache/retrieve/SH,b8065fa7219d29f0794985080471_CI_P?isLabel=true&autoPrint=false",
				type: "INVOICE",
			},
			{
				url: "https://wwwtest.fedex.com/document/v1/cache/retrieve/SH,271a0b0efab02871794985080471_SHIPPING_Z?isLabel=true&autoPrint=false&retrievalMode=THERMAL",
				type: "LABEL",
			},
		],
	};
	const [listConfirmChecked, setListConfirmChecked] = useState([]);
	const [listWarningInfo, setListWarningInfo] = useState([]);
	const [pdfData, setPdfData] = useState(""); // Replace with your base64-encoded PDF data

	const [base64Data, setBase64Data] = useState(""); // State to store Base64-encoded PDF data
	const [numPages, setNumPages] = useState(null); // State to store the total number of pages

	const [modalPreviewFile, setModalPreviewFile] = useState(false);
	const [dataPreviewFile, setDataPreviewFile] = useState("");
	const [typeFilePreview, setTypeFilePreview] = useState("pdf");

	const [dataLabel, setDataLabel] = useState("");
	const [dataComercialInvoice, setDataComercialInvoice] = useState("");

	const [dataViewFile, setDataViewFile] = useState("");

	const handleDecodeAndRender = () => {
		const decodedData = atob(base64Data);
		// saveAs(new Blob([decodedData], { type: "application/pdf" }), "decoded_file.pdf");
	};

	const handleDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const toggleConfirm = () => {
		if (isOpen) {
			setIsOpen(false);
			setListConfirmChecked([]);
			setListWarningInfo([]);
		}
		toggleCreateShipment();
	};

	useEffect(() => {
		if (dataDocument?.trackingNumber) {
			const resultObjectLabel = dataDocument?.documentList.find((obj) => obj.type === "LABEL");
			if (resultObjectLabel) {
				setDataLabel(resultObjectLabel?.base64);
			}

			const resultObjectInvoice = dataDocument?.documentList.find((obj) => obj.type === "INVOICE");
			if (resultObjectInvoice) {
				setDataComercialInvoice(resultObjectLabel?.base64);
			}
			setIsOpen(true);
		} else {
			setListConfirmChecked([]);
			setListWarningInfo([]);
		}
	}, [dataDocument]);

	const togglePreviewFile = useCallback(() => {
		if (modalPreviewFile) {
			setModalPreviewFile(false);
			setTimeout(() => {
				setDataPreviewFile("");
				setTypeFilePreview("");
			}, 500);
		} else {
			setModalPreviewFile(true);
		}
	}, [modalPreviewFile]);

	const downloadFile = (base64Data) => {
		// Convert base64 to binary data
		const binaryData = atob(base64Data);

		// Create a Blob object from the binary data
		const blob = new Blob([new Uint8Array(Array.from(binaryData).map((char) => char.charCodeAt(0)))], {
			type: "application/octet-stream",
		});

		// Get the current date and time components
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
		const day = currentDate.getDate().toString().padStart(2, "0");
		const hours = currentDate.getHours().toString().padStart(2, "0");
		const minutes = currentDate.getMinutes().toString().padStart(2, "0");
		const seconds = currentDate.getSeconds().toString().padStart(2, "0");

		// Create a download link and trigger the download
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = `file_${year}${month}${day}${hours}${minutes}${seconds}.pdf`; // Set the filename with the current date and time
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<React.Fragment>
			<Modal id="showModalPreviewFile" isOpen={modalPreviewFile} toggle={togglePreviewFile} centered className={"modal-full-width"}>
				<ModalBody style={{ width: "100%", height: "870px" }}>
					<div className="position-absolute me-1 mt-1" style={{ top: 0, right: 0 }}>
						<Link to="#" onClick={() => togglePreviewFile()}>
							<i className="ri-close-line fs-2"></i>
						</Link>
					</div>
					<div className="h-100 d-flex align-items-center justify-content-center ps-3 pe-3">
						<embed src={`data:application/pdf;base64,${dataViewFile}`} frameBorder="0" width="100%" height="840px" />
					</div>
				</ModalBody>
			</Modal>
			{/* Modal confirm */}
			<Modal id="showModal" isOpen={isOpen} toggle={toggleConfirm} centered className="modal-md">
				<ModalHeader className="bg-soft-info p-3" toggle={toggleConfirm}>
					View File
				</ModalHeader>
				<ModalBody className="py-3 px-5">
					<React.Fragment>
						{dataLabel != "" ? (
							<div className="pb-1">
								Label:{" "}
								<>
									<Link
										className="edit-item-btn text-primary"
										to="#"
										onClick={() => {
											setTypeFilePreview("pdf");
											togglePreviewFile();
											setDataViewFile(dataLabel);
										}}>
										View
									</Link>
									/
									<Link
										className="edit-item-btn  text-primary"
										to="#"
										onClick={() => {
											downloadFile(dataLabel);
										}}>
										Download
									</Link>
								</>
							</div>
						) : (
							<></>
						)}
						{dataComercialInvoice != "" ? (
							<div className="pt-1">
								Comercial Invoice:{" "}
								<>
									<Link
										className="edit-item-btn text-primary"
										to="#"
										onClick={() => {
											setTypeFilePreview("pdf");
											togglePreviewFile();
											setDataViewFile(dataComercialInvoice);
										}}>
										View
									</Link>
									/
									<Link
										className="edit-item-btn  text-primary"
										to="#"
										onClick={() => {
											downloadFile(dataComercialInvoice);
										}}>
										Download
									</Link>
								</>
							</div>
						) : (
							<></>
						)}
					</React.Fragment>
					<div className="d-flex gap-2 justify-content-center mt-4 mb-2">
						<button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={() => toggleConfirm()}>
							Close
						</button>
					</div>
				</ModalBody>
			</Modal>
		</React.Fragment>
	);
}

export default ModalViewFile;
