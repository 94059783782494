import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, FormFeedback, Row, Table, Input } from "reactstrap";

function InputSelectComponent({
	inputValue = "",
	setInputValue = () => {},
	placeholder = "Enter input value",
	listOptions = [],
	onSelectionChange = () => {},
	keyDisplay = "code",
	onKeyDown = () => {},
}) {
	const [isFocus, setIsFocus] = useState(false);
	const [isShow, setIsShow] = useState(false);

	useEffect(() => {
		if (isFocus) {
			setIsShow(true);
		} else {
			setTimeout(() => {
				setIsShow(false);
			}, 200);
		}
	}, [isFocus]);

	return (
		<React.Fragment>
			<div className="position-relative">
				<Input
					type="search"
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					placeholder={placeholder}
					onFocus={() => {
						setIsFocus(true);
					}}
					onBlur={() => {
						setIsFocus(false);
					}}
					onKeyDown={onKeyDown}
				/>
				{isShow && (
					<div className="input-select-list">
						{listOptions?.length > 0 ? (
							listOptions?.map((value, key) => (
								<div
									className="input-select-option"
									key={key}
									onClick={() => {
										onSelectionChange(value ?? {});
									}}>
									{value?.[keyDisplay] ?? ""}
								</div>
							))
						) : (
							<div className="d-flex align-items-center text-muted" style={{ height: "40px", paddingLeft: "8px" }}>
								No Options
							</div>
						)}
					</div>
				)}
			</div>
		</React.Fragment>
	);
}

export default InputSelectComponent;
