import React, { useState, useEffect, memo, useMemo, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import TableCommoditiesRows from "./TableCommoditiesRows";
import { Button, Col, Row, Table, Input, FormFeedback } from "reactstrap";

function ListCommoditiesTable({ valuesCheckPrice, setFieldValue, isDisablePO = false, touched, errors }) {
	const dispatch = useDispatch();

	const [rowsData, setRowsData] = useState([]);
	const [inputValueSearchProductCode, setInputValueSearchProductCode] = useState("");
	const [adding, setAdding] = useState(false);

	const disabledPO = {
		pointerEvents: isDisablePO ? "none" : "unset",
		opacity: isDisablePO ? 0.7 : 1,
	};

	//set data
	useEffect(() => {
		if (valuesCheckPrice?.commoditiesData) {
			const dataCustom = valuesCheckPrice?.commoditiesData.map((item) => ({
				// numberOfPackage: item.name,
				// weight: item.weight.toString(),
				// length: item.sizeL.toString(),
				// width: item.sizeW.toString(),
				// height: item.sizeH.toString(),
				description: item.description ?? "",
				noOfUnits: item.units ?? "",
				weight: item.weight ?? "",
				measures: item.measures ?? "",
				width: item.width ?? "",
				height: item.height ?? "",
				customValue: item.customsValue ?? "",
				unitValue: item.unitValue ?? "",
				CommodityManufacturerId: item.CommodityManufacturerId ?? "",
				inbound: item.inbound ?? "",
				outbound: item.outbound ?? "",
				countryOfManufacture: item.countryOfManufacture ?? "",
			}));
			if (dataCustom.length == 0) {
				setRowsData([
					{
						description: "",
						noOfUnits: "",
						weight: "",
						measures: "",
						width: "",
						height: "",
						customValue: "",
						unitValue: "",
						CommodityManufacturerId: "",
						inbound: "",
						outbound: "",
						countryOfManufacture: "",
					},
				]);
			} else {
				setRowsData(dataCustom);
			}
		} else {
			setRowsData([
				{
					description: "",
					noOfUnits: "",
					weight: "",
					measures: "",
					width: "",
					height: "",
					customValue: "",
					unitValue: "",
					CommodityManufacturerId: "",
					inbound: "",
					outbound: "",
					countryOfManufacture: "",
				},
			]);
		}
	}, [valuesCheckPrice.commoditiesData]);

	const addTableRows = useCallback(() => {
		const rowInput = {
			description: "",
			noOfUnits: "",
			weight: "",
			measures: "",
			width: "",
			height: "",
			customValue: "",
			unitValue: "",
			CommodityManufacturerId: "",
			inbound: "",
			outbound: "",
			countryOfManufacture: "",
		};
		let newRowsData = rowsData?.length > 0 ? rowsData : [];
		setRowsData([...newRowsData, rowInput]);
		setAdding(true);
	}, [rowsData]);

	useEffect(() => {
		if (rowsData[rowsData.length - 1]?.description && rowsData[rowsData.length - 1]?.description !== "") {
			addTableRows();
		}
	}, [rowsData, addTableRows]);

	useEffect(() => {
		if (adding) {
			const element = document.getElementById(`list-product-scroll`);
			if (element) {
				element.scrollTop = element.scrollHeight;
			}
			setAdding(false);
		}
	}, [adding]);

	const deleteTableRows = (index) => {
		setRowsData((prevData) => [...prevData.slice(0, index), ...prevData.slice(index + 1, prevData.length)]);
	};

	//Get Product Code

	useEffect(() => {
		let delayDebounceFn = setTimeout(() => {
			// dispatch(receivingGetListOptionProductCode(inputValueSearchProductCode));
		}, 300);

		return () => clearTimeout(delayDebounceFn);
	}, [dispatch, inputValueSearchProductCode]);

	const handleChangeInputSearch = (value) => {
		setInputValueSearchProductCode(value);
	};

	const handleChange = (index, value, name, productModelId = "") => {
		setRowsData((prevData) => prevData.map((item, key) => (key === index ? { ...item, [name]: value } : item)));
	};

	const handleChangeNote = (index, value, name = "shortDescription") => {
		setRowsData((prevData) => prevData.map((item, key) => (key === index ? { ...item, [name]: value } : item)));
	};

	const handleChangeChecked = (index) => {
		const rowsInput = [...rowsData];
		if (rowsInput[index].status === "active") rowsInput[index].status = "inactive";
		else rowsInput[index].status = "active";
		setRowsData(rowsInput);
	};

	useEffect(() => {
		let newRowsData = [];
		if (rowsData?.length > 0) {
			rowsData.forEach((row) => {
				if (
					typeof row?.description !== "undefined" &&
					row.description !== "" &&
					typeof row?.noOfUnits !== "undefined" &&
					row.noOfUnits !== ""
					// &&
					// typeof row?.CommodityManufacturerId !== "undefined" &&
					// row.CommodityManufacturerId !== "" &&
					// typeof row?.inbound !== "undefined" &&
					// row.inbound !== "" &&
					// typeof row?.outbound !== "undefined" &&
					// row.outbound !== ""
				) {
					const data = {
						description: row?.description,
						countryOfManufacture: row?.countryOfManufacture,
						quantity: row?.noOfUnits,
						quantityUnits: "PCS",
						unitPrice: {
							amount: Number(row?.unitValue),
							currency: "USD",
						},
						customsValue: {
							amount: Number(row?.customValue), // giá trị của mỗi đơn vị hàng để khai báo hải quan
							currency: "USD",
						},
						weight: {
							units: "KG",
							value: Number(row?.weight),
						},
						inbound: row?.inbound,
						outbound: row?.outbound,
						CommodityManufacturerId: row?.CommodityManufacturerId,
					};
					newRowsData.push(data);
				}
			});
		}
		setFieldValue("commodities", newRowsData);
	}, [rowsData, setFieldValue]);

	return (
		<React.Fragment>
			<div id="list-product-scroll" className="pe-3 ps-3 pb-0 pt-0">
				<Row>
					<Table className="table-bordered align-middle table-wrap mb-0" size="sm">
						<thead style={{ ...disabledPO, position: "sticky", top: "-1px", zIndex: "1" }} className="table-light-add-row bg-theme-dark">
							<tr>
								<th scope="col" className="text-center">
									Description of Good
								</th>
								<th scope="col" className="text-center" style={{ width: "70px" }}>
									No. of <br></br>Units
								</th>
								<th scope="col" className="text-center" style={{ width: "70px" }}>
									Net Weight<br></br>(Kg)
								</th>
								<th scope="col" className="text-center" style={{ width: "180px" }}>
									Unit of<br></br>Measures
								</th>
								<th scope="col" className="text-center" style={{ width: "70px" }}>
									Width<br></br>(Cm)
								</th>
								<th scope="col" className="text-center" style={{ width: "70px" }}>
									Height<br></br>(Cm)
								</th>
								<th scope="col" className="text-center" style={{ width: "70px" }}>
									Customs Value<br></br>(USD)
								</th>
								<th scope="col" className="text-center" style={{ width: "70px" }}>
									Unit Value<br></br>(USD)
								</th>
								<th scope="col" className="text-center" style={{ width: "140px" }}>
									Commodity<br></br>Manufacturer ID
								</th>
								<th scope="col" className="text-center" style={{ width: "140px" }}>
									In bound
								</th>
								<th scope="col" className="text-center" style={{ width: "140px" }}>
									Out bound
								</th>
								<th scope="col" className="text-center" style={{ width: "140px" }}>
									Manufacturer Country
								</th>
							</tr>
						</thead>
						<tbody id="tbody-table">
							{rowsData.length > 0 ? (
								rowsData.map((data, index) => {
									return (
										<tr key={index}>
											<TableCommoditiesRows
												data={data}
												index={index}
												handleChange={handleChange}
												disabledPO={disabledPO}
												touched={touched}
												errors={errors}
											/>
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan={12} className="text-center">
										<span>Already package all orders or no packages</span>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</Row>
			</div>
			<Row className="text-center">
				<div style={{ ...disabledPO }}>
					<Button
						className="mt-1 p-1"
						type="button"
						color="success"
						onClick={() => {
							// const element = document.getElementById(`list-product-scroll`);
							// if (element) {
							// 	element.scrollTop = element.scrollHeight + 100;
							// }
							addTableRows();
						}}>
						Add more
					</Button>
				</div>
			</Row>
		</React.Fragment>
	);
}

export default ListCommoditiesTable;
