import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Input, Row } from "reactstrap";
import LabelComponent from "../../../../Components/Hooks/LabelComponent";
import InputComponent from "../../../../Components/Hooks/InputComponent";
import ModalBooked from "../ModelBooked/ModalBooked";
import InputSelectComponent from "../../../../Components/Hooks/InputSelectComponent";
import { getDataFromApi } from "../../../../Components/Hooks/Functions";
import { listLogisticsSetValueDomesticToInternational } from "../../../../store/actions";
import ModalLoading from "../../../../Components/Hooks/ModalLoading";

const FromToInformation = ({ type, setFieldValue, handleBlur, touched, errors, token, currentTimestamp, values }) => {
	const dispatch = useDispatch();
	const { dataValueFromToDomestic, dataListAddressDefault, dataValueDomesticToInternational, dataListAddressSearchDefaultDomestic } = useSelector(
		(state) => state.DashboardListLogistics
	);

	//From
	const [searchAddressFrom, setSearchAddressFrom] = useState("");
	const [streetLineFrom, setStreetLineFrom] = useState("");
	const [cityFrom, setCityFrom] = useState("");
	const [postalCodeFrom, setPostalCodeFrom] = useState("");
	const [stateFrom, setStateFrom] = useState("");
	const [countryFrom, setCountryFrom] = useState("AU");
	const [phoneFrom, setPhoneFrom] = useState("");

	//To
	const [searchAddressTo, setSearchAddressTo] = useState("");
	const [streetLineTo, setStreetLineTo] = useState("");
	const [cityTo, setCityTo] = useState("");
	const [postalCodeTo, setPostalCodeTo] = useState("");
	const [stateTo, setStateTo] = useState("");
	const [countryTo, setCountryTo] = useState("AU");
	const [phoneTo, setPhoneTo] = useState("");
	const [instructionTo, setInstructionTo] = useState("");

	const [isOpen, setIsOpen] = useState(false);
	const [typeBooked, setTypeBooked] = useState("from");
	const [addressActiveFrom, setAddressActiveFrom] = useState({
		active: false,
		id: "",
	});
	const [addressActiveTo, setAddressActiveTo] = useState({
		active: false,
		id: "",
	});
	const [isOpenLoading, setIsOpenLoading] = useState(false);

	// const [token, setToken] = useState("");
	// const [currentTimestamp, setCurrentTimestamp] = useState("");

	const [listAddressFrom, setListAddressFrom] = useState([]);
	const [listAddressTo, setListAddressTo] = useState([]);

	const [valuesCountryFrom, setValuesCountryFrom] = useState("");
	const [valuesCountryTo, setValuesCountryTo] = useState("");

	useEffect(() => {
		if (dataListAddressDefault) {
			//default from
			setStreetLineFrom(dataListAddressDefault?.address ?? "");
			setCityFrom(dataListAddressDefault?.city ?? "");
			setPostalCodeFrom(dataListAddressDefault?.postCode ?? "");
			setCountryFrom(dataListAddressDefault?.country ?? "");
			setStateFrom(dataListAddressDefault?.state ?? "");
			setPhoneFrom(dataListAddressDefault?.phone ?? "");

			setFieldValue("streetLineFrom", dataListAddressDefault?.address ?? "");
			setFieldValue("cityFrom", dataListAddressDefault?.city ?? "");
			setFieldValue("postalCodeFrom", dataListAddressDefault?.postCode ?? "");
			setFieldValue("countryFrom", dataListAddressDefault?.country ?? "");
			setFieldValue("stateFrom", dataListAddressDefault?.state ?? "");

			setFieldValue("personNameFrom", dataListAddressDefault?.name ?? "");
			setFieldValue("companyNameFrom", dataListAddressDefault?.companyName ?? "");
			setFieldValue("emailAddressFrom", dataListAddressDefault?.emailAddress ?? "");
			setFieldValue("phoneFrom", dataListAddressDefault?.phone ?? "");
		}
	}, [dataListAddressDefault]);

	const toggle = () => {
		setIsOpen(false);
	};

	const clearDataFrom = () => {
		setSearchAddressFrom("");
		setStreetLineFrom("");
		setCityFrom("");
		setPostalCodeFrom("");
		setStateFrom("");
		setCountryFrom("");
		setPhoneFrom("");
	};

	const clearDataTo = () => {
		setSearchAddressTo("");
		setStreetLineTo("");
		setCityTo("");
		setPostalCodeTo("");
		setStateTo("");
		setCountryTo("");
		setPhoneTo("");
	};

	useEffect(() => {
		if (dataValueFromToDomestic?.typeBooked != "") {
			if (dataValueFromToDomestic?.typeBooked == "from") {
				setAddressActiveFrom({
					active: true,
					id: dataValueFromToDomestic?.id,
				});
			}
			if (dataValueFromToDomestic?.typeBooked == "to") {
				setAddressActiveTo({
					active: true,
					id: dataValueFromToDomestic?.id,
				});
			}
			hanldeSetDataFromTo(dataValueFromToDomestic, dataValueFromToDomestic?.typeBooked);
		}
	}, [dataValueFromToDomestic]);

	// useEffect(() => {
	// 	setCurrentTimestamp(Date.now());
	// }, []);

	const handleSearchFrom = (placeId) => {
		if (placeId != "") {
			let delayDebounceFn = setTimeout(() => {
				if (token != "" && placeId != "") {
					const url = process.env.REACT_APP_API_URL + "/api/map/google/place-detail";
					const requestBody = {
						// your request body data here
						placeId: placeId,
					};
					getDataFromApi("POST", url, requestBody, token, currentTimestamp)
						.then((data) => {
							hanldeSetDataFromTo(data, "from");
						})
						.catch((error) => {
							console.error("Error fetching data:", error);
						});
				}
			}, 500);

			return () => clearTimeout(delayDebounceFn);
		}
	};

	const handleSearchTo = (placeId) => {
		if (placeId != "") {
			let delayDebounceFn = setTimeout(() => {
				if (token != "" && placeId != "") {
					const url = process.env.REACT_APP_API_URL + "/api/map/google/place-detail";
					const requestBody = {
						// your request body data here
						placeId: placeId,
					};
					getDataFromApi("POST", url, requestBody, token, currentTimestamp)
						.then((data) => {
							hanldeSetDataFromTo(data, "to");
						})
						.catch((error) => {
							console.error("Error fetching data:", error);
						});
				}
			}, 500);

			return () => clearTimeout(delayDebounceFn);
		}
	};

	const hanldeSetDataFromTo = (data, typeAddress) => {
		if (typeAddress == "from") {
			setFieldValue("streetLineFrom", data?.streetFull || data?.streetLine || "");
			setFieldValue("cityFrom", data?.city ?? "");
			setFieldValue("postalCodeFrom", data?.postalCode || data?.postCode || "");
			setFieldValue("stateFrom", data?.state ?? "");
			setFieldValue("countryFrom", data?.country ?? "");
			setFieldValue("phoneFrom", data?.phone ?? "");
			setFieldValue("personNameFrom", data?.name ?? "");
			setFieldValue("companyNameFrom", data?.companyName ?? "");
			setFieldValue("emailAddressFrom", data?.emailAddress ?? "");

			// Use the data as needed
			if (data?.country) {
				setCountryFrom(data?.country);
			} else {
				setCountryFrom("");
			}

			if (data?.state) {
				setStateFrom(data?.state);
			} else {
				setStateFrom("");
			}

			if (data?.streetFull) {
				setStreetLineFrom(data?.streetFull);
			} else if (data?.streetLine) {
				setStreetLineFrom(data?.streetLine);
			} else {
				setStreetLineFrom("");
			}

			if (data?.city) {
				setCityFrom(data?.city);
			} else {
				setCityFrom("");
			}

			if (data?.postalCode) {
				setPostalCodeFrom(data?.postalCode);
			} else if (data?.postCode) {
				setPostalCodeFrom(data?.postCode);
			} else {
				setPostalCodeFrom("");
			}

			if (data?.phone) {
				setPhoneFrom(data?.phone);
			} else {
				setPhoneFrom("");
			}
		}
		if (typeAddress == "to") {
			setFieldValue("streetLineTo", data?.streetFull || data?.streetLine || "");
			setFieldValue("cityTo", data?.city ?? "");
			setFieldValue("postalCodeTo", data?.postalCode || data?.postCode || "");
			setFieldValue("stateTo", data?.state ?? "");
			setFieldValue("countryTo", data?.country ?? "");
			setFieldValue("phoneTo", data?.phone ?? "");
			setFieldValue("personNameTo", data?.name ?? "");
			setFieldValue("companyNameTo", data?.companyName ?? "");
			setFieldValue("emailAddressTo", data?.emailAddress ?? "");

			// Use the data as needed
			if (data?.country) {
				setCountryTo(data?.country);
			} else {
				setCountryTo("");
			}

			if (data?.state) {
				setStateTo(data?.state);
			} else {
				setStateTo("");
			}

			if (data?.streetFull) {
				setStreetLineTo(data?.streetFull);
			} else if (data?.streetLine) {
				setStreetLineTo(data?.streetLine);
			} else {
				setStreetLineTo("");
			}

			if (data?.city) {
				setCityTo(data?.city);
			} else {
				setCityTo("");
			}
			if (data?.postalCode) {
				setPostalCodeTo(data?.postalCode);
			} else if (data?.postCode) {
				setPostalCodeTo(data?.postCode);
			} else {
				setPostalCodeTo("");
			}
			if (data?.phone) {
				setPhoneTo(data?.phone);
			} else {
				setPhoneTo("");
			}
			if (data?.instruction) {
				setInstructionTo(data?.instruction);
			} else {
				setInstructionTo("");
			}
		}
	};

	useEffect(() => {
		let dataRequest = {
			streetLines: [streetLineFrom],
			city: cityFrom,
			postalCode: postalCodeFrom,
			countryCode: countryFrom,
		};
		if (countryTo == "US") {
			dataRequest.stateOrProvinceCode = stateFrom;
		}
		setFieldValue("from", dataRequest);
	}, [streetLineFrom, cityFrom, postalCodeFrom, countryFrom]);

	useEffect(() => {
		let dataRequest = {
			streetLines: [streetLineTo],
			city: cityTo,
			postalCode: postalCodeTo,
			countryCode: countryTo,
		};
		if (countryTo == "US") {
			dataRequest.stateOrProvinceCode = stateTo;
		}
		setFieldValue("to", dataRequest);
	}, [streetLineTo, cityTo, postalCodeTo, countryTo]);

	useEffect(() => {
		let dataRequestFrom = {
			from: {
				address: streetLineFrom,
				postCode: postalCodeFrom,
				city: cityFrom,
				state: stateFrom,
				country: countryFrom,
				phone: phoneFrom,
				instruction: "",
			},
		};

		let dataRequestTo = {
			to: {
				address: streetLineTo,
				postCode: postalCodeTo,
				city: cityTo,
				state: stateTo,
				country: countryTo,
				phone: phoneTo,
				instruction: instructionTo,
			},
		};

		if (addressActiveFrom?.active && addressActiveFrom?.id != "") {
			dataRequestFrom.from.id = addressActiveFrom?.id;
		}
		if (addressActiveTo?.active && addressActiveTo?.id != "") {
			dataRequestTo.to.id = addressActiveTo?.id;
		}
		// Cộng dồn các thuộc tính của dataRequestTo vào dataRequestFrom
		let combinedDataRequest = {
			...dataRequestFrom,
			...dataRequestTo,
		};
		setFieldValue("addressInfo", combinedDataRequest);
	}, [
		addressActiveFrom,
		addressActiveTo,
		streetLineFrom,
		cityFrom,
		postalCodeFrom,
		countryFrom,
		phoneFrom,
		stateFrom,
		streetLineTo,
		cityTo,
		postalCodeTo,
		countryTo,
		stateTo,
		phoneTo,
		instructionTo,
	]);

	useEffect(() => {
		if (values?.addressInfo?.from?.country) setValuesCountryFrom(values?.addressInfo?.from?.country);
		if (values?.addressInfo?.to?.country) setValuesCountryTo(values?.addressInfo?.to?.country);
	}, [values]);

	useEffect(() => {
		if ((values.countryFrom.length > 1 && values.countryFrom != "AU") || (values.countryTo.length > 1 && values.countryTo != "AU")) {
			let dataFromToInternational = {
				from: {
					id: values?.addressInfo?.from?.id,
					address: values?.addressInfo?.from?.address,
					streetFull: values?.addressInfo?.from?.address,
					postCode: values?.addressInfo?.from?.postCode,
					city: values?.addressInfo?.from?.city,
					state: values?.addressInfo?.from?.state,
					country: values?.addressInfo?.from?.country,
					phone: values?.addressInfo?.from?.phone,
					instruction: values?.addressInfo?.from?.instruction,
					typeBooked: "from",
				},
				to: {
					id: values?.addressInfo?.to?.id,
					address: values?.addressInfo?.to?.address,
					streetFull: values?.addressInfo?.to?.address,
					postCode: values?.addressInfo?.to?.postCode,
					city: values?.addressInfo?.to?.city,
					state: values?.addressInfo?.to?.state,
					country: values?.addressInfo?.to?.country,
					phone: values?.addressInfo?.to?.phone,
					instruction: values?.addressInfo?.to?.instruction,
					typeBooked: "to",
				},
				newRowsDataPackageInformation: values.newRowsDataPackageInformation,
			};
			dispatch(listLogisticsSetValueDomesticToInternational(dataFromToInternational));
		}
	}, [valuesCountryFrom, valuesCountryTo]);

	useEffect(() => {
		let delayDebounceFn = setTimeout(() => {
			if (token != "" && searchAddressFrom != "") {
				const url = process.env.REACT_APP_API_URL + "/api/map/google/" + encodeURIComponent(searchAddressFrom);
				getDataFromApi("GET", url, {}, token, currentTimestamp)
					.then((data) => {
						// Use the data as needed
						setListAddressFrom(data);
					})
					.catch((error) => {
						console.error("Error fetching data:", error);
					});
			}
		}, 500);

		return () => clearTimeout(delayDebounceFn);
	}, [token, searchAddressFrom]);

	const hanldeGetAddressData = (data) => {
		let addressData = "";
		if (data?.address) {
			addressData = data?.address;
		}
		if (data?.city) {
			if (addressData == "") {
				addressData = data?.city;
			} else {
				addressData = addressData + ", " + data?.city;
			}
		}
		if (data?.state) {
			if (addressData == "") {
				addressData = data?.state;
			} else {
				addressData = addressData + " " + data?.state;
			}
		}
		if (data?.postCode) {
			if (addressData == "") {
				addressData = data?.postCode;
			} else {
				addressData = addressData + " " + data?.postCode;
			}
		}
		if (data?.country) {
			if (addressData == "") {
				addressData = data?.country;
			} else {
				addressData = addressData + ", " + data?.country;
			}
		}
		return addressData;
	};

	// useEffect(() => {
	// 	if (searchAddressFrom == "" && dataListAddressSearchDefaultDomestic?.length > 0) {
	// 		const dataListAddressCustom = dataListAddressSearchDefaultDomestic.map((item) => ({
	// 			description: hanldeGetAddressData(item),
	// 			place_id: "",
	// 			default: true,
	// 			city: item.city ?? "",
	// 			state: item.state ?? "",
	// 			country: item.country ?? "",
	// 			postalCode: item.postCode ?? "",
	// 			streetFull: item.address ?? "",
	// 			phone: item.phone ?? "",
	// 			name: item.name ?? "",
	// 			companyName: item.companyName ?? "",
	// 			emailAddress: item.emailAddress ?? "",
	// 		}));
	// 		setListAddressFrom(dataListAddressCustom);
	// 	}
	// }, [searchAddressFrom, dataListAddressSearchDefaultDomestic]);

	useEffect(() => {
		if (searchAddressTo == "" && dataListAddressSearchDefaultDomestic?.length > 0) {
			const dataListAddressCustom = dataListAddressSearchDefaultDomestic.map((item) => ({
				description: item.to,
				place_id: "",
				default: true,
			}));
			setListAddressTo(dataListAddressCustom);
		}
	}, [searchAddressTo, dataListAddressSearchDefaultDomestic]);

	const handleValueSearchPlaceId = (description) => {
		setIsOpenLoading(true);
		let delayDebounceFn = setTimeout(() => {
			if (token != "" && description != "") {
				const url = process.env.REACT_APP_API_URL + "/api/map/google/" + encodeURIComponent(description);
				getDataFromApi("GET", url, {}, token, currentTimestamp)
					.then((data) => {
						// Use the data as needed
						if (data.length > 0) {
							const placeId = data[0]?.place_id ?? "";
							if (placeId != "") {
								let delayDebounceFn = setTimeout(() => {
									if (token != "" && placeId != "") {
										const url = process.env.REACT_APP_API_URL + "/api/map/google/place-detail";
										const requestBody = {
											// your request body data here
											placeId: placeId,
										};
										getDataFromApi("POST", url, requestBody, token, currentTimestamp)
											.then((data) => {
												hanldeSetDataFromTo(data, "to");
												setIsOpenLoading(false);
											})
											.catch((error) => {
												console.error("Error fetching data:", error);
												setIsOpenLoading(false);
											});
									} else {
										setIsOpenLoading(false);
									}
								}, 500);

								return () => clearTimeout(delayDebounceFn);
							}
						} else {
							setIsOpenLoading(false);
						}
					})
					.catch((error) => {
						console.error("Error fetching data:", error);
						setIsOpenLoading(false);
					});
			}
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	};

	useEffect(() => {
		let delayDebounceFn = setTimeout(() => {
			if (token != "" && searchAddressTo != "") {
				const url = process.env.REACT_APP_API_URL + "/api/map/google/" + encodeURIComponent(searchAddressTo);
				getDataFromApi("GET", url, {}, token, currentTimestamp)
					.then((data) => {
						// Use the data as needed
						setListAddressTo(data);
					})
					.catch((error) => {
						console.error("Error fetching data:", error);
					});
			}
		}, 500);

		return () => clearTimeout(delayDebounceFn);
	}, [token, searchAddressTo]);

	return (
		<React.Fragment>
			<ModalLoading isOpen={isOpenLoading} />
			<ModalBooked toggle={toggle} isOpen={isOpen} typeBooked={typeBooked} />
			<Row>
				<div className="d-flex align-items-center">
					<div className="mb-0 w-100 pb-1">
						<fieldset className="border rounded-3">
							<legend className="float-none w-auto px-3 text-left fw-bold">From:</legend>
							<Row className="gy-3">
								<Col md={7} lg={7} xl={8} xxl={9}>
									<LabelComponent title="Search Address:" />
									<InputSelectComponent
										inputValue={searchAddressFrom}
										setInputValue={(value) => {
											setSearchAddressFrom(value);
										}}
										listOptions={listAddressFrom}
										keyDisplay="description"
										placeholder={"Search Address"}
										onSelectionChange={(value) => {
											setSearchAddressFrom("");
											if (value?.default) {
												hanldeSetDataFromTo(value, "from");
											} else {
												handleSearchFrom(value?.place_id ?? "");
											}
											setAddressActiveFrom({
												active: false,
												id: "",
											});
										}}
										onKeyDown={(keyEvent) => {
											if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
												// setCurrentPage(1);
												// handleSearch();
											}
										}}
									/>
								</Col>
								<Col md={5} lg={5} xl={4} xxl={3}>
									<Button
										style={{ marginTop: "28px", padding: "3px", paddingRight: "7px", paddingLeft: "7px", marginRight: "10px" }}
										onClick={() => {
											clearDataFrom();
											setAddressActiveFrom({
												active: false,
												id: "",
											});
										}}
										type="button"
										color="warning">
										<i className=" ri-close-circle-line align-bottom fs-20"></i>
									</Button>
									<Button
										style={{ marginTop: "28px" }}
										onClick={() => {
											setIsOpen(true);
											setTypeBooked("from");
										}}
										type="button"
										color="success">
										Recenty Booked
									</Button>
								</Col>
								<Col lg={4}>
									<LabelComponent title="Street line:" requiredField />
									<InputComponent
										requiredField
										type="search"
										name={`streetLineFrom`}
										value={streetLineFrom}
										onChange={(value, name) => {
											setStreetLineFrom(value);
											setFieldValue("streetLineFrom", value);
										}}
										placeholder={"Street line"}
										validate={{
											required: { value: true },
										}}
										onBlur={handleBlur}
										invalid={touched.streetLineFrom && errors.streetLineFrom ? true : false}
										error={touched?.streetLineFrom && errors?.streetLineFrom}
										englishValue={false}
									/>
								</Col>
								<Col lg={2}>
									<LabelComponent title="City:" requiredField />
									<InputComponent
										type="search"
										name={`cityFrom`}
										value={cityFrom}
										onChange={(value, name) => {
											setCityFrom(value);
											setFieldValue("cityFrom", value);
										}}
										placeholder={"City"}
										onBlur={handleBlur}
										invalid={touched.cityFrom && errors.cityFrom ? true : false}
										error={touched?.cityFrom && errors?.cityFrom}
										englishValue={false}
									/>
								</Col>
								<Col lg={2}>
									<LabelComponent title="Postal Code:" requiredField />
									<InputComponent
										type="search"
										name={`postalCodeFrom`}
										value={postalCodeFrom}
										onChange={(value, name) => {
											setPostalCodeFrom(value);
											setFieldValue("postalCodeFrom", value);
										}}
										placeholder={"Postal Code"}
										onBlur={handleBlur}
										invalid={touched.postalCodeFrom && errors.postalCodeFrom ? true : false}
										error={touched?.postalCodeFrom && errors?.postalCodeFrom}
										englishValue={false}
									/>
								</Col>
								<Col lg={4}>
									<LabelComponent title="State:" requiredField />
									<InputComponent
										type="search"
										name={`stateFrom`}
										value={stateFrom}
										onChange={(value, name) => {
											setStateFrom(value);
											setFieldValue("stateFrom", value);
										}}
										placeholder={"State"}
										onBlur={handleBlur}
										invalid={touched.stateFrom && errors.stateFrom ? true : false}
										error={touched?.stateFrom && errors?.stateFrom}
										englishValue={false}
									/>
								</Col>
								<Col lg={4}>
									<LabelComponent title="Country:" requiredField />
									<InputComponent
										type="search"
										name={`countryFrom`}
										value={countryFrom}
										onChange={(value, name) => {
											setCountryFrom(value);
											setFieldValue("countryFrom", value);
										}}
										placeholder={"Country"}
										onBlur={handleBlur}
										invalid={touched.countryFrom && errors.countryFrom ? true : false}
										error={touched?.countryFrom && errors?.countryFrom}
										englishValue
										valueUpperCase
									/>
								</Col>
								<Col lg={4}>
									<LabelComponent title="Phone:" />
									<InputComponent
										type="search"
										name={`phoneFrom`}
										value={phoneFrom}
										onChange={(value, name) => {
											setPhoneFrom(value);
											setFieldValue("phoneFrom", value);
										}}
										placeholder={"Phone"}
										onBlur={handleBlur}
										invalid={touched.phoneFrom && errors.phoneFrom ? true : false}
										error={touched?.phoneFrom && errors?.phoneFrom}
										englishValue={false}
									/>
								</Col>
							</Row>
						</fieldset>
					</div>
				</div>
			</Row>
			<Row>
				<div className="d-flex align-items-center ">
					<div className="mb-0 w-100 pb-1">
						<fieldset className="border rounded-3">
							<legend className="float-none w-auto px-3 text-left fw-bold">To:</legend>
							<Row className="gy-3">
								<Col md={7} lg={7} xl={8} xxl={9}>
									<LabelComponent title="Search Address:" />
									<InputSelectComponent
										inputValue={searchAddressTo}
										setInputValue={(value) => {
											setSearchAddressTo(value);
										}}
										listOptions={listAddressTo}
										keyDisplay="description"
										placeholder={"Search Address"}
										onSelectionChange={(value) => {
											setSearchAddressTo("");
											if (value?.default) {
												hanldeSetDataFromTo(value, "to");
												handleValueSearchPlaceId(value.description);
											} else {
												handleSearchTo(value?.place_id ?? "");
											}
											setAddressActiveTo({
												active: false,
												id: "",
											});
										}}
										onKeyDown={(keyEvent) => {
											if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
												// setCurrentPage(1);
												// handleSearch();
											}
										}}
									/>
								</Col>
								<Col md={5} lg={5} xl={4} xxl={3}>
									<Button
										style={{ marginTop: "28px", padding: "3px", paddingRight: "7px", paddingLeft: "7px", marginRight: "10px" }}
										onClick={() => {
											clearDataTo();
											setAddressActiveTo({
												active: false,
												id: "",
											});
										}}
										type="button"
										color="warning">
										<i className=" ri-close-circle-line align-bottom fs-20"></i>
									</Button>
									<Button
										style={{ marginTop: "28px" }}
										onClick={() => {
											setIsOpen(true);
											setTypeBooked("to");
										}}
										type="button"
										color="success">
										Recenty Booked
									</Button>
								</Col>
								<Col lg={4}>
									<LabelComponent title="Street line:" requiredField />
									<InputComponent
										requiredField
										type="search"
										name={`streetLineTo`}
										value={streetLineTo}
										onChange={(value, name) => {
											setStreetLineTo(value);
											setFieldValue("streetLineTo", value);
										}}
										placeholder={"Street line"}
										onBlur={handleBlur}
										invalid={touched.streetLineTo && errors.streetLineTo ? true : false}
										error={touched?.streetLineTo && errors?.streetLineTo}
										englishValue={false}
									/>
								</Col>
								<Col lg={2}>
									<LabelComponent title="City:" requiredField />
									<InputComponent
										type="search"
										name={`cityTo`}
										value={cityTo}
										onChange={(value, name) => {
											setCityTo(value);
											setFieldValue("cityTo", value);
										}}
										placeholder={"City"}
										onBlur={handleBlur}
										invalid={touched.cityTo && errors.cityTo ? true : false}
										error={touched?.cityTo && errors?.cityTo}
										englishValue={false}
									/>
								</Col>
								<Col lg={2}>
									<LabelComponent title="Postal Code:" requiredField />
									<InputComponent
										type="search"
										name={`postalCodeTo`}
										value={postalCodeTo}
										onChange={(value, name) => {
											setPostalCodeTo(value);
											setFieldValue("postalCodeTo", value);
										}}
										placeholder={"Postal Code"}
										onBlur={handleBlur}
										invalid={touched.postalCodeTo && errors.postalCodeTo ? true : false}
										error={touched?.postalCodeTo && errors?.postalCodeTo}
										englishValue={false}
									/>
								</Col>
								<Col lg={4}>
									<LabelComponent title="State:" requiredField />
									<InputComponent
										type="search"
										name={`stateTo`}
										value={stateTo}
										onChange={(value, name) => {
											setStateTo(value);
											setFieldValue("stateTo", value);
										}}
										placeholder={"State"}
										onBlur={handleBlur}
										invalid={touched.stateTo && errors.stateTo ? true : false}
										error={touched?.stateTo && errors?.stateTo}
										englishValue={false}
									/>
								</Col>
								<Col lg={4}>
									<LabelComponent title="Country:" requiredField />
									<InputComponent
										type="search"
										name={`countryTo`}
										value={countryTo}
										onChange={(value, name) => {
											setCountryTo(value);
											setFieldValue("countryTo", value);
										}}
										placeholder={"Country"}
										onBlur={handleBlur}
										invalid={touched.countryTo && errors.countryTo ? true : false}
										error={touched?.countryTo && errors?.countryTo}
										englishValue
										valueUpperCase
									/>
								</Col>
								<Col lg={4}>
									<LabelComponent title="Phone:" />
									<InputComponent
										type="search"
										name={`phoneTo`}
										value={phoneTo}
										onChange={(value, name) => {
											setPhoneTo(value);
											setFieldValue("phoneTo", value);
										}}
										placeholder={"Phone"}
										onBlur={handleBlur}
										invalid={touched.phoneTo && errors.phoneTo ? true : false}
										error={touched?.phoneTo && errors?.phoneTo}
										englishValue={false}
									/>
								</Col>
							</Row>
						</fieldset>
					</div>
				</div>
			</Row>
			<Row>
				<div className="d-flex align-items-center">
					<div className="mb-0 w-100 pb-1">
						<fieldset className="border rounded-3">
							<legend className="float-none w-auto px-1 text-left fw-bold">Instruction/Attn:</legend>
							<Row className="gy-3 ">
								<Col lg={12}>
									<InputComponent
										type="textarea"
										name={`instructionTo`}
										value={instructionTo}
										onChange={(value, name) => {
											setInstructionTo(value);
										}}
										placeholder={"Instruction/Attn"}
									/>
								</Col>
							</Row>
						</fieldset>
					</div>
				</div>
			</Row>
		</React.Fragment>
	);
};

export default FromToInformation;
