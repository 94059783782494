import React, { Fragment, useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import { optimizeEnter } from "../../utils/utilParagraph";

const customStyles = {
	rows: {
		style: {
			minHeight: "40px", // override the row height
		},
	},
	headCells: {
		style: {
			paddingLeft: "8px", // override the cell padding for head cells
			paddingRight: "8px",
			// justifyContent: "center",
		},
	},
	cells: {
		style: {
			paddingLeft: "8px", // override the cell padding for data cells
			paddingRight: "8px",
		},
	},
};

const defaultPaginationComponentOptions = {
	rowsPerPageText: "Rows per page:",
	rangeSeparatorText: "of",
	noRowsPerPage: false,
	selectAllRowsItem: false,
	selectAllRowsItemText: "All",
};

createTheme(
	"darkCustom",
	{
		divider: {
			default: "#32383e",
		},
		text: {
			primary: "#ced4da", //color dòng lẻ
			secondary: "#ced4da",
			disabled: "#ced4da",
		},
		background: {
			default: "#212429", // background dòng lẻ
		},
		context: {
			background: "#e3f2fd",
			text: "rgba(255, 0, 0, 0.87)",
		},
		// button: {
		//   default: "rgba(0,0,0,.54)",
		//   focus: "rgba(0,0,0,.12)",
		//   hover: "rgba(0,0,0,.12)",
		//   disabled: "rgba(0, 0, 0, .18)",
		// },
		action: {
			button: "rgba(0,0,0,.54)",
			hover: "rgba(0,0,0,.08)",
			disabled: "rgba(0,0,0,.12)",
		},
		selected: {
			default: "#5b636e",
			text: "#ffffff",
		},
		highlightOnHover: {
			default: "#34373a",
			text: "#ced4da",
		},
		striped: {
			default: "#2a2d31", // blackground dòng chẳn
			text: "#ced4da", //color dòng chẳn
		},
	},
	"dark"
);

const DataTableComponent = (props) => {
	const {
		keyField,
		fixedHeader,
		fixedHeaderScrollHeight,
		columns,
		data,
		totalRows,
		responsive,
		striped,
		dense,
		pagination,
		paginationServer,
		setOrder,
		setSortDirection,
		setCurrentPage,
		setPerPage,
		currentPage,
		persistTableHead,
		selectableRowsHighlight,
		highlightOnHover,
		pointerOnHover,
		perPage,
		perPageOptions,
		sortServer,
		selectableRows,
		checkSelectAll,
		setSelectValues,
		conditionalRowStyles,
		onRowClicked,
		clearSelectedRows,
		expandableRows,
		expandableRowsComponent,
		expandableRowsComponentProps,
		expandOnRowClicked,
		expandOnRowDoubleClicked,
		expandableRowsHideExpander,
		conditionalCellStyles,
		paginationComponentOptions,
		onRowDoubleClicked,
		disabled = false,
		onRowMouseEnter = null,
		onRowMouseLeave = null,
		keyShowTooltip = "",
		selectableRowDisabled = false,
	} = props;

	const { layoutModeType } = useSelector((state) => {
		return {
			layoutModeType: state.Layout.layoutModeType,
		};
	});
	let initSortServer = typeof sortServer === "undefined" ? true : sortServer;
	let initResponsive = typeof responsive === "undefined" ? true : responsive;
	let initFixedHeader = typeof fixedHeader === "undefined" ? true : fixedHeader;
	let initStriped = typeof striped === "undefined" ? true : striped;
	let initDense = typeof dense === "undefined" ? true : dense;
	let initPagination = typeof pagination === "undefined" ? true : pagination;

	let initPaginationServer = typeof paginationServer === "undefined" ? true : paginationServer;

	let initPersistTableHead = typeof persistTableHead === "undefined" ? true : persistTableHead;

	let initSelectableRowsHighlight = typeof selectableRowsHighlight === "undefined" ? true : selectableRowsHighlight;

	let initHighlightOnHover = typeof highlightOnHover === "undefined" ? true : highlightOnHover;

	let initPointerOnHover = typeof pointerOnHover === "undefined" ? true : pointerOnHover;

	let initPerPageOptions = typeof perPageOptions === "undefined" ? [50, 100, 500] : perPageOptions;

	let initPerPage = typeof perPage === "undefined" ? 50 : perPage;

	let initSelectableRows = typeof selectableRows === "undefined" ? false : selectableRows;

	let initCheckSelectAll = typeof checkSelectAll === "undefined" ? false : checkSelectAll;

	const [initSelectedRows, setInitSelectedRows] = useState(0);

	let initPaginationComponentOptions =
		typeof paginationComponentOptions === "undefined"
			? defaultPaginationComponentOptions
			: { ...defaultPaginationComponentOptions, ...paginationComponentOptions };

	const handleSort = (column, sortDirection) => {
		setOrder(column.sortField);
		setSortDirection(sortDirection);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setCurrentPage(1);
		setPerPage(newPerPage);
	};

	const customSort = (rows, selector, direction) => {
		return rows.sort((rowA, rowB) => {
			// console.log(rowA, "rowA");
			// console.log("selector(rowA)", selector(rowA));
			// use the selector function to resolve your field names by passing the sort comparitors
			const dataSortA = selector(rowA)?.props?.["data-sort"];
			const dataSortB = selector(rowB)?.props?.["data-sort"];
			let aField = "";
			if (selector(rowA) !== "" && typeof selector(rowA) !== "undefined") {
				aField = selector(rowA).props.value;
				if (typeof aField === "undefined") {
					aField = selector(rowA).props.children;
					if (typeof aField === "undefined") {
						return direction === "desc" ? 0 * -1 : 0;
					} else {
						aField = selector(rowA).props.children.props;
						if (typeof aField === "undefined") {
							aField = selector(rowA).props.children;
						} else {
							aField = selector(rowA).props.children.props.value;
						}
					}
				}
			}
			let bField = "";
			if (selector(rowB) !== "" && typeof selector(rowB) !== "undefined") {
				bField = selector(rowB).props.value;
				if (typeof bField === "undefined") {
					bField = selector(rowB).props.children;
					if (typeof bField === "undefined") {
						return direction === "desc" ? 0 * -1 : 0;
					} else {
						bField = selector(rowB).props.children.props;
						if (typeof bField === "undefined") {
							bField = selector(rowB).props.children;
						} else {
							bField = selector(rowB).props.children.props.value;
						}
					}
				}
			}
			if (!isNaN(parseFloat(aField))) {
				aField = parseFloat(aField);
			}
			if (!isNaN(parseFloat(bField))) {
				bField = parseFloat(bField);
			}
			if (bField === 0 && aField === "") {
				aField = -1;
			}
			if (aField === 0 && bField === "") {
				bField = -1;
			}
			let comparison = 0;

			if (typeof dataSortA !== "undefined") aField = dataSortA;
			if (typeof dataSortA !== "undefined") bField = dataSortB;

			if (aField > bField) {
				comparison = 1;
			} else if (aField < bField) {
				comparison = -1;
			}

			return direction === "desc" ? comparison * -1 : comparison;
		});
	};

	const handleSelectedRows = (rows) => {
		if (typeof setSelectValues !== "undefined") {
			if (typeof keyField !== "undefined") {
				const selectedData = [];
				data.forEach((item) => {
					if (rows.selectedRows.some((e) => typeof e[keyField] != "undefined" && e[keyField] === item[keyField])) {
						selectedData.push(item);
					}
				});
				if (selectedData.length > 0) {
					setInitSelectedRows(selectedData.length);
					setSelectValues(selectedData);
				} else {
					setInitSelectedRows(0);
					setSelectValues(rows.selectedRows);
				}
			} else {
				setSelectValues(rows.selectedRows);
			}
		}
	};
	useEffect(() => {
		setInitSelectedRows(0);
	}, [clearSelectedRows]);

	const ExpandedComponent = ({ data }) => expandableRowsComponent(data);

	const [dataHoverRow, setDataHoverRow] = useState({});
	const [dataSelectRow, setDataSelectRow] = useState({});
	const [modal, setModal] = useState(false);
	const [countClick, setCountClick] = useState(0);

	const initOnRowMouseEnter = (row, event) => {
		setDataHoverRow(row);
	};
	const initOnRowMouseLeave = (row, event) => {
		setDataHoverRow({});
	};

	const handleOnRowClicked = (row, event) => {
		if (onRowClicked) onRowClicked(row, event);
		setCountClick(1);
		setDataSelectRow(row);
	};

	useEffect(() => {
		if (countClick !== 0) {
			const delayDebounceFn = setTimeout(() => {
				if (countClick === 1)
					if (
						keyShowTooltip !== "" &&
						typeof dataSelectRow?.[keyShowTooltip] !== "undefined" &&
						dataSelectRow?.[keyShowTooltip] !== "" &&
						dataSelectRow?.[keyShowTooltip] !== null
					) {
						setModal(true);
					}
				setCountClick(0);
			}, 300);
			return () => clearTimeout(delayDebounceFn);
		}
	}, [countClick]);

	const toggle = () => {
		if (modal) {
			setModal(false);
			setTimeout(() => {
				setDataSelectRow({});
			}, 300);
		} else {
			setModal(true);
		}
	};

	const handleOnRowDoubleClicked = (row, event) => {
		if (onRowDoubleClicked) onRowDoubleClicked(row, event);
		setCountClick(2);
		setDataSelectRow({});
		setModal(false);
	};

	return (
		<React.Fragment>
			<DataTable
				conditionalRowStyles={conditionalRowStyles}
				keyField={keyField}
				fixedHeader={initFixedHeader} //Cố định tiêu đề bảng cho phép bạn cuộn nội dung bảng
				fixedHeaderScrollHeight={fixedHeaderScrollHeight} //Để fixedHeader hoạt động thuộc tính này cho phép bạn đặt chiều cao tĩnh cho TableBody. chiều cao phải là một giá trị cố định
				columns={columns}
				data={data}
				// progressPending={loading}
				// noDataComponent={"No rows"} // Một thành phần tùy chỉnh để hiển thị khi không có bản ghi nào để hiển thị (string or component)
				responsive={initResponsive} // Làm cho bảng có thể cuộn theo chiều ngang trên chiều rộng màn hình nhỏ hơn
				striped={initStriped} //Sọc / dải màu cho các hàng lẻ
				highlightOnHover={initHighlightOnHover} //Nếu các hàng được đánh dấu khi di chuột
				pointerOnHover={initPointerOnHover} // Nếu các hàng hiển thị biểu tượng điểm khi di chuột
				dense={initDense} // Thu gọn chiều cao của hàng. có thể được ghi đè thông qua các hàng chủ đề.denseHeight.
				persistTableHead={initPersistTableHead} //Hiển thị tiêu đề bảng (cột) ngay cả khi processPending là true.
				selectableRowsHighlight={initSelectableRowsHighlight} //Đánh dấu một hàng khi nó được chọn
				customStyles={customStyles}
				pagination={initPagination}
				paginationServer={initPaginationServer}
				paginationTotalRows={totalRows}
				paginationDefaultPage={currentPage}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				theme={layoutModeType === "dark" ? "darkCustom" : "light"}
				sortFunction={initSortServer === false ? customSort : () => {}}
				sortServer={initSortServer} // Bật/Tắt tính năng sắp xếp nội bộ để sử dụng với sắp xếp từ xa / phía máy chủ hoặc khi bạn muốn kiểm soát hành vi sắp xếp theo cách thủ công. đặt các lệnh gọi logic và / hoặc api sắp xếp của bạn trong một trình xử lý onSort.
				onSort={initSortServer ? handleSort : () => {}}
				// title={props.title} // Tiêu đề bảngƒ
				// noTableHead={true} //Ẩn các cột sắp xếp và tiêu đề (TableHead).
				disabled={disabled} // Disable table
				// direction
				// defaultSortFieldId={"id"} //đặt cột được sắp xếp trước và tương ứng với id định nghĩa cột
				// defaultSortAsc={true} //Đặt giá trị này thành false nếu bạn muốn dữ liệu bảng được sắp xếp theo thứ tự DESC.
				// sortIcon // Ghi đè biểu tượng sắp xếp mặc định - biểu tượng phải là phông chữ hoặc biểu tượng svg và nó phải là biểu tượng 'hướng xuống' vì hoạt ảnh sẽ được React Data Table xử lý
				selectableRows={initSelectableRows} //checkbox chọn
				selectableRowsNoSelectAll={initCheckSelectAll} // checkbox chọn tất cả các hàng
				clearSelectedRows={clearSelectedRows}
				onSelectedRowsChange={initSelectableRows ? handleSelectedRows : () => {}}
				// actions //Thêm hành động vào TableHeader
				// subHeader={true} //Hiển thị tiêu đề phụ giữa bảng và tiêu đề bảng
				// subHeaderAlign={"right"} //Căn chỉnh nội dung tiêu đề phụ (left, right, center)
				// subHeaderWrap={true} // Nội dung tiêu đề phụ có nên bao bọc hay không
				paginationPerPage={initPerPage}
				// paginationResetDefaultPage={true}
				paginationRowsPerPageOptions={initPerPageOptions}
				onRowClicked={handleOnRowClicked}
				expandableRows={expandableRows}
				expandableRowsComponent={ExpandedComponent}
				expandableRowsComponentProps={expandableRowsComponentProps}
				expandOnRowClicked={expandOnRowClicked}
				expandOnRowDoubleClicked={expandOnRowDoubleClicked}
				expandableRowsHideExpander={expandableRowsHideExpander}
				conditionalCellStyles={conditionalCellStyles}
				paginationComponentOptions={initPaginationComponentOptions}
				onRowDoubleClicked={handleOnRowDoubleClicked}
				onRowMouseEnter={onRowMouseEnter ? onRowMouseEnter : initOnRowMouseEnter}
				onRowMouseLeave={onRowMouseLeave ? onRowMouseLeave : initOnRowMouseLeave}
				selectableRowDisabled={selectableRowDisabled}
			/>
			{initSelectedRows > 0 && (
				<span
					style={{ marginTop: "-36px", marginLeft: "18px", position: "absolute", fontSize: "13px", backgroundColor: "red" }}
					className="text-white ps-1 pe-1">{`${initSelectedRows} ${initSelectedRows > 1 ? "rows" : "row"} selected`}</span>
			)}
			{keyShowTooltip !== "" &&
				typeof dataHoverRow?.[keyShowTooltip] !== "undefined" &&
				dataHoverRow?.[keyShowTooltip] !== "" &&
				dataHoverRow?.[keyShowTooltip] !== null &&
				!dataSelectRow?.id && (
					<ReactTooltip id="my-tooltip" place="right">
						<span style={{ whiteSpace: "pre-line" }}>
							{dataHoverRow?.[keyShowTooltip]?.trim()?.replace(/\t/g, "")?.replace(/\n +/g, "\n")?.replace(/\n+/g, "\n")}
						</span>
					</ReactTooltip>
				)}

			<Modal fade={false} id="showModalTooltip" isOpen={modal} toggle={toggle} centered style={{ opacity: 0.9, maxWidth: "60%" }}>
				<ModalBody style={{ backgroundColor: "#222", margin: "-2px", paddingRight: "2px" }}>
					<div style={{ maxHeight: "700px", overflow: "auto", color: "#fff", backgroundColor: "#222" }}>
						<span style={{ whiteSpace: "pre-line" }}>
							{/* {dataSelectRow?.[keyShowTooltip]?.replace(/(\r\n|\n|\r){2,}/g, "\n")} */}
							{/* {dataSelectRow?.[keyShowTooltip]?.replace(/\n +/g, "\n")?.replace(/(\r\n|\n|\r){2,}/g, "\n")} */}
							{optimizeEnter(dataSelectRow?.[keyShowTooltip])}
						</span>
					</div>
				</ModalBody>
			</Modal>
		</React.Fragment>
	);
};

export default DataTableComponent;
