import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Input, Row } from "reactstrap";
import InputComponent from "../../../../Components/Hooks/InputComponent";
import DataTableComponent from "../../../../Components/Hooks/DataTableComponent";
import { Link } from "react-router-dom";
import ModalDHL from "./DHL/ModalDHL";
import ModalFedex from "./Fedex/ModalFedex";
import ModalSendle from "./Sendle/ModalSendle";
import ModalTransdirect from "./Transdirect/ModalTransdirect";
import ModalTNT from "./TNT/ModalTNT";
import { dateFormatString1 } from "../../../../Components/Common/FormatTime";

const DataTableCheckPrice = ({ type, data, values, token, currentTimestamp }) => {
	const dispatch = useDispatch();

	const [totalRows, setTotalRows] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [order, setOrder] = useState("priceUSD");
	const [sortDirection, setSortDirection] = useState("desc");
	const [perPage, setPerPage] = useState(50);
	const [dataRow, setDataRow] = useState(null);

	const [timeFrom, setTimeFrom] = useState("");
	const [timeTo, setTimeTo] = useState("");

	const [priceFrom, setPriceFrom] = useState("");
	const [priceTo, setPriceTo] = useState("");

	const [isOpenDHL, setIsOpenDHL] = useState(false);
	const [typeShipment, setTypeShipment] = useState("");

	const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		const filtered = data.filter((item) => {
			const withinTimeRange = (!timeFrom || item.deliverDateRange[0] >= timeFrom) && (!timeTo || item.deliverDateRange[1] <= timeTo);
			const withinPriceRange = (!priceFrom || Number(item.priceUSD) >= Number(priceFrom)) && (!priceTo || Number(item.priceUSD) <= Number(priceTo));
			return withinTimeRange && withinPriceRange;
		});

		// Cập nhật state filteredData với mảng đã lọc
		setFilteredData(filtered);
	}, [data, timeFrom, timeTo, priceFrom, priceTo]);

	useEffect(() => {
		if (data.length > 0) {
			const filtered = data.sort((a, b) => {
				return Number(a.priceUSD) - Number(b.priceUSD);
			});
			setFilteredData(filtered);
		}
	}, [data.length]);

	const toggleDHL = () => {
		setIsOpenDHL(false);
	};

	const [isOpenFedex, setIsOpenFedex] = useState(false);
	const toggleFedex = () => {
		setIsOpenFedex(false);
	};

	const [isOpenSendle, setIsOpenSendle] = useState(false);
	const toggleSendle = () => {
		setIsOpenSendle(false);
	};

	const [isOpenTransdirect, setIsOpenTransdirect] = useState(false);
	const toggleTransdirect = () => {
		setIsOpenTransdirect(false);
	};

	const [isOpenTNT, setIsOpenTNT] = useState(false);
	const toggleTNT = () => {
		setIsOpenTNT(false);
	};

	const columns = useMemo(
		() => [
			{
				name: <span>Courier Name</span>,
				sortable: true,
				selector: (row) => {
					return <span>{row?.courierName}</span>;
				},
				wrap: true,
				grow: 1,
				sortField: "courierName",
			},
			{
				name: <span>Service Name</span>,
				selector: (row) => {
					return <span>{row?.serviceName}</span>;
				},
				sortable: true,
				wrap: true,
				grow: 2,
				sortField: "serviceName",
				// width: "160px",
			},
			{
				name: <span>Time</span>,
				sortable: true,
				selector: (row) => {
					const deliverDateRange = convertTimeShip(row?.deliverDateRange);
					return <span>{deliverDateRange}</span>;
				},
				wrap: true,
				grow: 1,
			},
			{
				name: <span>Price Return</span>,
				sortable: true,
				selector: (row) => {
					const priceReturn = row?.price ? parseFloat(row?.price).toFixed(2) + " " + (row?.currency ? row?.currency : "") : "";
					return <span>{priceReturn}</span>;
				},
				wrap: true,
				grow: 1,
			},
			{
				name: <span>Price (USD)</span>,
				sortable: true,
				selector: (row) => {
					const priceUSD = row?.priceUSD ? parseFloat(row?.priceUSD).toFixed(2) : "";
					return <span>{priceUSD}</span>;
				},
				wrap: true,
				grow: 1,
			},
			{
				name: <span></span>,
				sortable: false,
				selector: (row) => {
					return (
						<ul className="list-inline hstack gap-2 mb-0">
							<li className="list-inline-item" title="Delete">
								{["DHL", "FEDEX", "SENDLE", "TRANSDIRECT", "TNT"].includes(row?.courierName) && (
									<Link
										className={"text-primary"}
										title={"Book"}
										onClick={() => {
											setTypeShipment(row?.courierName);
											setDataRow(row);
											switch (row?.courierName) {
												case "DHL":
													setIsOpenDHL(true);
													break;
												case "FEDEX":
													setIsOpenFedex(true);
													break;
												case "SENDLE":
													setIsOpenSendle(true);
													break;
												case "TRANSDIRECT":
													setIsOpenTransdirect(true);
													break;
												case "TNT":
													setIsOpenTNT(true);
													break;
												default:
													break;
											}
										}}
										to="#">
										Book
									</Link>
								)}
							</li>
						</ul>
					);
				},
				wrap: true,
				width: "110px",
			},
		],
		[]
	);

	const convertTimeShip = (data) => {
		const deliverDateRange = data ?? [];
		if (deliverDateRange.length > 0) {
			if (deliverDateRange[0] == deliverDateRange[1]) {
				return deliverDateRange[0] + " days";
			} else {
				const time = deliverDateRange[0] + "-" + deliverDateRange[1] + " days";
				return time;
			}
		}
		return "";
	};

	const onRowClicked = (row, event) => {
		// setInfo(row);
	};

	const conditionalRowStyles = [
		{
			when: (row) => typeof row.id !== "undefined",
			classNames: ["selectRowData"],
			style: {
				"&:hover": {
					cursor: "pointer",
				},
			},
		},
	];

	const onRowDoubleClicked = (row, event) => {
		// if (arrayPageRoles.includes("CONTACTS_EDIT")) handleEditClick(row);
	};

	return (
		<Row>
			<ModalDHL
				isOpen={isOpenDHL}
				toggle={toggleDHL}
				type={type}
				valuesCheckPrice={values}
				dataRow={dataRow}
				token={token}
				currentTimestamp={currentTimestamp}
				typeShipment={typeShipment}
				convertTimeShip={convertTimeShip}
			/>
			<ModalFedex
				isOpen={isOpenFedex}
				toggle={toggleFedex}
				type={type}
				valuesCheckPrice={values}
				dataRow={dataRow}
				token={token}
				currentTimestamp={currentTimestamp}
				typeShipment={typeShipment}
				convertTimeShip={convertTimeShip}
			/>
			<ModalSendle
				isOpen={isOpenSendle}
				toggle={toggleSendle}
				type={type}
				valuesCheckPrice={values}
				dataRow={dataRow}
				token={token}
				currentTimestamp={currentTimestamp}
				typeShipment={typeShipment}
				convertTimeShip={convertTimeShip}
			/>
			<ModalTransdirect
				isOpen={isOpenTransdirect}
				toggle={toggleTransdirect}
				type={type}
				valuesCheckPrice={values}
				dataRow={dataRow}
				token={token}
				currentTimestamp={currentTimestamp}
				typeShipment={typeShipment}
				convertTimeShip={convertTimeShip}
			/>
			<ModalTNT
				isOpen={isOpenTNT}
				toggle={toggleTNT}
				type={type}
				valuesCheckPrice={values}
				dataRow={dataRow}
				token={token}
				currentTimestamp={currentTimestamp}
				typeShipment={typeShipment}
				convertTimeShip={convertTimeShip}
			/>
			<Col lg={12}>
				<div className="pt-2 pb-2">
					<Row className="d-flex align-items-center g-3">
						<Col md={6} lg={6} xl={4} xxl={4}>
							<div className="mt-3 d-flex align-items-center gap-3">
								<div className="fw-bold">Time:</div>
								<div>
									<InputComponent
										style={{ width: "70px" }}
										size="sm"
										placeholder=""
										value={timeFrom}
										onChange={(value) => {
											setTimeFrom(value);
										}}
										onKeyDown={(e) => {
											if (e.key === ".") e.preventDefault();
										}}
									/>
								</div>

								<div>to</div>
								<div>
									<InputComponent
										style={{ width: "70px" }}
										size="sm"
										placeholder=""
										value={timeTo}
										onChange={(value) => {
											setTimeTo(value);
										}}
										onKeyDown={(e) => {
											if (e.key === ".") e.preventDefault();
										}}
									/>
								</div>

								<div>days</div>
							</div>
						</Col>
						<Col md={6} lg={6} xl={4} xxl={4}>
							<div className="mt-3 d-flex align-items-center gap-3">
								<div className="fw-bold">Price:</div>
								<div>
									<InputComponent
										style={{ width: "70px" }}
										size="sm"
										placeholder=""
										value={priceFrom}
										onChange={(value) => {
											setPriceFrom(value);
										}}
										onKeyDown={(e) => {
											if (e.key === ".") e.preventDefault();
										}}
									/>
								</div>
								<div>to</div>
								<div>
									<InputComponent
										style={{ width: "70px" }}
										size="sm"
										placeholder=""
										value={priceTo}
										onChange={(value) => {
											setPriceTo(value);
										}}
										onKeyDown={(e) => {
											if (e.key === ".") e.preventDefault();
										}}
									/>
								</div>
								<div>USD</div>
							</div>
						</Col>
					</Row>
				</div>
				<div>
					<DataTableComponent
						keyField={"id"}
						fixedHeaderScrollHeight={"calc(58vh)"}
						columns={columns}
						data={filteredData}
						// loading={loading}
						// setLoading={setLoading}
						dense={false} // Thu gọn chiều cao của hàng. có thể được ghi đè thông qua các hàng chủ đề.denseHeight.
						totalRows={totalRows}
						currentPage={currentPage}
						setOrder={setOrder}
						setSortDirection={setSortDirection}
						setCurrentPage={setCurrentPage}
						setPerPage={setPerPage}
						onRowClicked={onRowClicked}
						// selectableRows={true}
						// setSelectValues={setSelectValues}
						sortServer={false}
						conditionalRowStyles={conditionalRowStyles}
						onRowDoubleClicked={onRowDoubleClicked}
					/>
				</div>
			</Col>
		</Row>
	);
};

export default DataTableCheckPrice;
