import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputComponent from "../../../../../../Components/Hooks/InputComponent";
import SelectComponent from "../../../../../../Components/Hooks/SelectComponent";

function TableCommoditiesRows({
	data,
	index,
	handleChange,
	disabledPO = {
		pointerEvents: "unset",
		opacity: 1,
	},
	touched,
	errors,
}) {
	const {
		description,
		noOfUnits,
		weight,
		measures,
		width,
		height,
		customValue,
		unitValue,
		CommodityManufacturerId,
		inbound,
		outbound,
		countryOfManufacture,
	} = data;

	const { dataListDefinitions } = useSelector((state) => state.DashboardListLogistics);

	const dispatch = useDispatch();

	const [flagDisable, setFlagDisable] = useState(false);
	const [optionMeasures, setOptionMeasures] = useState(null);
	const [inputValueSearchMeasures, setInputValueSearchMeasures] = useState("");
	const [listMeasures, setListMeasures] = useState([]);

	const [optionCountry, setOptionCountry] = useState(null);
	const [inputValueSearchCountry, setInputValueSearchCountry] = useState("");
	const [listCountry, setListCountry] = useState([]);

	const formikRef = useRef(null);

	useEffect(() => {
		const dataDefinitions = Object.values(dataListDefinitions?.UNIT_OF_MEASURE ?? {})?.reduce((prevResult, currItem) => {
			prevResult.push({
				id: currItem.value,
				code: currItem.description,
			});
			return prevResult;
		}, []);
		setListMeasures(dataDefinitions);

		const dataDefinitionsCountry = Object.values(dataListDefinitions?.LIST_COUNTRY_CODE ?? {})?.reduce((prevResult, currItem) => {
			prevResult.push({
				id: currItem.country_code,
				code: currItem.country_name,
			});
			return prevResult;
		}, []);
		setListCountry(dataDefinitionsCountry);
	}, [dataListDefinitions]);

	const handleChangeMeasures = (option) => {
		setOptionMeasures(option);
		handleChange(index, option?.id ? option.id : "", "measures");
		setInputValueSearchMeasures("");
	};

	const handleChangeCountry = (option) => {
		setOptionCountry(option);
		handleChange(index, option?.id ? option.id : "", "countryOfManufacture");
		setInputValueSearchCountry("");
	};

	useEffect(() => {
		if (measures != "" && listMeasures.length > 0) {
			setOptionMeasures(listMeasures.find((item) => item.id == measures));
		}
	}, [measures, listMeasures]);

	return (
		<React.Fragment>
			<td>
				<div style={disabledPO}>
					<InputComponent
						type="text"
						value={description || ""}
						onChange={(value) => handleChange(index, value, "description")}
						name="description"
						className="form-control  fst-italic"
						style={{ fontSize: 11 }}
						placeholder="Description of Good"
						disabled={flagDisable}
					/>
				</div>
			</td>
			<td className={`text-center `}>
				<InputComponent
					type="text"
					value={noOfUnits || ""}
					onChange={(value) => handleChange(index, value, "noOfUnits")}
					name="noOfUnits"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="No. of Units"
					disabled={flagDisable}
				/>
			</td>
			<td className={`text-center `}>
				<InputComponent
					type="text"
					value={weight || ""}
					onChange={(value) => handleChange(index, value, "weight")}
					name="weight"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Net Weight"
					disabled={flagDisable}
					invalid={errors?.commodities && errors?.commodities[index]?.weight ? true : false}
					error={""}
				/>
			</td>
			<td>
				{/* <InputComponent
					type="text"
					value={measures || ""}
					onChange={(value) => handleChange(index, value, "measures")}
					name="measures"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Unit of Measures"
					disabled={flagDisable}
				/> */}
				<SelectComponent
					isSearchServer={false}
					options={listMeasures}
					name="measures"
					onChange={(option) => {
						handleChangeMeasures(option);
					}}
					value={optionMeasures?.id ? optionMeasures : []}
					placeholder="Select Measures"
					fieldValue="id"
					setInputValue={setInputValueSearchMeasures}
					inputValue={inputValueSearchMeasures}
					display="code"
				/>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={width || ""}
					onChange={(value) => handleChange(index, value, "width")}
					name="width"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Width"
					disabled={flagDisable}
				/>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={height || ""}
					onChange={(value) => handleChange(index, value, "height")}
					name="height"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Height"
					disabled={flagDisable}
				/>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={customValue || ""}
					onChange={(value) => handleChange(index, value, "customValue")}
					name="customValue"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Customs Value"
					disabled={flagDisable}
					invalid={errors?.commodities && errors?.commodities[index]?.customValue ? true : false}
					error={""}
				/>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={unitValue || ""}
					onChange={(value) => handleChange(index, value, "unitValue")}
					name="unitValue"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Unit Value"
					disabled={flagDisable}
					invalid={errors?.commodities && errors?.commodities[index]?.unitValue ? true : false}
					error={""}
				/>
			</td>
			<td className="">
				<InputComponent
					type="text"
					value={CommodityManufacturerId || ""}
					onChange={(value) => handleChange(index, value, "CommodityManufacturerId")}
					name="CommodityManufacturerId"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Commodity Manufacturer ID"
					disabled={flagDisable}
					invalid={errors?.commodities && errors?.commodities[index]?.CommodityManufacturerId ? true : false}
					error={""}
				/>
			</td>
			<td className="">
				<InputComponent
					type="text"
					value={inbound || ""}
					onChange={(value) => handleChange(index, value, "inbound")}
					name="inbound"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="In bound"
					disabled={flagDisable}
					invalid={errors?.commodities && errors?.commodities[index]?.inbound ? true : false}
					error={errors?.commodities && errors?.commodities[index]?.inbound === "length" ? "2 to 18 characters" : ""}
				/>
			</td>
			<td className="">
				<InputComponent
					type="text"
					value={outbound || ""}
					onChange={(value) => handleChange(index, value, "outbound")}
					name="outbound"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Out bound"
					disabled={flagDisable}
					invalid={errors?.commodities && errors?.commodities[index]?.outbound ? true : false}
					error={errors?.commodities && errors?.commodities[index]?.outbound === "length" ? "2 to 18 characters" : ""}
				/>
			</td>
			<td className="">
				{/* <InputComponent
					type="text"
					value={countryOfManufacture || ""}
					onChange={(value) => handleChange(index, value, "countryOfManufacture")}
					name="countryOfManufacture"
					className="form-control  fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Manufacturer Country"
					disabled={flagDisable}
				/> */}
				<SelectComponent
					isSearchServer={false}
					options={listCountry}
					name="countryOfManufacture"
					onChange={(option) => {
						handleChangeCountry(option);
					}}
					value={optionCountry?.id ? optionCountry : []}
					placeholder="Select Country"
					fieldValue="id"
					setInputValue={setInputValueSearchCountry}
					inputValue={inputValueSearchCountry}
					display="code"
					requiredField
					touched={errors?.commodities && errors?.commodities[index]?.countryOfManufacture ? true : false}
					error={true}
				/>
			</td>
		</React.Fragment>
	);
}

export default TableCommoditiesRows;
