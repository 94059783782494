import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik } from "formik";
import { Button, Col, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import FormSubmit from "../../../../../Components/Common/FormSubmit";
import InputComponent from "../../../../../Components/Hooks/InputComponent";
import SelectComponent from "../../../../../Components/Hooks/SelectComponent";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import LabelComponent from "../../../../../Components/Hooks/LabelComponent";
import DHL_logo from "../../../../../assets/images/logo/DHL_logo.png";
import { getDataFromApi } from "../../../../../Components/Hooks/Functions";
import ModalLoading from "../../../../../Components/Hooks/ModalLoading";
import ModalViewFile from "../../../../../Components/Hooks/ModalViewFile";
import { toast } from "react-toastify";
import { phoneRegExp, regexEmail } from "../../../../../Components/Common/Constant";

const ModalDHL = ({ isOpen = false, toggle = () => {}, type, valuesCheckPrice, dataRow, token, currentTimestamp, typeShipment, convertTimeShip }) => {
	const dispatch = useDispatch();
	const formikRefViewFile = useRef();
	const fileInputRef = useRef(null);

	const [paperlessTrade, setPaperlessTrade] = useState(false);
	const [paperless, setPaperless] = useState([]);
	const [returnLabel, setReturnLabel] = useState(false);

	//Ship
	const [addressShip, setAddressShip] = useState("");
	const [personNameShip, setPersonNameShip] = useState("");
	const [companyNameShip, setCompanyNameShip] = useState("");
	const [phoneNumberShip, setPhoneNumberShip] = useState("");
	const [emailShip, setEmailShip] = useState("");

	//Recipient
	const [addressRecip, setAddressRecip] = useState("");
	const [personNameRecip, setPersonNameRecip] = useState("");
	const [companyNameRecip, setCompanyNameRecip] = useState("");
	const [phoneNumberRecip, setPhoneNumberRecip] = useState("");
	const [emailRecip, setEmailRecip] = useState("");

	const [listConterm, setListCoterm] = useState([]);
	const [listPaperless, setListPaperless] = useState([]);
	const [incoterm, setIncoterm] = useState([]);

	const [productName, setProductName] = useState("");
	const [price, setPrice] = useState("");
	const [time, setTime] = useState("");

	const [isOpenModel, setIsOpenModel] = useState(false);
	const [isOpenLoading, setIsOpenLoading] = useState(false);
	const [dataDocument, setDataDocument] = useState(null);
	const [fileData, setFileData] = useState("");
	const [fileType, setFileType] = useState("");

	const hanldeGetAddressData = (field = "from") => {
		let addressData = "";
		if (valuesCheckPrice?.addressInfo[field]?.address) {
			addressData = valuesCheckPrice?.addressInfo[field]?.address;
		}
		if (valuesCheckPrice?.addressInfo[field]?.city) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.city;
			} else {
				addressData = addressData + ", " + valuesCheckPrice?.addressInfo[field]?.city;
			}
		}
		if (valuesCheckPrice?.addressInfo[field]?.state) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.state;
			} else {
				addressData = addressData + " " + valuesCheckPrice?.addressInfo[field]?.state;
			}
		}
		if (valuesCheckPrice?.addressInfo[field]?.postCode) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.postCode;
			} else {
				addressData = addressData + " " + valuesCheckPrice?.addressInfo[field]?.postCode;
			}
		}
		if (valuesCheckPrice?.addressInfo[field]?.country) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.country;
			} else {
				addressData = addressData + ", " + valuesCheckPrice?.addressInfo[field]?.country;
			}
		}
		return addressData;
	};

	useEffect(() => {
		if (isOpen) {
			// setPersonNameShip("harry potter 2");
			// setCompanyNameShip("apac tech 2");
			// setPhoneNumberShip("+6128066886");

			// setPersonNameRecip("harry potter");
			// setCompanyNameRecip("apac tech");
			// setPhoneNumberRecip("+9138584708");

			const addressDataShip = hanldeGetAddressData("from");
			setAddressShip(addressDataShip);
			setPhoneNumberShip(valuesCheckPrice?.phoneFrom);
			setPersonNameShip(valuesCheckPrice?.personNameFrom);
			setCompanyNameShip(valuesCheckPrice?.companyNameFrom);
			setEmailShip(valuesCheckPrice?.emailAddressFrom);

			const addressDataRecip = hanldeGetAddressData("to");
			setAddressRecip(addressDataRecip);
			setPhoneNumberRecip(valuesCheckPrice?.addressInfo?.to?.phone);
			setPersonNameRecip(valuesCheckPrice?.personNameTo);
			setCompanyNameRecip(valuesCheckPrice?.companyNameTo);
			setEmailRecip(valuesCheckPrice?.emailAddressTo);

			if (formikRef?.current) {
				formikRef?.current?.setFieldValue("companyNameShip", valuesCheckPrice?.companyNameFrom);
			}
		}
	}, [valuesCheckPrice?.addressInfo, isOpen]);

	useEffect(() => {
		if (isOpen && dataRow?.historyId) {
			setProductName(dataRow?.serviceName);
			setPrice(dataRow?.price);
			setTime(convertTimeShip(dataRow?.deliverDateRange));
		}
	}, [isOpen]);

	useEffect(() => {
		const methods = ["EXW", "FCA", "CPT", "CIP", "DPU", "DAP", "DDP", "FAS", "FOB", "CFR", "CIF", "DAF", "DAT", "DDU", "DEQ", "DES"];
		const methodObjects = methods.map((method, index) => ({
			id: index + 1,
			name: method,
		}));
		setListCoterm(methodObjects);
		const defaultIncoterm = methodObjects.find((item) => item.name == "FCA");
		setIncoterm(defaultIncoterm);

		const methodsPaperless = ["INV", "PNV", "COO", "CIN", "DCL", "AWB", "NAF"];
		const methodObjectsPaperless = methodsPaperless.map((method, index) => ({
			id: index + 1,
			name: method,
		}));
		setListPaperless(methodObjectsPaperless);
	}, []);

	const formikRef = useRef(null);

	const validate = (values) => {
		let errors = {};

		if (values?.personNameShip === "") errors.personNameShip = "Please enter person name";
		if (values?.companyNameShip === "") errors.companyNameShip = "Please enter company name";
		if (values?.phoneNumberShip === "") errors.phoneNumberShip = "Please enter phone number";
		if (!regexEmail.test(values?.emailShip) && values?.emailShip !== "") errors.emailShip = "Invalid Email";
		if (!phoneRegExp.test(values?.phoneNumberShip) && values?.phoneNumberShip !== "") errors.phoneNumberShip = "Invalid Phone Number";

		if (values?.personNameRecip === "") errors.personNameRecip = "Please enter person name";
		if (values?.companyNameRecip === "") errors.companyNameRecip = "Please enter company name";
		if (values?.phoneNumberRecip === "") errors.phoneNumberRecip = "Please enter phone number";
		if (!regexEmail.test(values?.emailRecip) && values?.emailRecip !== "") errors.emailRecip = "Invalid Email";
		if (!phoneRegExp.test(values?.phoneNumberRecip) && values?.phoneNumberRecip !== "") errors.phoneNumberRecip = "Invalid Phone Number";

		if (!incoterm?.name) errors.incoterm = "Please select incoterm";

		if (values.paperlessTrade) {
			if (!values.paperless) errors.paperless = "Please select paperless";
			if (!values.fileData) errors.fileData = "Please select file";
		}

		return errors;
	};

	const submitForm = async (values) => {
		const documentImages = [];
		if (paperlessTrade) {
			documentImages.push({
				typeCode: paperless, // loại tài liệu. có thể là một trong các giá trị sau: ['INV', 'PNV', 'COO', 'CIN', 'DCL', 'AWB', 'NAF']
				imageFormat: fileType, // định dạng file user tải lên
				content: fileData, //base64 string của file
			});
		}
		const requestData = {
			courier: typeShipment,
			historyId: dataRow?.historyId,
			commodities: [],
			requestedPackageLineItems: valuesCheckPrice.requestedPackageLineItems ?? [],
			shipper: {
				address: valuesCheckPrice.from,
				contact: {
					personName: personNameShip,
					phoneNumber: phoneNumberShip,
					companyName: companyNameShip,
					emailAddress: emailShip,
				},
			},
			recipient: {
				address: valuesCheckPrice.to,
				contact: {
					personName: personNameRecip,
					phoneNumber: phoneNumberRecip,
					companyName: companyNameRecip,
					emailAddress: emailRecip,
				},
			},
			domesticInformation: valuesCheckPrice?.domesticInformation ?? "",

			inforCreateDHL: {
				incoterm: incoterm?.name, // required. có thể bao gồm ["EXW","FCA","CPT","CIP","DPU","DAP","DDP","FAS","FOB","CFR","CIF","DAF","DAT","DDU","DEQ","DES"]
				invoiceNumber: "", // required. mã hóa đon bán hàng
				invoiceDate: "", // required. ngày lập hóa đơn
				commonityMID: [], // not required. mã MID của product
				documentImages: documentImages,
				valueAddedServicesWY: paperlessTrade, // not required. cho biết có dùng paperless shipment không
				valueAddedServicesReturn: returnLabel, // not required. cho biết có phải returne shipment không
			},
			addressInfo: valuesCheckPrice?.addressInfo,
		};
		setIsOpenLoading(true);
		const response = await handleCreateShipment(requestData);
		if (response?.error) {
			toast.error(response?.error && response?.error?.message);
		} else {
			setDataDocument(response);
		}
		setIsOpenLoading(false);
	};

	const initialValues = {
		addressShip: "",
		personNameShip: valuesCheckPrice?.personNameFrom ?? "",
		companyNameShip: valuesCheckPrice?.companyNameFrom ?? "",
		phoneNumberShip: valuesCheckPrice?.phoneFrom ?? "",
		emailShip: valuesCheckPrice?.emailAddressFrom ?? "",

		addressRecip: "",
		personNameRecip: "",
		companyNameRecip: "",
		phoneNumberRecip: "",
		emailRecip: "",
		commercialInvoice: "",

		invoiceNumber: "",
		invoiceDate: "",
		commonityMID: [],
		commodityCode: [],

		paperlessTrade: false,
		paperless: null,
		fileData: null,
		returnLabel: false,

		incoterm: listConterm.find((item) => item.name == "FCA"),
	};

	const handleReset = () => {
		toggle();

		setAddressShip("");
		setPersonNameShip(valuesCheckPrice?.personNameFrom ?? "");
		setCompanyNameShip(valuesCheckPrice?.companyNameFrom ?? "");
		setPhoneNumberShip(valuesCheckPrice?.phoneFrom ?? "");
		setEmailShip(valuesCheckPrice?.emailAddressFrom ?? "");
		setAddressRecip("");
		setPersonNameRecip("");
		setCompanyNameRecip("");
		setPhoneNumberRecip("");
		setEmailRecip("");

		const defaultIncoterm = listConterm.find((item) => item.name == "FCA");
		setIncoterm(defaultIncoterm);

		setPaperlessTrade(false);
		setPaperless([]);
		setReturnLabel(false);

		handleClearFile();
	};

	const handleFileChange = (event, setFieldValue) => {
		const file = event.target.files[0];
		if (file) {
			setFileType(file.type);

			const reader = new FileReader();

			reader.onload = function (e) {
				const base64String = e.target.result.split(",")[1];
				setFileData(base64String);
				setFieldValue("fileData", base64String);
			};

			reader.readAsDataURL(file);
		} else setFieldValue("fileData", null);
	};

	const handleCreateShipment = async (requestBody) => {
		if (token != "") {
			const url = process.env.REACT_APP_API_URL + "/api/shipment/create-shipment";

			return await getDataFromApi("POST", url, requestBody, token, currentTimestamp)
				.then((data) => {
					return data;
				})
				.catch((error) => {
					return [];
					// console.error("Error fetching data:", error);
				});
		}
	};

	const handleClearFile = (setFieldValue) => {
		// Clear the content of the file input
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
			setFileData("");
			setFileType("");
			setFieldValue("fileData", null);
		}
	};
	return (
		<>
			<ModalLoading isOpen={isOpenLoading} />
			<ModalViewFile
				dataDocument={dataDocument}
				setIsOpen={setIsOpenModel}
				isOpen={isOpenModel}
				formikRef={formikRefViewFile}
				toggleCreateShipment={() => {
					handleReset();
				}}
			/>
			<Modal
				isOpen={isOpen}
				toggle={() => {
					handleReset();
				}}
				centered
				className="modal-lg-2">
				<Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={submitForm} innerRef={formikRef}>
					{(formik) => {
						const {
							values,
							handleChange,
							setFieldValue,
							handleSubmit,
							errors,
							touched,
							handleBlur,
							isValid,
							dirty,
							validateForm,
							setFieldError,
							setFieldTouched,
						} = formik;
						return (
							<FormSubmit onSubmit={handleSubmit}>
								<ModalHeader
									className="bg-soft-info p-3"
									toggle={() => {
										handleReset();
									}}>
									DHL Label
								</ModalHeader>
								<ModalBody className="p-0">
									<Row className="ps-3 pb-1 d-flex align-items-center">
										<img
											src={DHL_logo}
											alt=""
											className="acitivity-avatar"
											style={{
												marginLeft: "13px",
												marginTop: "10px",
												width: "250px",
												height: "120px",
												borderRadius: "10%",
											}}
										/>
										<div className="p-2 w-auto px-1 text-left  ">
											<div className="d-flex align-items-center">
												<legend className="fw-bold  float-none" style={{ fontSize: "15px" }}>
													Product Name: {productName}
												</legend>
											</div>
											<div className="d-flex align-items-center">
												<legend className="fw-bold  float-none" style={{ fontSize: "15px" }}>
													Price: {price} USD
												</legend>
											</div>
											<div className="d-flex align-items-center">
												<legend className="fw-bold  float-none" style={{ fontSize: "15px" }}>
													Time: {time}
												</legend>
											</div>
										</div>
									</Row>

									<Row>
										<div className="d-flex align-items-center">
											<div className="mb-0 w-100 pb-1">
												<fieldset className="border rounded-3">
													<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
														Shipper Information
													</legend>
													<Row className="gy-3 pe-3 ps-3">
														<Col lg={12}>
															<LabelComponent title="Address:" />
															<InputComponent
																type="search"
																name={`addressShip`}
																value={addressShip}
																onChange={(value, name) => {
																	setAddressShip(value);
																	setFieldValue("addressShip", value);
																}}
																placeholder={"Address"}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Person name:" requiredField />
															<InputComponent
																type="search"
																name={`personNameShip`}
																value={personNameShip}
																onChange={(value, name) => {
																	setPersonNameShip(value);
																	setFieldValue("personNameShip", value);
																}}
																placeholder={"Person name"}
																onBlur={handleBlur}
																invalid={touched.personNameShip && errors.personNameShip ? true : false}
																error={touched?.personNameShip && errors?.personNameShip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Company name:" requiredField />
															<InputComponent
																type="search"
																name={`companyNameShip`}
																value={companyNameShip}
																onChange={(value, name) => {
																	setCompanyNameShip(value);
																	setFieldValue("companyNameShip", value);
																}}
																placeholder={"Company name"}
																onBlur={handleBlur}
																invalid={touched.companyNameShip && errors.companyNameShip ? true : false}
																error={touched?.companyNameShip && errors?.companyNameShip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Phone number:" requiredField />
															<InputComponent
																type="search"
																name={`phoneNumberShip`}
																value={phoneNumberShip}
																onChange={(value, name) => {
																	setPhoneNumberShip(value);
																	setFieldValue("phoneNumberShip", value);
																}}
																placeholder={"Phone number"}
																onBlur={handleBlur}
																invalid={touched.phoneNumberShip && errors.phoneNumberShip ? true : false}
																error={touched?.phoneNumberShip && errors?.phoneNumberShip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Email:" />
															<InputComponent
																type="search"
																name={`emailShip`}
																value={emailShip}
																onChange={(value, name) => {
																	setEmailShip(value);
																	setFieldValue("emailShip", value);
																}}
																placeholder={"Email"}
																onBlur={handleBlur}
																invalid={touched.emailShip && errors.emailShip ? true : false}
																error={touched?.emailShip && errors?.emailShip}
															/>
														</Col>
													</Row>
												</fieldset>
											</div>
										</div>
									</Row>
									<Row>
										<div className="d-flex align-items-center">
											<div className="mb-0 w-100 pb-1">
												<fieldset className="border rounded-3">
													<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
														Recipient Information
													</legend>
													<Row className="gy-3 pe-3 ps-3">
														<Col lg={12}>
															<LabelComponent title="Address:" />
															<InputComponent
																type="search"
																name={`addressRecip`}
																value={addressRecip}
																onChange={(value, name) => {
																	setAddressRecip(value);
																	setFieldValue("addressRecip", value);
																}}
																placeholder={"Address"}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Person name:" requiredField />
															<InputComponent
																type="search"
																name={`personNameRecip`}
																value={personNameRecip}
																onChange={(value, name) => {
																	setPersonNameRecip(value);
																	setFieldValue("personNameRecip", value);
																}}
																placeholder={"Person name"}
																onBlur={handleBlur}
																invalid={touched.personNameRecip && errors.personNameRecip ? true : false}
																error={touched?.personNameRecip && errors?.personNameRecip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Company name:" requiredField />
															<InputComponent
																type="search"
																name={`companyNameRecip`}
																value={companyNameRecip}
																onChange={(value, name) => {
																	setCompanyNameRecip(value);
																	setFieldValue("companyNameRecip", value);
																}}
																placeholder={"Company name"}
																onBlur={handleBlur}
																invalid={touched.companyNameRecip && errors.companyNameRecip ? true : false}
																error={touched?.companyNameRecip && errors?.companyNameRecip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Phone number:" requiredField />
															<InputComponent
																type="search"
																name={`phoneNumberRecip`}
																value={phoneNumberRecip}
																onChange={(value, name) => {
																	setPhoneNumberRecip(value);
																	setFieldValue("phoneNumberRecip", value);
																}}
																placeholder={"Phone number"}
																onBlur={handleBlur}
																invalid={touched.phoneNumberRecip && errors.phoneNumberRecip ? true : false}
																error={touched?.phoneNumberRecip && errors?.phoneNumberRecip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Email:" />
															<InputComponent
																type="search"
																name={`emailRecip`}
																value={emailRecip}
																onChange={(value, name) => {
																	setEmailRecip(value);
																	setFieldValue("emailRecip", value);
																}}
																placeholder={"Email"}
																onBlur={handleBlur}
																invalid={touched.emailRecip && errors.emailRecip ? true : false}
																error={touched?.emailRecip && errors?.emailRecip}
															/>
														</Col>
													</Row>
												</fieldset>
											</div>
										</div>
									</Row>

									<Row>
										<Col lg={3}>
											<div className="d-flex align-items-center">
												<div className="mb-0 w-100 pb-1">
													<fieldset className="border rounded-3">
														<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
															Incoterm{" "}
															<span>
																(<span className={"text-danger"}>*</span>)
															</span>
														</legend>
														<Row className="gy-3 pe-3 ps-3">
															<SelectComponent
																isSearchServer={true}
																label=""
																options={listConterm}
																name="incoterm"
																onBlur={() => {
																	handleBlur({ target: { name: "incoterm" } });
																}}
																onChange={(option) => {
																	setFieldValue("incoterm", option);
																	setIncoterm(option);
																}}
																validate={{
																	required: { value: true },
																}}
																value={incoterm}
																placeholder={"Select incoterm"}
																fieldValue="id"
																display="name"
																requiredField
																touched={touched.incoterm && errors.incoterm ? true : false}
																error={touched.incoterm && errors.incoterm}
															/>
														</Row>
													</fieldset>
												</div>
											</div>
										</Col>
										<Col lg={6}>
											<div className="d-flex align-items-center">
												<div className="mb-0 w-100 pb-1">
													<fieldset className="border rounded-3">
														<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
															Paperless Trade Shipment Information{" "}
															<span>
																(<span className={"text-danger"}>*</span>)
															</span>
														</legend>
														<Row className="ps-3 pe-3">
															<Col lg={12}>
																<Row className="gy-3 pb-2 pt-2">
																	<Col lg={6}>
																		<InputComponent
																			name="paperlessTrade"
																			className="form-check-input"
																			type="radio"
																			id="checkbox_paperlessTrade_yes"
																			value={true}
																			checked={paperlessTrade === true}
																			onClick={(value) => {
																				setPaperlessTrade(true);
																				setFieldValue("paperlessTrade", true);
																			}}
																			onBlur={(e) => {
																				handleBlur(e);
																			}}
																		/>
																		<span
																			className="form-check-label ps-2"
																			htmlFor="checkbox_paperlessTrade_yes"
																			onClick={() => {
																				setPaperlessTrade(true);
																				setFieldValue("paperlessTrade", true);
																			}}>
																			Yes
																		</span>
																	</Col>
																	<Col lg={6}>
																		<InputComponent
																			name="paperlessTrade"
																			className="form-check-input"
																			type="radio"
																			id={`checkbox_paperlessTrade_no`}
																			value={false}
																			checked={paperlessTrade === false}
																			validate={{
																				required: { value: true },
																			}}
																			onClick={(value) => {
																				setPaperlessTrade(false);
																				setFieldValue("paperlessTrade", false);
																			}}
																			onBlur={(e) => {
																				handleBlur(e);
																			}}
																			invalid={touched.partnertype && errors.partnertype ? true : false}
																		/>
																		<span
																			className="form-check-label ps-2"
																			htmlFor="checkbox_paperlessTrade_no"
																			onClick={() => {
																				setPaperlessTrade(false);
																				setFieldValue("paperlessTrade", false);
																			}}>
																			No
																		</span>
																	</Col>
																</Row>
															</Col>

															{paperlessTrade ? (
																<Col lg={12} className="text-danger">
																	Your commecial invoice will automatically submit to CUSTOMS, please check with your Manager
																</Col>
															) : (
																<></>
															)}
														</Row>
														{paperlessTrade ? (
															<Row className="gy-3 ps-3 pe-3 pb-2 pt-2">
																<Col lg={4} className="pe-0">
																	<SelectComponent
																		isSearchServer={true}
																		label=""
																		options={listPaperless}
																		name="paperless"
																		onBlur={() => {
																			handleBlur({ target: { name: "paperless" } });
																		}}
																		onChange={(option) => {
																			setFieldValue("paperless", option);
																			setPaperless(option);
																		}}
																		validate={{
																			required: { value: true },
																		}}
																		value={paperless}
																		placeholder={"Paperless"}
																		fieldValue="id"
																		display="name"
																		requiredField
																		touched={touched.paperless && errors.paperless ? true : false}
																		error={touched.paperless && errors.paperless}
																	/>
																</Col>
																<Col lg={8}>
																	<div style={{ position: "relative" }}>
																		<input
																			ref={fileInputRef}
																			className="form-control"
																			id="project-thumbnail-img"
																			type="file"
																			accept="image/png, image/gif, image/jpeg"
																			onChange={(event) => handleFileChange(event, setFieldValue)}
																		/>
																		{fileInputRef.current && fileInputRef.current.value && (
																			<div
																				style={{
																					position: "absolute",
																					top: "50%",
																					right: "10px",
																					transform: "translateY(-50%)",
																					cursor: "pointer",
																					color: "red",
																				}}
																				onClick={() => handleClearFile(setFieldValue)}>
																				<i className="ri-delete-bin-line align-bottom fs-16"></i>
																			</div>
																		)}
																	</div>
																	{errors?.fileData && (
																		<div>
																			<span className="text-danger" style={{ width: "100%", marginTop: "0.25rem", fontSize: "0.875em" }}>
																				{errors?.fileData}
																			</span>
																		</div>
																	)}
																</Col>
															</Row>
														) : (
															<></>
														)}
													</fieldset>
												</div>
											</div>
										</Col>
										<Col lg={3}>
											<div className="d-flex align-items-center">
												<div className="mb-0 w-100 pb-1">
													<fieldset className="border rounded-3">
														<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
															Create Return label
														</legend>
														<Row className="gy-3 pe-3 ps-3 pb-2 pt-2">
															<Col lg={6}>
																<InputComponent
																	name="returnLabel"
																	className="form-check-input"
																	type="radio"
																	id={"return_label_yes"}
																	value={true}
																	checked={returnLabel === true}
																	onChange={(value) => {
																		setReturnLabel(true);
																		setFieldValue("returnLabel", true);
																	}}
																	onBlur={(e) => {
																		handleBlur(e);
																	}}
																/>
																<span
																	className="form-check-label ps-2"
																	htmlFor="return_label_yes"
																	onClick={() => {
																		setReturnLabel(true);
																		setFieldValue("returnLabel", true);
																	}}>
																	Yes
																</span>
															</Col>
															<Col lg={6}>
																<InputComponent
																	name="returnLabel"
																	className="form-check-input"
																	type="radio"
																	id={"return_label_no"}
																	value={false}
																	checked={returnLabel === false}
																	onChange={(value) => {
																		setReturnLabel(false);
																		setFieldValue("returnLabel", false);
																	}}
																	onBlur={(e) => {
																		handleBlur(e);
																	}}
																/>
																<span
																	className="form-check-label ps-2"
																	htmlFor="return_label_no"
																	onClick={() => {
																		setReturnLabel(false);
																		setFieldValue("returnLabel", false);
																	}}>
																	No
																</span>
															</Col>
														</Row>
													</fieldset>
												</div>
											</div>
										</Col>
									</Row>

									<div className="mb-3 w-100 mx-auto d-flex align-items-center justify-content-end">
										<div style={{ marginRight: "29px" }}>
											<Button
												// disabled={!dirty}
												type="button"
												color="success"
												onClick={() => {
													handleSubmit(values);
												}}>
												Create Shipment
											</Button>
										</div>
									</div>
								</ModalBody>
							</FormSubmit>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
};

export default ModalDHL;
