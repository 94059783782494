import { Col, Row } from "reactstrap";
import LabelComponent from "../../../../Components/Hooks/LabelComponent";
import InputComponent from "../../../../Components/Hooks/InputComponent";
import { useEffect, useState } from "react";

const CommoditiesInformation = ({ setFieldValue }) => {
	const [description, setDescription] = useState("Computer Hardware");
	const [total, setTotal] = useState("");

	useEffect(() => {
		let requestData = {
			description: description,
			totalValue: total == "" ? 0 : Number(total),
		};
		setFieldValue("domesticInformation", requestData);
	}, [description, total]);
	return (
		<Row className="ps-2">
			<Col lg={6}>
				<LabelComponent title="Description:" />
				<InputComponent
					type="search"
					name={`description`}
					value={description}
					onChange={(value, name) => {
						setDescription(value);
					}}
					placeholder={"Description"}
				/>
			</Col>
			<Col lg={6}>
				<LabelComponent title="Total Value (AUD):" />
				<InputComponent
					type="search"
					name={`total`}
					value={total}
					onChange={(value, name) => {
						setTotal(value);
					}}
					placeholder={"0"}
				/>
			</Col>
			<Col lg={8}></Col>
		</Row>
	);
};

export default CommoditiesInformation;
