import React, { useState, useEffect, memo, useMemo, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableQuickSelectRows from "./TableQuickSelectRows";
import { Button, Col, Row, Table, Input, FormFeedback } from "reactstrap";
import {
	getListPackageSize,
	listLogisticsAddRowsPackageSize,
	listLogisticsDeleteRowsPackageSize,
	listLogisticsUpdateRowsPackageSize,
} from "../../../../../store/actions";

function ListQuickSelectTable({ setFieldValue, isDisablePO = false, isOpen }) {
	const dispatch = useDispatch();

	const [rowsData, setRowsData] = useState([]);
	const [adding, setAdding] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [order, setOrder] = useState("_iid");
	const [sortDirection, setSortDirection] = useState("asc");
	const [perPage, setPerPage] = useState(50);

	const [defaultData, setDefaultData] = useState(false);

	const { dataListPackagaSize, dataValuPackagaSizeDomestic } = useSelector((state) => state.DashboardListLogistics);

	useEffect(() => {
		if (isOpen) dispatch(getListPackageSize(currentPage, perPage, order, sortDirection));
	}, [isOpen]);

	const disabledPO = {
		pointerEvents: isDisablePO ? "none" : "unset",
		opacity: isDisablePO ? 0.7 : 1,
	};

	//set data
	useEffect(() => {
		if (dataListPackagaSize?.length > 0 && isOpen) {
			let dataListPackagaSizeCustom = dataListPackagaSize;
			if (!defaultData) {
				if (dataValuPackagaSizeDomestic?.length > 0) {
					dataListPackagaSizeCustom.forEach((value) => {
						let isIdExist = dataValuPackagaSizeDomestic.some((item) => item.id === value.id);
						if (isIdExist) {
							value.statusChecked = true;
						} else {
							value.statusChecked = false;
						}
					});
				}
			} else {
				setDefaultData(true);
			}
			setRowsData(dataListPackagaSizeCustom);
		} else setRowsData([{ id: "", name: "", weight: "", sizeL: "", sizeW: "", sizeH: "", statusChecked: false }]);
	}, [dataListPackagaSize, isOpen, dataValuPackagaSizeDomestic]);

	const addTableRows = useCallback(() => {
		const rowInput = { id: "", name: "", weight: "", sizeL: "", sizeW: "", sizeH: "", statusChecked: false };
		// let newRowsData = rowsData?.length > 0 ? rowsData : [];
		// setRowsData([...newRowsData, rowInput]);
		dispatch(listLogisticsAddRowsPackageSize(rowInput));
		setAdding(true);
	}, [rowsData]);

	useEffect(() => {
		if (rowsData[rowsData.length - 1]?.name && rowsData[rowsData.length - 1]?.name !== "") {
			addTableRows();
		}
	}, [rowsData, addTableRows]);

	useEffect(() => {
		if (adding) {
			const element = document.getElementById(`list-product-scroll`);
			if (element) {
				element.scrollTop = element.scrollHeight;
			}
			setAdding(false);
		}
	}, [adding]);

	const deleteTableRows = (index) => {
		dispatch(listLogisticsDeleteRowsPackageSize(index));
		// setRowsData((prevData) => [...prevData.slice(0, index), ...prevData.slice(index + 1, prevData.length)]);
	};

	const handleChangeWeight = (index, dataUpdate) => {
		setDefaultData(true);
		const data = rowsData.map((item, key) => {
			if (key === index) {
				return { ...item, ...dataUpdate };
			}
			return item;
		});
		dispatch(listLogisticsUpdateRowsPackageSize(data));
	};

	const handleChange = (index, value, name) => {
		setDefaultData(true);
		const data = rowsData.map((item, key) => (key === index ? { ...item, [name]: value } : item));
		dispatch(listLogisticsUpdateRowsPackageSize(data));
		// dispatch(
		// 	listLogisticsUpdateRowsPackageSize({
		// 		index,
		// 		value,
		// 		name,
		// 	})
		// );
		// setRowsData((prevData) => prevData.map((item, key) => (key === index ? { ...item, [name]: value } : item)));
	};

	useEffect(() => {
		let newRowsData = [];
		if (rowsData?.length > 0) {
			rowsData.forEach((row) => {
				if (typeof row?.name !== "undefined" && row.name !== "" && row.statusChecked) {
					newRowsData.push(row);
				}
			});
		}
		setFieldValue("package_information", newRowsData);
	}, [rowsData]);

	return (
		<React.Fragment>
			<div id="list-product-scroll" className="pe-1 ps-1 pb-0 pt-0">
				<Row>
					<Table className="table-bordered align-middle table-wrap mb-0" size="sm">
						<thead style={{ ...disabledPO, position: "sticky", top: "-1px", zIndex: "1" }} className="table-light-add-row bg-theme-dark">
							<tr>
								<th scope="col" className="text-center" style={{ width: "350px" }}>
									Name
								</th>
								<th scope="col" className="text-center">
									Weight
								</th>
								<th scope="col" className="text-center">
									Length
								</th>
								<th scope="col" className="text-center">
									Width
								</th>
								<th scope="col" className="text-center">
									Height
								</th>
								<th scope="col" className="text-center">
									Action
								</th>
								<th scope="col" className="text-center">
									Select
								</th>
							</tr>
						</thead>
						<tbody id="tbody-table">
							{rowsData.length > 0 ? (
								rowsData.map((data, index) => {
									return (
										<tr key={index}>
											<TableQuickSelectRows
												data={data}
												index={index}
												handleChange={handleChange}
												deleteTableRows={deleteTableRows}
												disabledPO={disabledPO}
												handleChangeWeight={handleChangeWeight}
											/>
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan={7} className="text-center">
										<span>Already package all orders or no packages</span>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</Row>
			</div>
			<Row>
				<Col lg={4} className="ps-4">
					<div style={{ ...disabledPO }}>
						<Button
							className="mt-1 p-1"
							type="button"
							color="success"
							onClick={() => {
								addTableRows();
							}}>
							Add more +
						</Button>
					</div>
				</Col>
				<Col lg={4} className="text-center"></Col>
				<Col lg={4} className="text-end pe-4"></Col>
			</Row>
		</React.Fragment>
	);
}

export default ListQuickSelectTable;
