import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Mailbox Redux States
import {
	LOGISTICS_GET_LIST_ADDRESS,
	LOGISTICS_GET_LIST_HISTORY,
	LOGISTICS_ADD_ADDRESS,
	LOGISTICS_UPDATE_ADDRESS,
	LOGISTICS_GET_LIST_PACKAGE_SIZE,
	LOGISTICS_ADD_PACKAGE_SIZE,
	LOGISTICS_UPDATE_PACKAGE_SIZE,
	LOGISTICS_DELETE_PACKAGE_SIZE,
	LOGISTICS_GET_RATE_SHIPMENT,
	LOGISTICS_GET_DEFINITIONS,
	LOGISTICS_GET_LIST_ADDRESS_DEFAULT,
	LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_DOMESTIC,
	LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_INTERNATIONAL,
	LOGISTICS_DELETE_ADDRESS,
} from "./actionType";

//Include Both Helper File with needed methods
import {
	addAddress,
	deletePackageSize,
	getDefinitions,
	getListAddress,
	getListComboboxAddress,
	getListLogistics,
	getListPackageSize,
	getRateShipment,
	savePackageSize,
} from "../../helpers/fakebackend_helper";
import {
	listLogisticsApiResponseError,
	listLogisticsApiResponseSuccess,
	listLogisticsDeletePackageSizeFail,
	listLogisticsDeletePackageSizeSuccess,
} from "./action";

//GET LIST HISTORY
function* logisticsGetListHistory({ payload: data }) {
	try {
		const response = yield call(getListLogistics, data);
		yield put(listLogisticsApiResponseSuccess(LOGISTICS_GET_LIST_HISTORY, response));
	} catch (error) {
		yield put(listLogisticsApiResponseError(LOGISTICS_GET_LIST_HISTORY, error));
	}
}
export function* watchLogisticsGetListLogistics() {
	yield takeEvery(LOGISTICS_GET_LIST_HISTORY, logisticsGetListHistory);
}

//GET LIST ADDRESS
function* logisticsGetListAddress({ payload: data }) {
	try {
		const response = yield call(getListAddress, data);
		yield put(listLogisticsApiResponseSuccess(LOGISTICS_GET_LIST_ADDRESS, response));
	} catch (error) {
		yield put(listLogisticsApiResponseError(LOGISTICS_GET_LIST_ADDRESS, error));
	}
}
export function* watchLogisticsGetListAddress() {
	yield takeEvery(LOGISTICS_GET_LIST_ADDRESS, logisticsGetListAddress);
}

function* listLogisticsGetListAddressDefaultSearchAddressDomestic({ payload: data }) {
	try {
		const response = yield call(getListComboboxAddress, data);
		yield put(listLogisticsApiResponseSuccess(LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_DOMESTIC, response));
	} catch (error) {
		yield put(listLogisticsApiResponseError(LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_DOMESTIC, error));
	}
}
export function* watchListLogisticsGetListAddressDefaultSearchAddressDomestic() {
	yield takeEvery(LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_DOMESTIC, listLogisticsGetListAddressDefaultSearchAddressDomestic);
}

function* listLogisticsGetListAddressDefaultSearchAddressInternational({ payload: data }) {
	try {
		const response = yield call(getListComboboxAddress, data);
		yield put(listLogisticsApiResponseSuccess(LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_INTERNATIONAL, response));
	} catch (error) {
		yield put(listLogisticsApiResponseError(LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_INTERNATIONAL, error));
	}
}
export function* watchListLogisticsGetListAddressDefaultSearchAddressInternational() {
	yield takeEvery(LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_INTERNATIONAL, listLogisticsGetListAddressDefaultSearchAddressInternational);
}

//GET LIST ADDRESS
function* logisticsGetListAddressDefault({ payload: data }) {
	try {
		const response = yield call(getListAddress, data);
		yield put(listLogisticsApiResponseSuccess(LOGISTICS_GET_LIST_ADDRESS_DEFAULT, response));
	} catch (error) {
		yield put(listLogisticsApiResponseError(LOGISTICS_GET_LIST_ADDRESS_DEFAULT, error));
	}
}
export function* watchLogisticsGetListAddressDefault() {
	yield takeEvery(LOGISTICS_GET_LIST_ADDRESS_DEFAULT, logisticsGetListAddressDefault);
}

//Add address
function* listLogisticsAddAddress({ payload: data }) {
	try {
		const response = yield call(addAddress, data.data);
		if (response.Status === "ERROR") {
			toast.error(response?.Msg && Array?.isArray(response?.Msg) ? response?.Msg?.join("\n") : response?.Msg ?? "", { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_ADD_ADDRESS, response));
		} else if (response.error) {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_ADD_ADDRESS, response));
		} else {
			yield put(listLogisticsApiResponseSuccess(LOGISTICS_ADD_ADDRESS, response));
			toast.success("Add Address Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listLogisticsApiResponseError(LOGISTICS_ADD_ADDRESS, error));
	}
}
export function* watchLogisticsAddAddress() {
	yield takeEvery(LOGISTICS_ADD_ADDRESS, listLogisticsAddAddress);
}

//UPDATE ADDRESS
function* listLogisticsUpdateAddress({ payload: data }) {
	try {
		const response = yield call(addAddress, data.data);
		if (response.Status === "ERROR") {
			toast.error(response?.Msg && Array?.isArray(response?.Msg) ? response?.Msg?.join("\n") : response?.Msg ?? "", { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_UPDATE_ADDRESS, response));
		} else if (response.error) {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_UPDATE_ADDRESS, response));
		} else {
			yield put(listLogisticsApiResponseSuccess(LOGISTICS_UPDATE_ADDRESS, response));
			toast.success("Update Address Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listLogisticsApiResponseError(LOGISTICS_UPDATE_ADDRESS, error));
	}
}
export function* watchLogisticsUpdateAddress() {
	yield takeEvery(LOGISTICS_UPDATE_ADDRESS, listLogisticsUpdateAddress);
}

function* listLogisticsDeleteAddress({ payload: data }) {
	try {
		const response = yield call(addAddress, data.data);
		if (response.Status === "ERROR") {
			toast.error(response?.Msg && Array?.isArray(response?.Msg) ? response?.Msg?.join("\n") : response?.Msg ?? "", { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_DELETE_ADDRESS, response));
		} else if (response.error) {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_DELETE_ADDRESS, response));
		} else {
			yield put(listLogisticsApiResponseSuccess(LOGISTICS_DELETE_ADDRESS, response));
			toast.success("Delete Address Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listLogisticsApiResponseError(LOGISTICS_DELETE_ADDRESS, error));
	}
}
export function* watchLogisticsDeleteAddress() {
	yield takeEvery(LOGISTICS_DELETE_ADDRESS, listLogisticsDeleteAddress);
}

//GET LIST PACKAGE SIZE
function* logisticsGetListPackageSize({ payload: data }) {
	try {
		const response = yield call(getListPackageSize, data);
		yield put(listLogisticsApiResponseSuccess(LOGISTICS_GET_LIST_PACKAGE_SIZE, response));
	} catch (error) {
		yield put(listLogisticsApiResponseError(LOGISTICS_GET_LIST_PACKAGE_SIZE, error));
	}
}
export function* watchLogisticsGetListPackageSize() {
	yield takeEvery(LOGISTICS_GET_LIST_PACKAGE_SIZE, logisticsGetListPackageSize);
}

//ADD PACKAGE SIZE
function* listLogisticsAddPackageSize({ payload: data }) {
	try {
		const response = yield call(savePackageSize, data.data);
		if (response.Status === "ERROR") {
			toast.error(response?.Msg && Array?.isArray(response?.Msg) ? response?.Msg?.join("\n") : response?.Msg ?? "", { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_ADD_PACKAGE_SIZE, response));
		} else if (response.error) {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_ADD_PACKAGE_SIZE, response));
		} else {
			response.index = data.index;
			yield put(listLogisticsApiResponseSuccess(LOGISTICS_ADD_PACKAGE_SIZE, response));
			toast.success("Add Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listLogisticsApiResponseError(LOGISTICS_ADD_PACKAGE_SIZE, error));
	}
}
export function* watchLogisticsAddPackageSize() {
	yield takeEvery(LOGISTICS_ADD_PACKAGE_SIZE, listLogisticsAddPackageSize);
}

//UPDATE PACKAGE SIZE
function* listLogisticsUpdatePackageSize({ payload: data }) {
	try {
		const response = yield call(savePackageSize, data.data);
		if (response.Status === "ERROR") {
			toast.error(response?.Msg && Array?.isArray(response?.Msg) ? response?.Msg?.join("\n") : response?.Msg ?? "", { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_UPDATE_PACKAGE_SIZE, response));
		} else if (response.error) {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_UPDATE_PACKAGE_SIZE, response));
		} else {
			yield put(listLogisticsApiResponseSuccess(LOGISTICS_UPDATE_PACKAGE_SIZE, response));
			toast.success("Update Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listLogisticsApiResponseError(LOGISTICS_UPDATE_PACKAGE_SIZE, error));
	}
}
export function* watchLogisticsUpdatePackageSize() {
	yield takeEvery(LOGISTICS_UPDATE_PACKAGE_SIZE, listLogisticsUpdatePackageSize);
}

//DELETE PACKAGE SIZE
function* listLogisticsDeletePackageSize({ payload: data }) {
	try {
		const response = yield call(deletePackageSize, data);
		yield put(listLogisticsDeletePackageSizeSuccess({ data }));
		toast.success("Delete Successfully", { autoClose: 1000 });
	} catch (error) {
		yield put(listLogisticsDeletePackageSizeFail(error));
		toast.error("Delete Failed", { autoClose: 3000 });
	}
}
export function* watchLogisticsDeletePackageSize() {
	yield takeEvery(LOGISTICS_DELETE_PACKAGE_SIZE, listLogisticsDeletePackageSize);
}

//GET RATE SHIPMENT
function* listLogisticsGetRateShipment({ payload: data }) {
	try {
		const response = yield call(getRateShipment, data.data);
		if (response.Status === "ERROR") {
			toast.error(response?.Msg && Array?.isArray(response?.Msg) ? response?.Msg?.join("\n") : response?.Msg ?? "", { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_GET_RATE_SHIPMENT, response));
		} else if (response.error) {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_GET_RATE_SHIPMENT, response));
		} else {
			yield put(listLogisticsApiResponseSuccess(LOGISTICS_GET_RATE_SHIPMENT, response));
			toast.success("Get Data Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listLogisticsApiResponseError(LOGISTICS_GET_RATE_SHIPMENT, error));
	}
}
export function* watchLogisticsGetRateShipment() {
	yield takeEvery(LOGISTICS_GET_RATE_SHIPMENT, listLogisticsGetRateShipment);
}

//GET DEFINITIONS
function* listLogisticsGetDefinitions({ payload: data }) {
	try {
		const response = yield call(getDefinitions, data.data);
		if (response.Status === "ERROR") {
			toast.error(response?.Msg && Array?.isArray(response?.Msg) ? response?.Msg?.join("\n") : response?.Msg ?? "", { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_GET_DEFINITIONS, response));
		} else if (response.error) {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listLogisticsApiResponseError(LOGISTICS_GET_DEFINITIONS, response));
		} else {
			yield put(listLogisticsApiResponseSuccess(LOGISTICS_GET_DEFINITIONS, response));
			// toast.success("Get Data Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listLogisticsApiResponseError(LOGISTICS_GET_DEFINITIONS, error));
	}
}
export function* watchLogisticsGetDefinitions() {
	yield takeEvery(LOGISTICS_GET_DEFINITIONS, listLogisticsGetDefinitions);
}

function* DashboardListLogisticsSaga() {
	yield all([
		fork(watchLogisticsGetListLogistics),
		fork(watchLogisticsGetListAddress),
		fork(watchLogisticsGetListAddressDefault),
		fork(watchLogisticsAddAddress),
		fork(watchLogisticsUpdateAddress),
		fork(watchLogisticsGetListPackageSize),
		fork(watchLogisticsAddPackageSize),
		fork(watchLogisticsUpdatePackageSize),
		fork(watchLogisticsDeletePackageSize),
		fork(watchLogisticsGetRateShipment),
		fork(watchLogisticsGetDefinitions),
		fork(watchListLogisticsGetListAddressDefaultSearchAddressDomestic),
		fork(watchListLogisticsGetListAddressDefaultSearchAddressInternational),
		fork(watchLogisticsDeleteAddress),
	]);
}

export default DashboardListLogisticsSaga;
