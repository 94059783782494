import {
	GET_LIST_MODEL,
	GET_LIST_SUPPLIER,
	GET_LIST_WAREHOUSE,
	GET_LIST_CONDITION,
	GET_LIST_MANAGEMENT,
	GET_LIST_STATUS,
	API_RESPONSE_SUCCESS,
	API_RESPONSE_ERROR,
	GET_SEARCH_TEXT_INPUT,
	GET_PRODUCT_MODEL_ID,
	GET_SUPPLYER_ID,
	GET_ALl_PARAMS_SEARCH,
	GET_UPDATED_SEARCH,
	GET_DATEIN_SEARCH,
	GET_STATUS_SEARCH,
	GET_MANAGEMENT_SEARCH,
	GET_CONDITION_SEARCH,
	GET_WAREHOUSE_SEARCH,
	CLEAR_ALL_SEARCH_DATA,
	GET_PRODUCT_MODEL_CODE,
	GET_MODEL,
	GET_WAREHOUSE,
	GET_LIST_ORGANIZATION,
	GET_LIST_POSITION,
	GET_FILTER_CONDITION,
	GET_PARAMS_TABLE_FOOTER_SEARCH,
	MASTER_DATA_QUICK_ADD_SUPPLIER,
	MASTER_DATA_CACHE_QUICK_ADD_SUPPLIER,
	MASTER_DATA_QUICK_ADD_SUPPLIER_SUCCESS,
	MASTER_DATA_QUICK_ADD_SUPPLIER_FAIL,
	MASTER_DATA_GET_LIST_HASHTAGS,
	MASTER_DATA_RESET_LOADING_PAGE_LIST_SN,
	MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT,
	MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT_SUCCESS,
	MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT_FAIL,
	MASTER_DATA_CACHE_QUICK_ADD_SUPPLIER_EDIT,
	MASTER_DATA_GET_LIST_PRODUCT_CODE,
	MASTER_DATA_QUICK_ADD_PRODUCT,
	MASTER_DATA_QUICK_ADD_PRODUCT_SUCCESS,
	MASTER_DATA_QUICK_ADD_PRODUCT_FAIL,
	MASTER_DATA_CLEAR_CACHE_QUICK_ADD_PRODUCT,
	MASTER_DATA_GET_LIST_USERS,
	MASTER_DATA_QUICK_ADD_PARTNER,
	MASTER_DATA_QUICK_ADD_PARTNER_SUCCESS,
	MASTER_DATA_QUICK_ADD_PARTNER_FAIL,
	MASTER_DATA_CLEAR_CACHE_QUICK_ADD_PARTNER,
} from "./actionType";

export const getDataListModel = (search) => ({
	type: GET_LIST_MODEL,
	payload: { search },
});
export const getDataModel = (search) => ({
	type: GET_MODEL,
	payload: { search },
});
export const getDataListSuplier = (search) => ({
	type: GET_LIST_SUPPLIER,
	payload: { search },
});

export const getDataListWarehouse = () => ({
	type: GET_LIST_WAREHOUSE,
});

export const getFilterWarehouse = (search) => ({
	type: GET_WAREHOUSE,
	payload: { search },
});

export const getFilterCondition = (search) => ({
	type: GET_FILTER_CONDITION,
	payload: { search },
});

export const getDataListCondition = (type) => ({
	type: GET_LIST_CONDITION,
	payload: { type },
});

export const getDataListManagement = (type) => ({
	type: GET_LIST_MANAGEMENT,
	payload: { type },
});

export const getDataListStatus = (type) => ({
	type: GET_LIST_STATUS,
	payload: { type },
});

export const getDataListOrganization = (search, typeUser = "partner") => ({
	type: GET_LIST_ORGANIZATION,
	payload: { search, typeUser },
});

export const getDataListPosition = (search, group = "IN_ORG") => ({
	type: GET_LIST_POSITION,
	payload: {
		urlAPI: "/api/position/list-combo-box",
		filter: {
			where: {
				_q: search,
				group: group,
			},
		},
	},
});

//filter
export const getSearchTextInput = (search) => ({
	type: GET_SEARCH_TEXT_INPUT,
	payload: search,
});

export const getProductModelId = (search) => ({
	type: GET_PRODUCT_MODEL_ID,
	payload: search,
});

export const getProductModelCode = (search) => ({
	type: GET_PRODUCT_MODEL_CODE,
	payload: search,
});

export const getSupplierId = (search) => ({
	type: GET_SUPPLYER_ID,
	payload: search,
});

export const getOrgCode = (search) => ({
	type: GET_WAREHOUSE_SEARCH,
	payload: search,
});

export const getCondition = (search) => ({
	type: GET_CONDITION_SEARCH,
	payload: search,
});

export const getManagement = (search) => ({
	type: GET_MANAGEMENT_SEARCH,
	payload: search,
});

export const getStatus = (search) => ({
	type: GET_STATUS_SEARCH,
	payload: search,
});

export const getDateIn = (search) => ({
	type: GET_DATEIN_SEARCH,
	payload: search,
});

export const getUpdated = (search) => ({
	type: GET_UPDATED_SEARCH,
	payload: search,
});
export const getParamsTableFooterSearch = (search) => ({
	type: GET_PARAMS_TABLE_FOOTER_SEARCH,
	payload: search,
});

export const getAllParamsSearch = (search) => ({
	type: GET_ALl_PARAMS_SEARCH,
	payload: search,
});

export const clearAllSearchData = () => ({
	type: CLEAR_ALL_SEARCH_DATA,
});

// common success
export const dashboardMasterDataSuccess = (actionType, data) => ({
	type: API_RESPONSE_SUCCESS,
	payload: { actionType, data },
});

// common error
export const dashboardMasterDataError = (actionType, error) => ({
	type: API_RESPONSE_ERROR,
	payload: { actionType, error },
});

//Quick add Supplier
export const masterDataQuickAddSupplier = (name) => ({
	type: MASTER_DATA_QUICK_ADD_SUPPLIER,
	payload: {
		urlAPI: "/api/organization/partner-save",
		data: {
			id: "",
			code: "",
			name: name,
			contactType: "",
			secureCV: "0",
			active: "1",
			currency: "",
			partnerPaymentTermId: "",
			ipsPaymentTermId: "",
			partnerWarrantyTermId: "",
			ipsWarrantyTermId: "",
			contactOwnerId: "",
			notes: {
				content: "",
			},
			contactPersons: [],
			accountPayment: [],
			accountShipping: [],
			accountOther: [
				{
					website: [""],
				},
				{
					ebay: [""],
				},
				{
					amazon: [""],
				},
				{
					uneda: [""],
				},
				{
					brokerbin: [""],
				},
			],
			source: [],
			groupIds: [],
		},
	},
});

export const masterDataQuickAddSupplierSuccess = (data) => ({
	type: MASTER_DATA_QUICK_ADD_SUPPLIER_SUCCESS,
	payload: data,
});

export const masterDataQuickAddSupplierFail = (error) => ({
	type: MASTER_DATA_QUICK_ADD_SUPPLIER_FAIL,
	payload: error,
});

export const masterDataClearAddQuickSupplier = () => ({
	type: MASTER_DATA_CACHE_QUICK_ADD_SUPPLIER,
});

//

//Quick add Supplier
export const masterDataQuickAddSupplierEdit = (name) => ({
	type: MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT,
	payload: {
		urlAPI: "/api/organization/partner-save",
		data: {
			id: "",
			code: "",
			name: name,
			contactType: "",
			secureCV: "0",
			active: "1",
			currency: "",
			partnerPaymentTermId: "",
			ipsPaymentTermId: "",
			partnerWarrantyTermId: "",
			ipsWarrantyTermId: "",
			contactOwnerId: "",
			notes: {
				content: "",
			},
			contactPersons: [],
			accountPayment: [],
			accountShipping: [],
			accountOther: [
				{
					website: [""],
				},
				{
					ebay: [""],
				},
				{
					amazon: [""],
				},
				{
					uneda: [""],
				},
				{
					brokerbin: [""],
				},
			],
			source: [],
			groupIds: [],
		},
	},
});

export const masterDataQuickAddSupplierEditSuccess = (data) => ({
	type: MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT_SUCCESS,
	payload: data,
});

export const masterDataQuickAddSupplierEditFail = (error) => ({
	type: MASTER_DATA_QUICK_ADD_SUPPLIER_EDIT_FAIL,
	payload: error,
});

export const masterDataClearAddQuickSupplierEdit = (name) => ({
	type: MASTER_DATA_CACHE_QUICK_ADD_SUPPLIER_EDIT,
});

export const masterDataGetListHashTags = () => ({
	type: MASTER_DATA_GET_LIST_HASHTAGS,
	payload: {
		urlAPI: "/api/hashtags/list-combo-box",
		filter: {
			limit: 12,
			order: "orders ASC",
			where: { _q: "" },
		},
	},
});

//Update

export const masterResetFetchDetailListSN = () => {
	return {
		type: MASTER_DATA_RESET_LOADING_PAGE_LIST_SN,
	};
};

//Product code
export const masterDataGetListProductCode = (search = "") => ({
	type: MASTER_DATA_GET_LIST_PRODUCT_CODE,
	payload: {
		urlAPI: "/api/product-model/list-combo-box",
		filter: {
			limit: 10,
			order: "updatedAt desc",
			where: {
				_q: search,
			},
		},
	},
});

export const masterDataQuickAddProduct = (data, isConfirm = false, orderId = "", packageSaleId = "", packagePoId = "", poId = "") => {
	let body = {
		code: data,
		names: [data],
		shortDescription: data,
		longDescription: data,
		isConfirm,
	};
	if (orderId !== "" && orderId !== null && typeof orderId !== "undefined") body = { ...body, orderId };
	if (packageSaleId !== "" && packageSaleId !== null && typeof packageSaleId !== "undefined") body = { ...body, packageSaleId };
	if (packagePoId !== "" && packagePoId !== null && typeof packagePoId !== "undefined") body = { ...body, packagePoId };
	if (poId !== "" && poId !== null && typeof poId !== "undefined") body = { ...body, poId };

	return {
		type: MASTER_DATA_QUICK_ADD_PRODUCT,
		payload: {
			urlAPI: "/api/product-model/data-save",
			data: body,
		},
	};
};

export const masterDataQuickAddProductSuccess = (data) => ({
	type: MASTER_DATA_QUICK_ADD_PRODUCT_SUCCESS,
	payload: data,
});

export const masterDataQuickAddProductFail = (error) => ({
	type: MASTER_DATA_QUICK_ADD_PRODUCT_FAIL,
	payload: error,
});

export const masterDataClearCacheQuickAddProduct = () => ({
	type: MASTER_DATA_CLEAR_CACHE_QUICK_ADD_PRODUCT,
});

//Users
export const masterDataGetListUsers = (search = "", limit = 10) => ({
	type: MASTER_DATA_GET_LIST_USERS,
	payload: {
		urlAPI: "/api/user/list-combo-box",
		filter: {
			limit: limit,
			order: "updatedAt desc",
			where: {
				_q: search,
				type: "staff",
			},
		},
	},
});

export const masterDataQuickAddPartner = (name) => ({
	type: MASTER_DATA_QUICK_ADD_PARTNER,
	payload: {
		urlAPI: "/api/organization/quick-add",
		data: {
			name: name,
			contactType: "Other",
			currency: "USD",
			emails: [],
			tels: [],
		},
	},
});

export const masterDataQuickAddPartnerSuccess = (data) => ({
	type: MASTER_DATA_QUICK_ADD_PARTNER_SUCCESS,
	payload: data,
});

export const masterDataQuickAddPartnerFail = (error) => ({
	type: MASTER_DATA_QUICK_ADD_PARTNER_FAIL,
	payload: error,
});

export const masterDataClearAddQuickPartner = () => ({
	type: MASTER_DATA_CLEAR_CACHE_QUICK_ADD_PARTNER,
});
