import axios from "axios";
import { api } from "../config";
import Notification from "../Components/Common/Notification";
// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
let token = localStorage.getItem("authUser")
	? localStorage.getItem("authUser") != ""
		? JSON.parse(localStorage.getItem("authUser")).token
		: null
	: null;
if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;

// intercepting to capture errors
axios.interceptors.response.use(
	function (response) {
		return response.data ? response.data : response;
	},
	function (error) {
		let response = error.response;
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		let message;
		switch (response.status) {
			case 500:
				message = response.data.message || "Internal Server Error";
				break;
			case 401:
				message = response.data.message || "Invalid credentials";
				break;
			case 404:
				message = response.data.message || "Sorry! the data you are looking for could not be found";
				break;
			default:
				message = response.data.message || response;
		}
		Notification(message, "error");

		if (response.status === 401) {
			let delayDebounceFn = setTimeout(() => {
				window.location.href = "/login";
				return Promise.reject(message);
			}, 1000);
			return () => clearTimeout(delayDebounceFn);
		} else {
			return response.data ? response.data : response;
		}
	}
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
	axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
	/**
	 * Fetches data from given url
	 */

	//  get = (url, params) => {
	//   return axios.get(url, params);
	// };
	get = (url, params) => {
		let response;
		let paramKeys = [];
		if (params) {
			Object.keys(params).map((key) => {
				paramKeys.push(key + "=" + encodeURIComponent(Array.isArray(params[key]) ? JSON.stringify(params[key]) : params[key]));
				return paramKeys;
			});
			const queryString = paramKeys && paramKeys.length ? paramKeys.join("&") : "";
			response = axios.get(`${url}?${queryString}`, params);
		} else {
			response = axios.get(`${url}`, params);
		}

		return response;
	};

	getV2 = (url, params) => {
		let response;
		if (params) {
			const queryString = "filter=" + JSON.stringify(params["filter"]) ?? "";
			response = axios.get(`${url}?${queryString}`, params);
		} else {
			response = axios.get(`${url}`, params);
		}

		return response;
	};
	/**
	 * post given data to url
	 */
	create = (url, data) => {
		// data.page = window.location.pathname ?? "";
		return axios.post(url, data);
	};
	/**
	 * Updates data
	 */
	update = (url, data) => {
		return axios.patch(url, data);
	};

	put = (url, data) => {
		return axios.put(url, data);
	};
	/**
	 * Delete
	 */
	delete = (url, config) => {
		return axios.delete(url, { ...config });
	};
}
const getLoggedinUser = () => {
	const user = localStorage.getItem("authUser");
	// console.log("user", user, "sessionStorage", sessionStorage);
	if (!user) {
		return null;
	} else {
		return JSON.parse(user);
	}
};

export { APIClient, setAuthorization, getLoggedinUser };
